import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import {
  addItemChangeStatus,
  changeBriefToEdit,
  changeTargetID,
  addItemToDelete,
  addSelectedBrief,
} from '../slices/bulletSlice'
import {
  setRightState,
  setRightOpen,
  setModalOpen,
  setModalState,
} from '../slices/navSlice'
import { IconButtons } from '../buttons/Buttons'

export const Actions = ({ brief, inFolder, inBuilder, isClient }) => {
  const dispatch = useDispatch()
  const { brief_header, brief_id, brief_target, record_status } = brief

  const handleEdit = () => {
    dispatch(changeTargetID(brief_target))
    dispatch(changeBriefToEdit(brief))
    dispatch(setRightOpen(true))
    dispatch(setRightState('editBrief'))
  }

  const handleDelete = () => {
    dispatch(
      addItemToDelete({
        id: brief_id,
        type: 'brief',
        header: brief_header,
        record_status,
      })
    )
    dispatch(setModalState('delete'))
    dispatch(setModalOpen(true))
  }
  const handleChangeStatus = () => {
    dispatch(
      addItemChangeStatus({
        id: brief_id,
        type: 'brief',
        header: brief_header,
        record_status: record_status,
      })
    )
    dispatch(setModalState('changeStatus'))
    dispatch(setModalOpen(true))
  }

  const handleFolder = () => {
    dispatch(addSelectedBrief(brief))
    dispatch(setRightState('addToFolder'))
    dispatch(setRightOpen(true))
  }
  return (
    <>
      {!inFolder && !inBuilder && (
        <IconContainer>
          {!isClient && (
            <>
              <IconButtons
                iconType='edit'
                toolTipValue='Edit Brief'
                handleChange={handleEdit}
              />
              <IconButtons
                iconType='status'
                toolTipValue='Change Brief Status'
                handleChange={handleChangeStatus}
              />
              <IconButtons
                iconType='delete'
                toolTipValue='Delete Brief'
                handleChange={handleDelete}
              />
            </>
          )}
          <IconButtons
            iconType='addToFolder'
            toolTipValue='Add Brief to Folder'
            handleChange={handleFolder}
          />
        </IconContainer>
      )}
    </>
  )
}

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
