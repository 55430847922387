import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Edit, AddCircle } from '@material-ui/icons'
import { Typography, Button } from '@material-ui/core'

import { api } from '../../../api/api'
import { FormWithTracking } from './FormWithTracking'
import {
  WActions,
  WContent,
  WTitle,
  WDescription,
  WList,
  WListItem,
  WListItemActions,
} from '../../wizard'
import { EDUCATION_FIELDS } from '../../../utils/forms'
import { addEducation, updateEducation } from '../../slices/targetSlice'
import { MomentRange } from '../../util/MomentRange'

const EducationLine = ({ education }) => (
  <>
    {education.school_name}
    &nbsp;
    <MomentRange
      fromDate={education.education_date_start}
      toDate={education.education_date_end}
    />
  </>
)

export const WEducationTab = ({ onSubmitSuccess, onExit }) => {
  let dispatch = useDispatch()
  const { targetId } = useParams()

  const allEducation = useSelector((state) => state.target.education)
  const [education, setEducation] = useState()

  const handleAdd = () => {
    setEducation({})
  }

  const handleSubmit = async (body) => {
    if (education.education_id) {
      const response = await api.updateEducation(education.education_id, body)
      dispatch(updateEducation(response.data))
    } else {
      const response = await api.createEducation({
        ...body,
        target_id: targetId,
      })
      dispatch(addEducation(response.data))
    }

    setEducation(null)
  }

  return (
    <>
      <WTitle>Fill in education sought and/or attained</WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>TKTKTK</WDescription>
      <WContent style={{ paddingBottom: '2rem' }}>
        <WList style={{ width: '80%' }}>
          <WListItem onClick={handleAdd}>
            Add Another
            <WListItemActions>
              <AddCircle />
            </WListItemActions>
          </WListItem>
          {allEducation.map((education) => (
            <WListItem
              key={education.education_id}
              onClick={() => setEducation(education)}
            >
              <EducationLine education={education} />
              <WListItemActions>
                <Edit />
              </WListItemActions>
            </WListItem>
          ))}
        </WList>
      </WContent>

      {education && (
        <>
          <Typography variant='h5' style={{ paddingBottom: '1rem' }}>
            {education.education_id
              ? `Editing ${education.school_name}`
              : 'Add New Education Record'}
          </Typography>
          <FormWithTracking
            objectId={education.education_id}
            relationType='many'
            initialAttributes={education}
            fields={EDUCATION_FIELDS}
            onSubmit={handleSubmit}
          />
        </>
      )}

      <WActions>
        <Button variant='contained' onClick={onSubmitSuccess}>
          CONTINUE
        </Button>
        <Button variant='contained' onClick={onExit}>
          EXIT
        </Button>
      </WActions>
    </>
  )
}
