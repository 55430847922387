import axios from 'axios'
import { errorHandler } from './errorHandler'

export const family = {
  createFamilyMember: async (body) => {
    try {
      return await axios.post(`/targets/family/create`, body)
    } catch (err) {
      errorHandler(err)
    }
  },
  updateFamilyMember: async (id, body) => {
    try {
      return await axios.patch(`/targets/family/update/${id}`, {
        ...body,
      })
    } catch (err) {
      errorHandler(err)
    }
  },
  deleteFamilyMember: async (id) => {
    try {
      return await axios.delete(`/targets/family/delete/${id}`)
    } catch (err) {
      errorHandler(err)
    }
  },
}
