import styled from 'styled-components'
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from '@material-ui/core'
import { MomentDate } from '../util/MomentDate'

export const TargetEducation = (props) => {
  const { education } = props

  return (
    <Box sx={{ p: '1rem' }}>
      <TableContainer component={Box} sx={{ backgroundColor: '#EEE' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <StyledRowHead>
              <TableCell>Start Year</TableCell>
              <TableCell>End Year</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>School Name</TableCell>
              <TableCell>Field</TableCell>
              <TableCell>Degree Sought</TableCell>
              <TableCell>Obtained Degree?</TableCell>
            </StyledRowHead>
          </TableHead>
          <TableBody>
            {education.map((row, index) => (
              <StyledRow
                key={row.education_id}
                className={index % 2 === 0 ? 'even' : 'odd'}
              >
                <StyledCell>
                  <MomentDate date={row.education_date_start} type='year' />
                </StyledCell>
                <StyledCell>
                  <MomentDate date={row.education_date_end} type='year' />
                </StyledCell>
                <StyledCell>{row.education_level}</StyledCell>
                <StyledCell>{row.school_name}</StyledCell>
                <StyledCell>{row.education_field}</StyledCell>
                <StyledCell>{row.degree_sought}</StyledCell>
                <StyledCell>{row.obtained_degree ? 'Yes' : 'No'}</StyledCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const StyledRowHead = styled(TableRow)`
  background: #d2d2d2;
  border-bottom: 2px solid darkgray;

  th {
    font-weight: bold;
  }
`

const StyledRow = styled(TableRow)`
  border-top: ${(props) => (props.nested ? 'none' : '1px solid lightgray')};
  background: #eee;

  &.even {
    background: #e3e3e3;
  }

  &:hover,
  &:focus {
    ${(props) => !props.nested && 'background: #d4d4d4;'};
  }
`

const StyledCell = styled(TableCell)`
  border-bottom: none;
`
