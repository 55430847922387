import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  target: null,
  targetID: null,
  localTarget: null,
  localTargetID: null,
  loading: true,
  startDate: null,
  endDate: null,
  bullets: null,
  selected_bullets: [],
  selected_briefs: [],
  folderName: null,
  folder_targets: [],
  selected_targets: [],
  selected_folders: [],
  selected_folder: null,
  bulletToEdit: null,
  briefToEdit: null,
  briefs: [],
  bulletForBrief: [],
  itemsToDelete: [],
  itemsChangeStatus: [],
  briefForBullet: [],
  keyword: null,
  excludeWord: null,
  status_to_set: null,
  bullet_to_copy: null,
  selected_types: [],
  selected_bullet_types: [],
  refreshList: true,
  folderRefetch: false,
  selected_statuses: [],
}

const bulletSlice = createSlice({
  name: 'bullet',
  initialState: initialState,
  reducers: {
    clearFilters(state) {
      state.selected_targets = []
      state.selected_statuses = []
      state.selected_types = []
      state.excludeWord = null
      state.keyword = null
      state.selected_bullet_types = []
      state.startDate = null
      state.endDate = null
      state.refreshList = !state.refreshList
    },
    changeStartDate(state, action) {
      state.startDate = action.payload
    },
    changeEndDate(state, action) {
      state.endDate = action.payload
    },
    addSelectedBullet(state, action) {
      state.selected_bullets.push(action.payload)
    },
    removeSelectedBullet(state, action) {
      state.selected_bullets.splice(
        state.selected_bullets.findIndex((i) => i.bullet_id === action.payload),
        1
      )
    },
    addSelectedBrief(state, action) {
      state.selected_briefs.push(action.payload)
    },
    removeSelectedBrief(state, action) {
      state.selected_briefs.splice(
        state.selected_briefs.findIndex((i) => i.brief_id === action.payload),
        1
      )
    },
    changeSelectedBriefs(state, action) {
      state.selected_briefs = action.payload
    },
    changeSelectedBullets(state, action) {
      state.selected_bullets = action.payload
    },

    changeTarget(state, action) {
      state.target = action.payload
    },
    changeTargetID(state, action) {
      state.targetID = action.payload
    },
    changeLocalTargetID(state, action) {
      state.localTargetID = action.payload
    },
    changeLocalTarget(state, action) {
      state.localTarget = action.payload
    },
    addSelectedTarget(state, action) {
      state.selected_targets.push(action.payload)
    },
    removeSelectedTarget(state, action) {
      state.selected_targets.splice(
        state.selected_targets.findIndex((i) => i.id === action.payload),
        1
      )
    },
    addSelectedFolder(state, action) {
      state.selected_folders.push(action.payload)
    },
    removeSelectedFolder(state, action) {
      state.selected_folders.splice(
        state.selected_folders.findIndex((i) => i.folder_id === action.payload),
        1
      )
    },
    setSelectedFolder(state, { payload }) {
      state.selected_folder = payload
    },
    changeBulletToEdit(state, action) {
      state.bulletToEdit = action.payload
    },
    changeBriefToEdit(state, action) {
      state.briefToEdit = action.payload
    },
    changeBriefs(state, action) {
      state.briefs = action.payload
    },
    changeKeyword(state, action) {
      state.keyword = action.payload
    },
    changeExcludeWord(state, action) {
      state.excludeWord = action.payload
    },
    addItemToDelete(state, action) {
      state.itemsToDelete.push(action.payload)
    },
    changeItemsToDelete(state, action) {
      state.itemsToDelete.push(action.payload)
      state.itemsToDelete.splice(
        state.itemsToDelete.findIndex((i) => i.id === action.payload.id),
        1
      )
    },
    addSelectedType(state, { payload }) {
      state.selected_types.push(payload)
    },
    removeSelectedType(state, { payload }) {
      state.selected_types.splice(
        state.selected_types.findIndex((i) => i === payload),
        1
      )
    },
    addSelectedBulletType(state, { payload }) {
      state.selected_bullet_types.push(payload)
    },
    removeSelectedBulletType(state, { payload }) {
      state.selected_bullet_types.splice(
        state.selected_bullet_types.findIndex((i) => i === payload),
        1
      )
    },
    addSelectedStatuses(state, { payload }) {
      state.selected_statuses.push(payload)
    },
    removeSelectedStatuses(state, { payload }) {
      state.selected_statuses.splice(
        state.selected_statuses.findIndex((i) => i === payload),
        1
      )
    },
    clearItemsToDelete(state) {
      state.itemsToDelete = []
    },
    addItemChangeStatus(state, action) {
      state.itemsChangeStatus.push(action.payload)
    },
    changeItemsChangeStatus(state, action) {
      state.itemsChangeStatus.push(action.payload)
      state.itemsChangeStatus.splice(
        state.itemsChangeStatus.findIndex((i) => i.id === action.payload.id),
        1
      )
    },
    clearItemsChangeStatus(state) {
      state.itemsChangeStatus = []
    },
    changeStatus(state, action) {
      state.status_to_set = action.payload
    },
    clearStatus(state) {
      state.status_to_set = null
    },
    changeBulletToCopy(state, action) {
      state.bullet_to_copy = action.payload
    },
    triggerRefreshList(state) {
      state.refreshList = !state.refreshList
    },
    setFolderTargets(state, { payload }) {
      state.folder_targets = payload
    },
    clearFolderTargets(state) {
      state.folder_targets = []
    },
    removeFolderTarget(state, { payload }) {
      state.folder_targets.splice(
        state.folder_targets.findIndex((i) => i.id === payload),
        1
      )
    },
    setFolderRefetch(state, { payload }) {
      state.folderRefetch = payload
    },
  },
})

export const {
  clearFilters,
  changeTarget,
  changeStartDate,
  changeEndDate,
  changeTargetID,
  addSelectedBullet,
  removeSelectedBullet,

  changeLocalTarget,
  changeLocalTargetID,
  addSelectedTarget,
  removeSelectedTarget,
  addSelectedFolder,
  removeSelectedFolder,
  setFolderTargets,
  setSelectedFolder,

  addSelectedBrief,
  removeSelectedBrief,
  changeBulletToEdit,
  changeBriefToEdit,
  changeBriefs,

  changeSelectedBriefs,
  changeSelectedBullets,

  changeKeyword,
  changeExcludeWord,

  addItemToDelete,
  changeItemsToDelete,
  clearItemsToDelete,

  addItemChangeStatus,
  changeItemsChangeStatus,
  clearItemsChangeStatus,

  changeStatus,
  clearStatus,

  changeBulletToCopy,
  addSelectedType,
  removeSelectedType,
  addSelectedBulletType,
  removeSelectedBulletType,
  addSelectedStatuses,
  removeSelectedStatuses,
  triggerRefreshList,
  setFolderRefetch,
} = bulletSlice.actions

export default bulletSlice.reducer
