const genericReq = ['bullet_header', 'bullet_cite_source', 'bullet_target']
const voteReq = ['bullet_header', 'leg_chamber', 'bill_number', 'bullet_target']
const recordReq = [
  'bullet_header',
  'record_source',
  'record_type',
  'bullet_target',
]
const mediaReq = ['bullet_header', 'network_name', 'bullet_target']

export const sanitizeBulletForm = async (formState, validate) => {
  let doContinue

  let formSubmit = { ...formState }
  switch (formState.bullet_type) {
    case 'GENERIC':
      doContinue = await validate(genericReq)
      if (!doContinue) {
        return
      }
      formSubmit.bullet_type = 'GENERIC'
      formSubmit.leg_chamber = null
      formSubmit.bill_number = null
      formSubmit.leg_sponsor = null
      formSubmit.leg_description = null
      formSubmit.leg_action = null
      formSubmit.leg_sponsorship_type = null
      formSubmit.leg_vote_question = null
      formSubmit.leg_vote_direction = null
      formSubmit.leg_vote_number = null

      formSubmit.record_number = null
      formSubmit.record_source = null
      formSubmit.record_type = null

      formSubmit.network_name = null
      formSubmit.network_user = null
      break
    case 'VOTE':
      doContinue = await validate(voteReq)

      if (!doContinue) {
        return
      }

      formSubmit.bullet_type = 'VOTE'

      formSubmit.record_number = null
      formSubmit.record_source = null
      formSubmit.record_type = null
      formSubmit.leg_action = formSubmit.leg_action.toUpperCase()

      formSubmit.network_name = null
      formSubmit.network_user = null
      break

    case 'PUBLIC_RECORD':
      doContinue = await validate(recordReq)

      if (!doContinue) {
        return
      }

      formSubmit.bullet_type = 'PUBLIC_RECORD'

      formSubmit.leg_chamber = null
      formSubmit.bill_number = null
      formSubmit.leg_sponsor = null
      formSubmit.leg_description = null
      formSubmit.leg_action = null
      formSubmit.leg_sponsorship_type = null
      formSubmit.leg_vote_question = null
      formSubmit.leg_vote_direction = null
      formSubmit.leg_vote_number = null

      formSubmit.network_name = null
      formSubmit.network_user = null
      break

    case 'SOCIAL_MEDIA':
      doContinue = await validate(mediaReq)

      if (!doContinue) {
        return
      }

      formSubmit.bullet_type = 'SOCIAL_MEDIA'

      formSubmit.leg_chamber = null
      formSubmit.bill_number = null
      formSubmit.leg_sponsor = null
      formSubmit.leg_description = null
      formSubmit.leg_action = null
      formSubmit.leg_sponsorship_type = null
      formSubmit.leg_vote_question = null
      formSubmit.leg_vote_direction = null
      formSubmit.leg_vote_number = null

      formSubmit.record_number = null
      formSubmit.record_source = null
      formSubmit.record_type = null
      break
    case 'CLIP':
      doContinue = await validate(genericReq)
      if (!doContinue) {
        return
      }
      formSubmit.bullet_type = 'CLIP'
      formSubmit.leg_chamber = null
      formSubmit.bill_number = null
      formSubmit.leg_sponsor = null
      formSubmit.leg_description = null
      formSubmit.leg_action = null
      formSubmit.leg_sponsorship_type = null
      formSubmit.leg_vote_question = null
      formSubmit.leg_vote_direction = null
      formSubmit.leg_vote_number = null

      formSubmit.record_number = null
      formSubmit.record_source = null
      formSubmit.record_type = null

      formSubmit.network_name = null
      formSubmit.network_user = null
      break
    default:
      return
  }
  return formSubmit
}
