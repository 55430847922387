import styled from 'styled-components'
import { useState, Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { Grid, Button } from '@material-ui/core'
import { pick } from 'lodash'

import { WContent, WActions } from '../../wizard'
import placeholderImage from '../../../static/img/placeholder-female.png'
import { setFormStatus } from '../../slices/wizardSlice'
import { getTrackingMapByObjectId } from '../../slices/wizardSlice'
import { FormErrors } from '../../util/FormErrors'
import { FormField } from '../../util/FormField'
import { MomentDate } from '../../util/MomentDate'

export const FormWithTracking = ({
  relationType = 'one',
  objectId,
  initialAttributes,
  fields,
  onSubmit,
  onSubmitSuccess = null,
  onExit = null,
  children,
}) => {
  let dispatch = useDispatch()
  const formStatus = useSelector((state) => state.wizard.formStatus)
  const [attributes, setAttributes] = useState(pick(initialAttributes, fields))
  const [errors, setErrors] = useState([])
  const [imagePreview, setImagePreview] = useState(
    initialAttributes.target_photo || placeholderImage
  )
  const trackingByField = useSelector((state) =>
    getTrackingMapByObjectId(state, objectId)
  )

  useEffect(() => {
    if (isEqual(initialAttributes, attributes)) {
      dispatch(setFormStatus('clean'))
    } else {
      dispatch(setFormStatus('dirty'))
    }
  }, [dispatch, fields, initialAttributes, attributes])

  useEffect(() => {
    setAttributes({ ...initialAttributes })
  }, [initialAttributes])

  const handleChange = (key, value) => {
    setAttributes({
      ...attributes,
      [key]: value,
    })
  }

  const handleUploadClick = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onloadend = (e) => {
      setImagePreview(reader.result)
    }

    setAttributes({
      ...attributes,
      [event.target.name]: file,
    })
  }

  const handleSubmit = async () => {
    try {
      dispatch(setFormStatus('pending'))
      await onSubmit(attributes)
      dispatch(setFormStatus('clean'))

      if (relationType === 'one') {
        onSubmitSuccess()
      }
    } catch (err) {
      dispatch(setFormStatus('dirty'))
      if (err.error === 'ValidationError') {
        setErrors(err.errors)
      } else {
        throw err
      }
    }
  }

  return (
    <>
      <FormErrors errors={errors} />
      <WContent style={{ width: '80%', margin: '0 auto' }}>
        <form autoComplete='off' noValidate>
          <Grid container spacing={3}>
            {Object.values(fields).map((field) => (
              <Fragment key={field.name}>
                {field.type === 'file' ? (
                  <Grid item xs={12}>
                    <StyledProfileImageWrapper>
                      <StyledProfileImage src={imagePreview} alt='Profile' />
                    </StyledProfileImageWrapper>
                    <Button
                      variant='contained'
                      component='label'
                      style={{ backgroundColor: 'purple', color: 'white' }}
                    >
                      {initialAttributes.target_photo
                        ? 'PICK ANOTHER'
                        : 'UPLOAD PHOTO'}
                      <input
                        type='file'
                        name={field.name}
                        accept='image/*'
                        hidden
                        onChange={handleUploadClick}
                      />
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={8}>
                      <FormField
                        field={field}
                        value={attributes[field.name] || ''}
                        onChange={handleChange}
                        formStatus={formStatus}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {trackingByField[field.name] && (
                        <StyledTracking>
                          <div>{trackingByField[field.name].source}</div>
                          <div>
                            <MomentDate
                              date={trackingByField[field.name].updated}
                              type='timestamp'
                            />
                          </div>
                        </StyledTracking>
                      )}
                    </Grid>
                  </>
                )}
              </Fragment>
            ))}
          </Grid>
        </form>
      </WContent>

      {children}

      {relationType === 'one' ? (
        <WActions>
          <Button variant='contained' onClick={handleSubmit}>
            CONTINUE
          </Button>
          <Button variant='contained' onClick={onExit}>
            EXIT
          </Button>
        </WActions>
      ) : (
        <WActions>
          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={formStatus === 'pending'}
          >
            SAVE
          </Button>
        </WActions>
      )}
    </>
  )
}

const StyledTracking = styled.div`
  height: 100%;
  font-size: 12px;
  color: gray;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

const StyledProfileImageWrapper = styled.div`
  margin: 0 auto;
  width: 110px;
  height: 135px;
  background: white;
  border: 5px solid white;
`

const StyledProfileImage = styled.img`
  height: 100%;
  object-fit: contain;
`
