import { useMemo, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'solid',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

export const StyledDropzone = ({ handleChange, currentFile }) => {
  const onDrop = useCallback((acceptedFiles) => {
    handleChange(acceptedFiles)
  }, [])
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.pdf'],
      'text/*': ['.csv', '.txt'],
      'video/*': ['.mov', '.mp4'],
      'audio/*': ['.mp3'],
      'application/*': [
        '.doc',
        '.docx',
        '.rtf',
        '.csv',
        '.xls',
        '.xlsx',
        '.xlsm',
        '.ppt',
        '.pptx',
        '.zip',
        '.bz',
        '.bz2',
      ],
    },
  })
  const acceptedFileItems = acceptedFiles.map((file) => (
    <span key={file.path}>{file.path}</span>
  ))

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <span key={file.path}>
      {file.path}
      <span>
        {errors.map((e) => (
          <span key={e.code}>{e.message}</span>
        ))}
      </span>
    </span>
  ))

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <div className='container'>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          Drag and drop or click to attach a file. Only one file can be
          attached.
        </p>
        <Row>
          {currentFile !== null && (
            <Col>
              <Typography variant='caption'>
                Current Attachment: {currentFile}
              </Typography>
            </Col>
          )}
          {acceptedFileItems.length !== 0 && (
            <Col>
              <Typography variant='caption'>
                Accepted Attachment: {acceptedFileItems}
              </Typography>
            </Col>
          )}
          {fileRejectionItems.length !== 0 && (
            <Col>
              <Typography variant='caption'>
                Rejected Attachment: {fileRejectionItems}
              </Typography>
            </Col>
          )}
        </Row>
      </div>
    </div>
  )
}
const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
  width: 100%;
`
