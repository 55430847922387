import styled from 'styled-components'
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from '@material-ui/core'

export const TargetOrganizations = (props) => {
  const { organizations } = props

  return (
    <Box sx={{ p: '1rem' }}>
      <TableContainer component={Box} sx={{ backgroundColor: '#EEE' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <StyledRowHead>
              <TableCell>Start Year</TableCell>
              <TableCell>End Year</TableCell>
              <TableCell>Organization Name</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Association Type</TableCell>
            </StyledRowHead>
          </TableHead>
          <TableBody>
            {organizations.map((organization, index) => (
              <StyledRow
                key={organization.organization_id}
                className={index % 2 === 0 ? 'even' : 'odd'}
              >
                <StyledCell>{organization.organization_start_year}</StyledCell>
                <StyledCell>{organization.organization_end_year}</StyledCell>
                <StyledCell>{organization.organization_name}</StyledCell>
                <StyledCell>{organization.organization_title}</StyledCell>
                <StyledCell>{organization.organization_type}</StyledCell>
                <StyledCell>
                  {organization.organization_association_type}
                </StyledCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const StyledRowHead = styled(TableRow)`
  background: #d2d2d2;
  border-bottom: 2px solid darkgray;

  th {
    font-weight: bold;
  }
`

const StyledRow = styled(TableRow)`
  border-top: ${(props) => (props.nested ? 'none' : '1px solid lightgray')};
  background: #eee;

  &.even {
    background: #e3e3e3;
  }

  &:hover,
  &:focus {
    ${(props) => !props.nested && 'background: #d4d4d4;'};
  }
`

const StyledCell = styled(TableCell)`
  border-bottom: none;
`
