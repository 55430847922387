import { pick } from 'lodash'

import { WTitle, WDescription } from '../../wizard'
import { api } from '../../../api/api'
import { TARGET_FIELDS } from '../../../utils/forms'
import { FormWithTracking } from './FormWithTracking'

export const WBiographyTab = ({ target, onSubmitSuccess, onExit }) => {
  const BIO_FIELDS = pick(TARGET_FIELDS, [
    'target_dob',
    'target_gender',
    'target_party',
    'target_religion',
  ])

  const handleSubmit = async (body) => {
    await api.patchTarget(target.target_id, body)
  }

  return (
    <>
      <WTitle>Other biographical information.</WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>
        If the target's party, religion, or other dropdown is not available,
        choose OTHER and the administrator will get in touch to add the correct
        option.
      </WDescription>
      <FormWithTracking
        objectId={target.target_id}
        initialAttributes={target}
        fields={BIO_FIELDS}
        onSubmit={handleSubmit}
        onSubmitSuccess={onSubmitSuccess}
        onExit={onExit}
      />
    </>
  )
}
