import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import clsx from 'clsx'
import {
  AppBar,
  Toolbar,
  makeStyles,
  Link as MaterialLink,
  IconButton,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { setLeftOpen, setModalOpen, setModalState } from '../slices/navSlice'
import { AppDrawer } from './Drawer'
import { api } from '../../api/api'
import { setUser } from '../slices/usersSlice'
import { DocumentLinks } from './DocumentLinks'

const drawerWidth = 250

const Navbar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const push = useNavigate()
  const { pathname } = useLocation()
  const user = useSelector((state) => state.user.user)
  const leftOpen = useSelector((state) => state.nav.leftOpen)
  const selectedBullets = useSelector((state) => state.bullet.selected_bullets)
  const selectedBriefs = useSelector((state) => state.bullet.selected_briefs)

  const handleDrawerOpen = () => {
    dispatch(setLeftOpen(true))
  }

  useEffect(() => {
    getLinks()
  }, [user])

  const getLinks = async () => {
    try {
      await api.getLinksByUser()
    } catch (err) {
      console.error(err)
    }
  }

  const validateRedirect = (path) => {
    if (selectedBullets.length > 0 || selectedBriefs.length > 0) {
      dispatch(setModalOpen(true))
      dispatch(setModalState('unsaved'))
      return
    } else {
      push(path)
    }
  }

  const logout = async () => {
    try {
      api.logout()
      dispatch(setUser(null))
      push('/login')
    } catch (err) {
      throw err
    }
  }

  return (
    <>
      <AppBar
        position='sticky'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: leftOpen,
        })}
      >
        <Toolbar>
          {!pathname.includes('login') && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, leftOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.toolbarTitle}>
            <MaterialLink
              variant='h5'
              onClick={() => validateRedirect('/')}
              color='inherit'
              className={clsx(classes.logo, {
                [classes.logoShift]: leftOpen,
              })}
              noWrap
            >
              Tappan Explorer
            </MaterialLink>
          </div>
          {user !== null && (
            <NavLinks>
              <DocumentLinks />
              <MaterialLink
                onClick={() => validateRedirect('/')}
                color='inherit'
              >
                Explorer
              </MaterialLink>
              <MaterialLink
                onClick={() => validateRedirect('/targets')}
                color='inherit'
              >
                Targets
              </MaterialLink>
              <MaterialLink onClick={() => logout()} color='inherit'>
                Logout
              </MaterialLink>
            </NavLinks>
          )}
        </Toolbar>
      </AppBar>
      <AppDrawer />
    </>
  )
}

const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: center;
  justify-self: flex-end;
  flex-grow: 1;
`

export default Navbar

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `.5px solid ${theme.palette.divider}`,
    backgroundColor: 'rgb(116, 153, 170)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarNoBullets: {
    borderBottom: `.5px solid ${theme.palette.divider}`,
    backgroundColor: 'rgb(116, 153, 170)',
  },
  hide: {
    display: 'none',
  },
  invis: {
    opacity: 0,
  },
  link: {
    'margin': theme.spacing(1, 1.5),
    'color': 'white',
    '&:hover': {
      color: 'yellow',
    },
    'cursor': 'pointer',
  },
  yellow: {
    color: 'yellow',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  logo: {
    '&:hover': {
      color: 'yellow',
    },
  },
  profileIcon: {
    marginLeft: '8px',
  },
}))
