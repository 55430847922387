/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import {
  Container,
  Grid,
  Button,
  makeStyles,
  Typography,
  TextField,
  Paper,
} from '@material-ui/core'

import {
  changeBriefToEdit,
  changeSelectedBullets,
  triggerRefreshList,
  changeLocalTargetID,
  changeLocalTarget,
} from '../slices/bulletSlice'
import { setRightOpen, setRightState } from '../slices/navSlice'
import { api } from '../../api/api'
import TargetPicker from '../util/TargetPicker'
import { DragDropper } from '../util/DragDropper'
import { Brief } from '../briefs/Brief'
import { isEmpty } from 'lodash'

export const BriefBuilder = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentTarget = useSelector((state) => state.bullet.localTargetID)
  const briefToEdit = useSelector((state) => state.bullet.briefToEdit)
  const selectedBullets = useSelector((state) => state.bullet.selected_bullets)
  const user = useSelector((state) => state.user.user)
  const rightState = useSelector((state) => state.nav.rightState)
  const [bullets, setBullets] = useState(
    briefToEdit !== null ? briefToEdit.bullets : []
  )
  const [newBrief, setNewBrief] = useState(null)
  const [isReady, setIsReady] = useState(false)
  //form submit state
  const [formState, setFormState] = useState(
    briefToEdit !== null
      ? briefToEdit
      : {
          brief_header: null,
          brief_body: null,
          brief_target: currentTarget,
          bullets: [],
          user: user,
        }
  )

  //errors state
  const [formErrors, setFormErrors] = useState({
    brief_header: false,
    brief_target: false,
  })
  useEffect(() => {
    if (currentTarget !== null) setIsReady(true)
    else setIsReady(false)
  }, [currentTarget])

  useEffect(() => {
    selectedBullets.forEach((item) => {
      let obj = { ...item, new: true }
      setBullets((bullets) => [...bullets, obj])
    })
  }, [])

  useEffect(() => {
    setFormState({
      ...formState,
      bullets,
    })
  }, [bullets])

  //if target changes in store thru target picker
  useEffect(() => {
    setFormState({
      ...formState,
      brief_target: currentTarget,
    })
  }, [currentTarget])

  useEffect(() => {
    if (briefToEdit !== null) {
      setFormState({
        ...briefToEdit,
      })
    }
  }, [briefToEdit])

  const handleChange = (field, event) => {
    setFormState({
      ...formState,
      [field]: event.target.value,
    })
    //clear errors when new input entered
    if (formErrors[field] === true) {
      setFormErrors({
        ...formErrors,
        [field]: false,
      })
    }
  }

  const handleSubmit = async (e) => {
    let dataOk = true
    let errorCopy = { ...formErrors }
    for (const formProp in formErrors) {
      if (formState[formProp] === null || formState[formProp] === '') {
        errorCopy[formProp] = true
        dataOk = false
      }
    }
    if (!dataOk) setFormErrors({ ...errorCopy })
    try {
      const createdBrief = await api.createBrief(formState)
      const id = createdBrief.brief_id
      if (!isEmpty(selectedBullets)) {
        try {
          const bulletsToAdd = []
          selectedBullets.forEach((obj) => {
            bulletsToAdd.push({
              bullet_order: bulletsToAdd.length,
              bullet: obj.bullet_id,
              brief: id,
            })
          })
          await api.updateBriefContents(bulletsToAdd)
        } catch (err) {
          throw err
        }
      }
      setNewBrief({ ...formState, brief_id: id })
      disbrief()
    } catch (err) {
      throw err
    }
  }
  const disbrief = () => {
    dispatch(setRightState(null))
    dispatch(setRightOpen(false))
    setFormState({
      brief_header: '',
      brief_body: '',
      bullets: [],
    })
    dispatch(changeSelectedBullets([]))
    dispatch(changeBriefToEdit(null))
    dispatch(triggerRefreshList())
    setBullets([])
    dispatch(changeLocalTarget(null))
    dispatch(changeLocalTargetID(null))
  }
  const handleSubmitChanges = async () => {
    let dataOk = true
    let errorCopy = { ...formErrors }
    for (const formProp in formErrors) {
      if (formState[formProp] == null || formState[formProp] === '') {
        errorCopy[formProp] = true
        dataOk = false
      }
    }
    if (!dataOk) setFormErrors({ ...errorCopy })

    const bulletsForm = []
    for (let i = 0; i < bullets.length; i++) {
      bulletsForm.push({
        bullet_order: i,
        bullet: bullets[i].bullet_id,
        brief: briefToEdit.brief_id,
      })
    }

    const formSubmit = { ...formState }
    formSubmit.bullets = bulletsForm
    try {
      api.updateBriefContents(bulletsForm)
      try {
        await api.updateBrief({
          body: formSubmit,
          id: briefToEdit.brief_id,
        })
      } catch (err) {
        throw err
      }
      disbrief()
    } catch (err) {
      throw err
    }
  }

  const cancel = () => {
    dispatch(setRightOpen(false))
    dispatch(setRightState(null))
    dispatch(changeBriefToEdit(null))
  }
  return (
    <BuilderContainer style={{ width: '800px' }}>
      <ScrollContainer>
        <Typography className={classes.header} variant='h5'>
          {briefToEdit === null ? 'Brief Builder' : 'Edit Brief'}
          <TargetPicker
            formErrors={formErrors}
            errorField='brief_target'
            setFormErrors={setFormErrors}
            formError={formErrors.brief_target}
            isGlobal={false}
          />
        </Typography>
        {isReady && (
          <Grid justifyContent='space-between' container>
            <Grid container direction='column'>
              <Grid item>
                <Typography variant='h5' style={{ marginTop: '1rem' }}>
                  Brief Header
                </Typography>
                <TextField
                  fullWidth
                  required
                  label='Required'
                  error={formErrors.brief_header}
                  value={formState.brief_header}
                  className={classes.marginTop8}
                  onChange={(e) => handleChange('brief_header', e)}
                />
              </Grid>
              <Grid container>
                <Typography variant='h5'>Body</Typography>
                <TextField
                  multiline
                  fullWidth
                  className={classes.marginTop8}
                  value={formState.brief_body}
                  onChange={(e) => handleChange('brief_body', e)}
                />
              </Grid>
              {bullets.length > 0 && (
                <Grid className={classes.marginTop8}>
                  <Typography variant='h5' style={{ marginTop: '1rem' }}>
                    Edit Bullet Order
                  </Typography>
                  <Paper className={classes.marginTop8}>
                    <DragDropper setItems={setBullets} dragItems={bullets} />
                  </Paper>
                </Grid>
              )}
              <Typography variant='h5' style={{ marginTop: '1rem' }}>
                Preview
              </Typography>
              <Brief inBuilder brief={formState} />
            </Grid>
          </Grid>
        )}
      </ScrollContainer>
      <ActionsContainer>
        <Grid className={classes.marginTop8} style={{ width: '100%' }}>
          <Row>
            <Button
              variant='contained'
              color='primary'
              onClick={
                briefToEdit === null ? handleSubmit : handleSubmitChanges
              }
            >
              Save
            </Button>
            {rightState === 'editBrief' && (
              <Button onClick={disbrief}>Clear Form</Button>
            )}
            <Button onClick={cancel}>Cancel</Button>
          </Row>
        </Grid>
      </ActionsContainer>
    </BuilderContainer>
  )
}

const BuilderContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 100%;
`
const ScrollContainer = styled.div`
  max-height: 85vh;
  overflow-y: scroll;
`
const ActionsContainer = styled.div`
  display: flex;
  align-self: end;
  width: 100%;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  marginTop8: {
    marginTop: '8px',
  },
  divider: {
    marginBottom: '12px',
  },
  marginTop24: {
    marginTop: '24px',
  },
  grow1: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
