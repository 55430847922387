import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { BallTriangle } from 'react-loader-spinner'
import { Container, Grid } from '@material-ui/core'

import { api } from '../../api/api'
import { Brief } from '../briefs/Brief'
import { Bullet } from '../bullets/Bullet'
import { ExplorerNav } from '../buttons/ButtonNav'
import { RightPane } from '../rightPane/RightPane'
import useWindowDimensions from '../util/WindowDims'
import { setList, updateList } from '../slices/explorerSlice'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'

export const Explorer = () => {
  const dispatch = useDispatch()
  const startDate = useSelector((state) => state.bullet.startDate)
  const endDate = useSelector((state) => state.bullet.endDate)
  const leftOpen = useSelector((state) => state.nav.leftOpen)
  const selectedBullets = useSelector((state) => state.bullet.selected_bullets)
  const selectedTargets = useSelector((state) => state.bullet.selected_targets)
  const selectedFolders = useSelector((state) => state.bullet.selected_folders)
  const selectedBriefs = useSelector((state) => state.bullet.selected_briefs)
  const selectedStatuses = useSelector(
    (state) => state.bullet.selected_statuses
  )
  const keyword = useSelector((state) => state.bullet.keyword)
  const excludeWord = useSelector((state) => state.bullet.excludeWord)
  const selectedThemes = useSelector((state) => state.theme.selectedThemes)
  const selectedDataType = useSelector((state) => state.bullet.selected_types)
  const selectedBulletType = useSelector(
    (state) => state.bullet.selected_bullet_types
  )
  const refreshList = useSelector((state) => state.bullet.refreshList)
  const itemsList = useSelector((state) => state.explorer.list)

  const [currentOffset, setCurrentOffset] = useState(0)
  const [targets, setTargets] = useState()
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(0)
  const [gettingMore, setGettingMore] = useState(false)
  const [filtered, setFiltered] = useState(false)
  const { width } = useWindowDimensions()
  const getTargets = async () => {
    try {
      const target = await api.getTargets()
      setTargets(target.data)
    } catch (err) {
      throw err
    }
  }
  useEffect(() => {
    getTargets()
  }, [])

  const getBullets = async () => {
    try {
      const results = await api.getBullets({
        startDate,
        endDate,
        fullTargets: targets,
        selectedTargets,
        selectedFolders,
        keyword,
        excludeWord,
        themes: selectedThemes,
        data_type: selectedDataType,
        bullet_type: selectedBulletType,
        record_status: selectedStatuses,
        currentOffset,
      })
      if (currentOffset === 0) dispatch(setList(results.results))
      else dispatch(updateList(results.results))
      setLoading(false)
      setCount(results.count)
      setGettingMore(false)
    } catch (err) {
      setLoading(false)
      setCount(0)
      setGettingMore(false)
      dispatch(setList([]))
      throw err
    }
  }

  useEffect(() => {
    if (filtered) getBullets()
    else {
      dispatch(setList([]))
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    targets,
    startDate,
    endDate,
    selectedFolders,
    selectedTargets,
    keyword,
    excludeWord,
    selectedThemes,
    selectedDataType,
    selectedBulletType,
    refreshList,
    selectedStatuses,
    currentOffset,
    filtered,
  ])

  useEffect(() => {
    setCurrentOffset(0)
    if (
      [startDate, endDate, keyword, excludeWord].every((arr) => arr === null) &&
      [
        selectedFolders,
        selectedTargets,
        selectedThemes,
        selectedDataType,
        selectedBulletType,
        selectedStatuses,
      ].every((arr) => arr.length === 0)
    ) {
      setFiltered(false)
      setLoading(false)
      setCount(0)
    } else {
      setFiltered(true)
      setLoading(true)
      setCount(0)
    }
  }, [
    startDate,
    endDate,
    selectedFolders,
    selectedTargets,
    keyword,
    excludeWord,
    selectedThemes,
    selectedDataType,
    selectedBulletType,
    selectedStatuses,
    refreshList,
  ])
  const handleContainerOnBottom = useCallback(() => {
    if (!gettingMore && count - itemsList.length > 0) {
      setGettingMore(true)
      setCurrentOffset(currentOffset + 1)
    }
  }, [currentOffset, gettingMore, count, itemsList])

  const containerRef = useBottomScrollListener(handleContainerOnBottom)

  return (
    <OuterContainer>
      {loading && (
        <Loading open={leftOpen}>
          <BallTriangle
            heigth='200'
            width='200'
            color='grey'
            arialLabel='loading-indicator'
          />
        </Loading>
      )}
      {!loading && !filtered ? (
        <Loading open={leftOpen}>
          <p>Select filter to continue</p>
        </Loading>
      ) : (
        <BulletsContainer open={leftOpen} width={width} ref={containerRef}>
          <Container>
            <RightPane />
            <ExplorerNav
              selectedBriefs={selectedBriefs}
              selectedBullets={selectedBullets}
              numCards={count}
              displaying={itemsList.length}
            />
            <Grid
              direction='column'
              container
              style={{
                marginTop: '72px',
              }}
            >
              {itemsList.map((item) =>
                item.type === 'Bullet' ? (
                  <Bullet key={'bullet' + item.bullet_id} bullet={item} />
                ) : (
                  <Brief key={'brief' + item.brief_id} brief={item} />
                )
              )}
              {gettingMore && (
                <GettingMore>
                  <BallTriangle
                    heigth='50'
                    width='50'
                    color='grey'
                    arialLabel='loading-indicator'
                  />
                </GettingMore>
              )}
            </Grid>
          </Container>
        </BulletsContainer>
      )}
    </OuterContainer>
  )
}

const Loading = styled.div`
  display: flex;
  width: ${(props) => (props.open ? 'calc(100% - 250)' : '100%')};
  height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.open ? '240px' : 'auto')};
`
const BulletsContainer = styled.div`
  width: ${(props) => (props.open ? 'calc(100% - 240px)' : '100%')};
  margin-left: ${(props) => (props.open ? '240px' : 'auto')};
  margin-top: 1rem;
  margin-bottom: 5rem;
  height: calc(100vh - 56px - 2rem);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
const GettingMore = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem;
`
const OuterContainer = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`
