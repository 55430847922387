import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import { changeStartDate, changeEndDate } from '../slices/bulletSlice'

export const DatePicker = () => {
  const dispatch = useDispatch()
  const startDate = useSelector((state) => state.bullet.startDate)
  const endDate = useSelector((state) => state.bullet.endDate)

  const handleStartChange = (date) => {
    dispatch(
      changeStartDate(date !== null ? `${date.format('YYYY-MM-DD')}` : null)
    )
  }

  const handleEndChange = (date) => {
    dispatch(
      changeEndDate(date !== null ? `${date.format('YYYY-MM-DD')}` : null)
    )
  }

  return (
    <div style={{ width: '100%', margin: '.25rem', padding: '0' }}>
      <DateForm
        id='startDate'
        label='Start Date'
        value={startDate}
        handleChange={handleStartChange}
      />
      <DateForm
        id='endDate'
        label='End Date'
        value={endDate}
        handleChange={handleEndChange}
      />
    </div>
  )
}

const DateForm = ({ id, label, value, handleChange }) => {
  const [selectedDate, setSelectedDate] = useState(value)
  const handleDateChange = (date) => {
    setSelectedDate(date)
    handleChange(date)
  }
  useEffect(() => {
    if (value === null) setSelectedDate(null)
  }, [value])
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant='inline'
        format='MM/DD/YYYY'
        margin='normal'
        id={id}
        label={label}
        value={selectedDate}
        onChange={(date) => handleDateChange(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  )
}
