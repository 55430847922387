import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

import { IconButtons } from './Buttons'

import {
  setRightState,
  setRightOpen,
  setModalOpen,
  setModalState,
} from '../slices/navSlice'
import {
  changeSelectedBriefs,
  changeSelectedBullets,
  changeBriefToEdit,
  addItemToDelete,
  addItemChangeStatus,
} from '../slices/bulletSlice'

export const ExplorerNav = ({
  selectedBullets,
  selectedBriefs,
  numCards,
  displaying,
}) => {
  const dispatch = useDispatch()
  const isClient = useSelector((state) => state.user.isClient)
  const leftOpen = useSelector((state) => state.nav.leftOpen)

  const [buttonState, setButtonState] = useState(null)

  const isEmpty = (arr) => {
    if (arr.length !== 0) return false
    else return true
  }

  useEffect(() => {
    if (isEmpty(selectedBullets) && isEmpty(selectedBriefs))
      setButtonState(null)
    if (selectedBriefs.length === 1 && isEmpty(selectedBullets))
      setButtonState('singleBrief')
    if (isEmpty(selectedBriefs) && selectedBullets.length === 1)
      setButtonState('singleBullet')
    if (isEmpty(selectedBriefs) && selectedBullets.length > 1)
      setButtonState('multipleBullets')
    if (selectedBriefs.length === 1 && selectedBullets.length >= 1)
      setButtonState('singleBriefWithBullets')
    if (selectedBriefs.length > 1) setButtonState('multipleBriefs')
  }, [selectedBriefs, selectedBullets])

  const createBullet = () => {
    dispatch(setRightState('newBullet'))
    dispatch(setRightOpen(true))
  }
  const createBrief = () => {
    dispatch(setRightState('newBrief'))
    dispatch(setRightOpen(true))
  }
  const viewFolders = () => {
    dispatch(setRightState('viewFolders'))
    dispatch(setRightOpen(true))
  }
  const handleDelete = () => {
    selectedBriefs.forEach((brief) => {
      dispatch(
        addItemToDelete({
          id: brief.brief_id,
          type: 'brief',
          header: brief.brief_header,
          record_status: brief.record_status,
        })
      )
    })
    selectedBullets.forEach((bullet) => {
      const citation_record = {
        bullet_cite_source: bullet.bullet_cite_source,
        bullet_cite_type: bullet.bullet_cite_type,
        record_source: bullet.record_source,
        record_type: bullet.record_type,
        record_number: bullet.record_number,
        network_name: bullet.network_name,
        network_user: bullet.network_user,
        amendment_number: bullet.amendment_number,
        bill_number: bullet.bill_number,
        bullet_type: bullet.bullet_type,
        bullet_cite_author: bullet.bullet_cite_author,
        leg_vote_number: bullet.leg_vote_number,
        leg_action: bullet.leg_action,
      }
      dispatch(
        addItemToDelete({
          id: bullet.bullet_id,
          type: 'bullet',
          header: bullet.bullet_header,
          record_status: bullet.record_status,
          citation_record,
        })
      )
    })
    dispatch(setModalState('delete'))
    dispatch(setModalOpen(true))
  }
  const handleChangeStatus = () => {
    dispatch(setModalState('changeStatus'))
    selectedBriefs.forEach((brief) => {
      dispatch(
        addItemChangeStatus({
          id: brief.brief_id,
          type: 'brief',
          header: brief.brief_header,
          record_status: brief.record_status,
        })
      )
    })
    selectedBullets.forEach((bullet) => {
      const citation_record = {
        bullet_cite_source: bullet.bullet_cite_source,
        bullet_cite_type: bullet.bullet_cite_type,
        record_source: bullet.record_source,
        record_type: bullet.record_type,
        record_number: bullet.record_number,
        network_name: bullet.network_name,
        network_user: bullet.network_user,
        amendment_number: bullet.amendment_number,
        bill_number: bullet.bill_number,
        bullet_type: bullet.bullet_type,
        bullet_cite_author: bullet.bullet_cite_author,
        leg_vote_number: bullet.leg_vote_number,
        leg_action: bullet.leg_action,
      }
      dispatch(
        addItemChangeStatus({
          id: bullet.bullet_id,
          type: 'bullet',
          header: bullet.bullet_header,
          record_status: bullet.record_status,
          citation_record,
        })
      )
    })
    dispatch(setModalState('changeStatus'))
    dispatch(setModalOpen(true))
  }

  const handleAddToFolder = () => {
    dispatch(setRightState('addToFolder'))
    dispatch(setRightOpen(true))
  }

  const handleAddToBrief = () => {
    dispatch(changeBriefToEdit(selectedBriefs[0]))
    dispatch(setRightState('editBrief'))
    dispatch(setRightOpen(true))
  }

  const clearSelection = () => {
    dispatch(changeSelectedBriefs([]))
    dispatch(changeSelectedBullets([]))
  }

  return (
    <Header leftOpen={leftOpen}>
      <Row>
        <Col>
          <Text variant='h6'>Results: {numCards}</Text>
          <Text variant='caption'>Displaying: {displaying}</Text>
        </Col>

        <Center>
          <Text variant='h6'>
            {`${selectedBullets.length} Bullets, and ${selectedBriefs.length}
                  Briefs selected`}
          </Text>
          {(selectedBriefs.length > 0 || selectedBullets.length > 0) && (
            <Clear>
              <IconButtons
                iconType={'clearSelected'}
                toolTipValue={'Clear Selection'}
                handleChange={clearSelection}
              />
            </Clear>
          )}
        </Center>
        <Icons>
          {[null].includes(buttonState) && !isClient && (
            <IconButtons
              iconType='newBullet'
              toolTipValue='Create New Bullet'
              handleChange={createBullet}
            />
          )}
          {[null, 'singleBullet', 'multipleBullets'].includes(buttonState) &&
            !isClient && (
              <IconButtons
                iconType='newBrief'
                toolTipValue='Create New Brief'
                handleChange={createBrief}
              />
            )}
          {buttonState !== null && !isClient && (
            <IconButtons
              iconType='status'
              toolTipValue='Change Status for Selected Item(s)'
              handleChange={handleChangeStatus}
            />
          )}
          {[null].includes(buttonState) && (
            <IconButtons
              iconType='viewFolders'
              toolTipValue='View Folders'
              handleChange={viewFolders}
            />
          )}
          {buttonState !== null && !isClient && (
            <IconButtons
              iconType='delete'
              toolTipValue='Delete Selected Item(s)'
              handleChange={handleDelete}
            />
          )}
          {buttonState === 'singleBriefWithBullets' && !isClient && (
            <IconButtons
              iconType='addBullet'
              toolTipValue='Add Bullet(s) to Brief'
              handleChange={handleAddToBrief}
            />
          )}
          {buttonState !== null && (
            <IconButtons
              iconType='addToFolder'
              toolTipValue='Add Selected Item(s) to Folder'
              handleChange={handleAddToFolder}
            />
          )}
        </Icons>
      </Row>
    </Header>
  )
}

const Header = styled.div`
  position: fixed;
  top: 60px;
  left: ${(props) => (props.leftOpen ? '240px' : '0px')};
  width: ${(props) => (props.leftOpen ? 'calc(100% - 240px)' : '100%')};
  padding: 1rem 0.5rem 0 0.5rem;
  z-index: 10;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  justify-content: flex-end;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const Text = styled(Typography)`
  margin-left: 1rem;
  margin-right: 5rem;
`

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  margin-right: 1rem;
  padding-bottom: 0.25rem;
  width: 20%;
`
const Clear = styled.span`
  margin-bottom: 0.15rem;
  align-self: end;
`
const Center = styled.div`
  display: flex;
  flex-direction: row;
  width: 35%;
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  text-align: left;
`
