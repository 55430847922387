import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'

export const NotFound = () => {
  const leftOpen = useSelector((state) => state.nav.leftOpen)

  return (
    <StyledContainer leftOpen={leftOpen}>
      <Typography variant="h5">Page not found</Typography>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  width: ${(props) => (props.leftOpen ? 'calc(100% - 250)' : '100%')};
  margin: 0;
  margin-left: ${(props) => (props.leftOpen ? '250px' : '0')};
`
