import {
  ACCOUNT_SOURCE_CHOICES,
  ACCOUNT_TYPE_CHOICES,
  ADDRESS_STATUS_CHOICES,
  ADDRESS_TYPE_CHOICES,
  CANDIDATE_TYPE_CHOICES,
  COMMITTEE_TYPE_CHOICES,
  DEGREE_CHOICES,
  EDUCATION_LEVEL_CHOICES,
  ELECTION_TYPE_CHOICES,
  FAMILY_TYPE_CHOICES,
  GENDER_CHOICES,
  ORGANIZATION_ASSOCIATION_CHOICES,
  ORGANIZATION_TYPE_CHOICES,
  OWNERSHIP_CHOICES,
  PARTY_CHOICES,
  RELIGION_CHOICES,
  STATE_CHOICES,
} from './choice_enums'

export const TARGET_FIELDS = {
  target_first_name: {
    label: 'First Name',
    name: 'target_first_name',
  },
  target_last_name: {
    label: 'Last Name',
    name: 'target_last_name',
  },
  target_title: {
    label: 'Title',
    name: 'target_title',
  },
  target_middle_name: {
    label: 'Middle',
    name: 'target_middle_name',
  },
  target_nick_name: {
    label: 'Nickname',
    name: 'target_nick_name',
  },
  target_preferred_first: {
    label: 'Preferred First',
    name: 'target_preferred_first',
  },
  target_preferred_last: {
    label: 'Preferred Last',
    name: 'target_preferred_last',
  },
  target_suffix: {
    label: 'Suffix',
    name: 'target_suffix',
  },
  target_dob: {
    label: 'Date of Birth',
    name: 'target_dob',
    type: 'date',
  },
  target_gender: {
    label: 'Gender',
    name: 'target_gender',
    type: 'select',
    options: GENDER_CHOICES,
  },
  target_party: {
    label: 'Party',
    name: 'target_party',
    type: 'select',
    options: PARTY_CHOICES,
  },
  target_religion: {
    label: 'Religion',
    name: 'target_religion',
    type: 'select',
    options: RELIGION_CHOICES,
  },
  target_photo: {
    name: 'target_photo',
    type: 'file',
  },
}

export const LINK_FIELDS = {
  account_source: {
    label: 'Source',
    name: 'account_source',
    type: 'select',
    options: ACCOUNT_SOURCE_CHOICES,
  },
  account_type: {
    label: 'Type',
    name: 'account_type',
    type: 'select',
    options: ACCOUNT_TYPE_CHOICES,
  },
  account: {
    label: 'URL, ID, etc.',
    name: 'account',
  },
}

export const FAMILY_FIELDS = {
  family_type: {
    label: 'Relationship',
    name: 'family_type',
    type: 'select',
    options: FAMILY_TYPE_CHOICES,
  },
  family_first_name: {
    label: 'First Name',
    name: 'family_first_name',
  },
  family_middle_name: {
    label: 'Middle Name',
    name: 'family_middle_name',
  },
  family_last_name: {
    label: 'Last Name',
    name: 'family_last_name',
  },
  family_nick_name: {
    label: 'Nickname',
    name: 'family_nick_name',
  },
  family_preferred_first: {
    label: 'Preferred First',
    name: 'family_preferred_first',
  },
  family_preferred_last: {
    label: 'Preferred Last',
    name: 'family_preferred_last',
  },
  family_suffix: {
    label: 'Suffix',
    name: 'family_suffix',
  },
  family_dob: {
    label: 'Date of Birth',
    name: 'family_dob',
    type: 'date',
  },
  family_gender: {
    label: 'Gender',
    name: 'family_gender',
    type: 'select',
    options: GENDER_CHOICES,
  },
  family_start_date: {
    label: 'Start Date',
    name: 'family_start_date',
    type: 'date',
  },
  family_end_date: {
    label: 'End Date',
    name: 'family_end_date',
    type: 'date',
  },
}

export const ADDRESS_FIELDS = {
  address_number: {
    label: 'Number',
    name: 'address_number',
  },
  address_street: {
    label: 'Street',
    name: 'address_street',
  },
  address_apt: {
    label: 'Apt/Unit',
    name: 'address_apt',
  },
  address_city: {
    label: 'City',
    name: 'address_city',
  },
  address_state: {
    label: 'State',
    name: 'address_state',
    type: 'select',
    options: STATE_CHOICES,
  },
  address_zip: {
    label: 'Zip',
    name: 'address_zip',
  },
  address_ownership: {
    label: 'Ownership',
    name: 'address_ownership',
    type: 'select',
    options: OWNERSHIP_CHOICES,
  },
  address_type: {
    label: 'Type',
    name: 'address_type',
    type: 'select',
    options: ADDRESS_TYPE_CHOICES,
  },
  address_status: {
    label: 'Status',
    name: 'address_status',
    type: 'select',
    options: ADDRESS_STATUS_CHOICES,
  },
  address_entity_id: {
    label: 'Google Entity ID',
    name: 'address_entity_id',
  },
  address_congressional_district: {
    label: 'Congressional District',
    name: 'address_congressional_district',
  },
  address_state_upper_district: {
    label: 'State District (Upper)',
    name: 'address_state_upper_district',
  },
  address_state_lower_district: {
    label: 'State District (Lower)',
    name: 'address_state_lower_district',
  },
  address_date_from: {
    label: 'Start Date',
    name: 'address_date_from',
    type: 'date',
  },
  address_date_to: {
    label: 'End Date',
    name: 'address_date_to',
    type: 'date',
  },
}

export const EDUCATION_FIELDS = {
  education_level: {
    label: 'Level',
    name: 'education_level',
    type: 'select',
    options: EDUCATION_LEVEL_CHOICES,
  },
  school_name: {
    label: 'School Name',
    name: 'school_name',
  },
  education_field: {
    label: 'Field',
    name: 'education_field',
  },
  degree_sought: {
    label: 'Degree Sought',
    name: 'degree_sought',
    type: 'select',
    options: DEGREE_CHOICES,
  },
  obtained_degree: {
    label: 'Obtained Degree?',
    name: 'obtained_degree',
    type: 'boolean',
  },
  education_date_start: {
    label: 'Start Date',
    name: 'education_date_start',
    type: 'date',
  },
  education_date_end: {
    label: 'End Date',
    name: 'education_date_end',
    type: 'date',
  },
}

export const CANDIDACY_FIELDS = {
  office: {
    label: 'Office',
    name: 'office',
  },
}

export const ELECTION_FIELDS = {
  candidacy_id: {
    label: 'Candidacy',
    name: 'candidacy_id',
    type: 'select',
    selector: (state) => state.target.candidacies,
    options: (candidacies) => {
      return candidacies.reduce((acc, candidacy) => {
        acc[candidacy.candidacy_id] = candidacy.office
        return acc
      }, {})
    },
  },
  election_type: {
    label: 'Type',
    name: 'election_type',
    type: 'select',
    options: ELECTION_TYPE_CHOICES,
  },
  election_year: {
    label: 'Year',
    name: 'election_year',
  },
  election_office: {
    label: 'Office',
    name: 'election_office',
  },
  election_district: {
    label: 'District',
    name: 'election_district',
  },
  election_open: {
    label: 'Open Seat?',
    name: 'election_open',
    type: 'boolean',
  },
  target_candidate_type: {
    label: 'Candidate Type',
    name: 'target_candidate_type',
    type: 'select',
    options: CANDIDATE_TYPE_CHOICES,
  },
  election_winner: {
    label: 'Won Election?',
    name: 'election_winner',
    type: 'boolean',
  },
}

export const GOVERNING_FIELDS = {
  candidacy_id: {
    label: 'Candidacy',
    name: 'candidacy_id',
    type: 'select',
    selector: (state) => state.target.candidacies,
    options: (candidacies) => {
      return candidacies.reduce((acc, candidacy) => {
        acc[candidacy.candidacy_id] = candidacy.office
        return acc
      }, {})
    },
  },
  office: {
    label: 'Office',
    name: 'office',
  },
  office_district: {
    label: 'District',
    name: 'office_district',
  },
  office_start_year: {
    label: 'Start Year',
    name: 'office_start_year',
  },
  office_end_year: {
    label: 'End Year',
    name: 'office_end_year',
  },
}

export const CAMPAIGN_FINANCE_FIELDS = {
  candidacy_id: {
    label: 'Candidacy',
    name: 'candidacy_id',
    type: 'select',
    selector: (state) => state.target.candidacies,
    options: (candidacies) => {
      return candidacies.reduce((acc, candidacy) => {
        acc[candidacy.candidacy_id] = candidacy.office
        return acc
      }, {})
    },
  },
  committee_name: {
    label: 'Committee Name',
    name: 'committee_name',
  },
  committee_id: {
    label: 'ID',
    name: 'committee_id',
  },
  committee_type: {
    label: 'Type',
    name: 'committee_type',
    type: 'select',
    options: COMMITTEE_TYPE_CHOICES,
  },
}

export const ORGANIZATION_FIELDS = {
  organization_name: {
    label: 'Name',
    name: 'organization_name',
  },
  organization_title: {
    label: 'Title',
    name: 'organization_title',
  },
  organization_type: {
    label: 'Type',
    name: 'organization_type',
    type: 'select',
    options: ORGANIZATION_TYPE_CHOICES,
  },
  organization_association: {
    label: 'Association',
    name: 'organization_association',
    type: 'select',
    options: ORGANIZATION_ASSOCIATION_CHOICES,
  },
  organization_google_entity_id: {
    label: 'Google Entity ID',
    name: 'organization_google_entity_id',
  },
  organization_start_year: {
    label: 'Start Year',
    name: 'organization_start_year',
  },
  organization_end_year: {
    label: 'End Year',
    name: 'organization_end_year',
  },
}
