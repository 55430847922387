import styled from 'styled-components'
import { useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from '@material-ui/core'
import {
  KeyboardArrowRight,
  KeyboardArrowDown,
  EditOutlined,
} from '@mui/icons-material'
import { AddressEditDialog } from './AddressEditDialog'

import { api } from '../../api/api'
import { MomentDate } from '../util/MomentDate'
import { AddressPresenter } from '../../presenters/address_presenter'

export const TargetAddresses = () => {
  const isReadOnly = useSelector((state) => state.user.client)
  const addresses = useSelector((state) => state.target.addresses)
  const [isExpanded, setExpanded] = useState({})
  const [editingAddress, setEditingAddress] = useState()
  const presentableAddresses = addresses.map(
    (address) => new AddressPresenter(address)
  )

  const toggleExpandAddress = (address_id) => {
    setExpanded((prevState) => {
      return { ...prevState, [address_id]: !prevState[address_id] }
    })
  }

  const handleClickEdit = (address_id) => {
    setEditingAddress(address_id)
  }

  const onCloseEdit = () => {
    setEditingAddress(null)
  }

  const onSaveEdit = async (id, addressAttributes) => {
    return await api.updateAddress(id, addressAttributes)
  }

  return (
    <Box sx={{ p: '1rem' }}>
      <TableContainer component={Box} sx={{ backgroundColor: '#EEE' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <StyledRowHead>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Start Year</TableCell>
              <TableCell>End Year</TableCell>
              <TableCell>Number &amp; Street</TableCell>
              <TableCell>Apt</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Zip</TableCell>
              {!isReadOnly && <TableCell>Actions</TableCell>}
            </StyledRowHead>
          </TableHead>
          <TableBody>
            {presentableAddresses.map((address, index) => (
              <Fragment key={address.address_id}>
                <StyledRow
                  className={index % 2 === 0 ? 'even' : 'odd'}
                  onClick={() => toggleExpandAddress(address.address_id)}
                >
                  <StyledCell>
                    {isExpanded[address.address_id] ? (
                      <KeyboardArrowDown fontSize='small' />
                    ) : (
                      <KeyboardArrowRight fontSize='small' />
                    )}
                  </StyledCell>
                  <StyledCell>
                    <MomentDate date={address.address_date_from} type='year' />
                  </StyledCell>
                  <StyledCell>
                    <MomentDate date={address.address_date_to} type='year' />
                  </StyledCell>
                  <StyledCell>{address.addressLineOne}</StyledCell>
                  <StyledCell>{address.address_apt}</StyledCell>
                  <StyledCell>{address.address_city}</StyledCell>
                  <StyledCell>{address.address_state}</StyledCell>
                  <StyledCell>{address.address_zip}</StyledCell>
                  {!isReadOnly && (
                    <StyledCell>
                      <EditOutlined
                        sx={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleClickEdit(address.address_id)
                        }}
                      />
                    </StyledCell>
                  )}
                </StyledRow>
                {isExpanded[address.address_id] && (
                  <StyledRow
                    nested={'yes'}
                    className={index % 2 === 0 ? 'even' : 'odd'}
                  >
                    <StyledExtraCell colSpan={9}>
                      <ul>
                        <li>Own/Rent: {address.address_ownership}</li>
                        <li>Type: {address.address_type}</li>
                        <li>Status: {address.address_status}</li>
                        <li>Google Entity ID: {address.address_entity_id}</li>
                        <li>
                          Congressional:{' '}
                          {address.address_congressional_district}
                        </li>
                        <li>
                          State-Upper: {address.address_state_upper_district}
                        </li>
                        <li>
                          State-Lower: {address.address_state_lower_district}
                        </li>
                      </ul>
                    </StyledExtraCell>
                  </StyledRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AddressEditDialog
        open={!!editingAddress}
        onCloseEdit={onCloseEdit}
        onSaveEdit={onSaveEdit}
        initialAddress={addresses.find(
          (adx) => adx.address_id === editingAddress
        )}
      />
    </Box>
  )
}

const StyledRowHead = styled(TableRow)`
  background: #d2d2d2;
  border-bottom: 2px solid darkgray;

  th {
    font-weight: bold;
  }
`

const StyledRow = styled(TableRow)`
  border-top: ${(props) => (props.nested ? 'none' : '1px solid lightgray')};
  background: #eee;

  &.even {
    background: #e3e3e3;
  }

  &:hover,
  &:focus {
    ${(props) => !props.nested && 'background: #d4d4d4;'};
  }
`

const StyledCell = styled(TableCell)`
  border-bottom: none;
`

const StyledExtraCell = styled(TableCell)`
  padding: 1rem 2rem 2rem;

  ul {
    margin: 0;
    padding-left: 1rem;
  }
`
