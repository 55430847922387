import { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  Typography,
  makeStyles,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Button,
} from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import {
  addSelectedType,
  addSelectedBulletType,
  addSelectedStatuses,
} from '../slices/bulletSlice'
const data = {
  data_type: [
    'Text',
    'Image',
    'Video',
    'Audio',
    'Table',
    'Presentation',
    'Document',
    'File',
  ],
  bullet_type: ['Vote', 'Generic', 'Public Record', 'Social Media', 'Clip'],
  record_status: ['Published', 'Editing', 'Pending Review'],
}

const TypePicker = ({ pickerType }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const dispatch = useDispatch()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) return
    setOpen(false)
  }

  const handleMenuItemClose = (e, type) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) return
    if (pickerType === 'data_type') dispatch(addSelectedType(type))
    else if (pickerType === 'record_status') dispatch(addSelectedStatuses(type))
    else dispatch(addSelectedBulletType(type))
    setOpen(false)
  }

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      <Button
        className={classes.targetSelect}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <Typography>
          {pickerType === 'data_type'
            ? 'Data Type'
            : pickerType === 'bullet_type'
            ? 'Bullet Type'
            : 'Record Status'}
        </Typography>
        <KeyboardArrowDown />
      </Button>
      <Popper
        className={classes.highIndex}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                >
                  {data[pickerType].map((type) => {
                    return (
                      <MenuItem
                        className={classes.item}
                        onClick={(e) => handleMenuItemClose(e, type)}
                        id={type}
                        key={type}
                      >
                        {type}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default TypePicker

const useStyles = makeStyles((theme) => ({
  targetSelect: {
    justifyContent: 'space-between',
    width: '100%',
    textTransform: 'none',
    textAlign: 'left',
    marginLeft: '-0.75rem',
  },
  highIndex: {
    zIndex: 9999,
  },
  paper: {
    left: '5px',
    marginLeft: '1rem !important',
    width: 'auto',
    maxHeight: '400px',
    overflowX: 'scroll',
  },
  item: {
    overflowWrap: 'normal',
    fontSize: '.75rem',
  },
}))
