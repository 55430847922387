import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { Citations } from '../bullets/Citations'
import { isEmpty } from 'lodash'

export const AddToFolder = () => {
  const selectedBullets = useSelector((state) => state.bullet.selected_bullets)
  const selectedBriefs = useSelector((state) => state.bullet.selected_briefs)
  const selectedFolder = useSelector((state) => state.bullet.selected_folder)
  const [folderTargets, setFolderTargets] = useState([])
  const allTargets = useSelector((state) => state.user.targets)

  useEffect(() => {
    const tempFolders = []
    selectedBullets.forEach((bullet) => {
      tempFolders.push(bullet.bullet_target)
    })
    selectedBriefs.forEach((brief) => {
      tempFolders.push(brief.brief_target)
    })
    tempFolders.push(...selectedFolder.folder_target)

    const tempTargets = []
    tempFolders.forEach((target) => {
      const match = allTargets.find((item) => item.target_id === target)
      if (!isEmpty(match) && !tempTargets.includes(match.target_full_name))
        tempTargets.push(match.target_full_name)
    })
    setFolderTargets(tempTargets)
  }, [])

  const getTargetName = (target) => {
    const name = allTargets.find(({ target_id }) => target_id === target)
    return !isEmpty(name) ? name : ''
  }
  return (
    <>
      <Typography variant={'h5'} style={{ margin: '1rem 0 1rem 0' }}>
        {selectedFolder && (
          <Col>
            <Row>Folder: {selectedFolder.folder_name}</Row>
            <Row>Targets: {folderTargets.map((target) => `${target}, `)}</Row>
          </Col>
        )}
      </Typography>
      <Typography variant={'h5'} style={{ margin: '1rem 0 1rem 0' }}>
        {(selectedBullets || selectedFolder.bullets.length > 0) && (
          <Row>
            <Left>Bullet(s)</Left>
          </Row>
        )}
      </Typography>
      <Typography>
        {!isEmpty(selectedBullets) && <Row>Incoming</Row>}
        {!isEmpty(selectedBullets) &&
          selectedBullets.map((entry, index) => (
            <Col key={entry.id + index + 'modal'}>
              <TopRow>
                <Left>{entry.bullet_header}</Left>
                <Right>
                  <Citations bullet={entry} isModal={true} />
                </Right>
              </TopRow>
              <Row>
                <Left>
                  Target: {getTargetName(entry.bullet_target).target_full_name}
                </Left>
                <Right>
                  {entry.record_status.charAt(0).toUpperCase() +
                    entry.record_status
                      .slice(1)
                      .toLowerCase()
                      .replace('_', ' ')}
                </Right>{' '}
              </Row>
            </Col>
          ))}
      </Typography>
      <Typography>
        {!isEmpty(selectedFolder.bullets) && <Row>Exisiting</Row>}
        {!isEmpty(selectedFolder.bullets) &&
          selectedFolder.bullets.map((entry, index) => (
            <Col
              key={entry.id + index + 'modal'}
              style={{ backgroundColor: 'lightgrey' }}
            >
              <TopRow>
                <Left>{entry.bullet_header}</Left>
                <Right>
                  <Citations bullet={entry} isModal={true} />
                </Right>
              </TopRow>
              <Row>
                <Left>
                  Target: {getTargetName(entry.bullet_target).target_full_name}
                </Left>
                <Right>
                  {entry.record_status.charAt(0).toUpperCase() +
                    entry.record_status
                      .slice(1)
                      .toLowerCase()
                      .replace('_', ' ')}
                </Right>{' '}
              </Row>
            </Col>
          ))}
      </Typography>
      <Typography variant={'h5'} style={{ margin: '1rem 0 1rem 0' }}>
        {selectedFolder.briefs &&
          (selectedFolder.briefs.length > 0 || selectedBriefs.length > 0) && (
            <Row>
              <Left>Brief(s)</Left>
            </Row>
          )}
      </Typography>
      <Typography>
        {!isEmpty(selectedBriefs) && <Row>Incoming</Row>}
        {selectedBriefs.map((entry) => (
          <Col key={entry.id + 'modal'}>
            <TopRow>
              <Left>{entry.brief_header}</Left>
            </TopRow>
            <Row>
              <Left>
                Target: {getTargetName(entry.brief_target).target_full_name}
              </Left>
              <Right>
                {entry.record_status.charAt(0).toUpperCase() +
                  entry.record_status.slice(1).toLowerCase().replace('_', ' ')}
              </Right>
            </Row>
          </Col>
        ))}
      </Typography>
      <Typography>
        {!isEmpty(selectedFolder.briefs) && <Row>Exisiting</Row>}
        {!isEmpty(selectedFolder.briefs) &&
          selectedFolder.briefs.map((entry) => (
            <Col
              key={entry.id + 'modal'}
              style={{ backgroundColor: 'lightgrey' }}
            >
              <TopRow>
                <Left>{entry.brief_header}</Left>
              </TopRow>
              <Row>
                <Left>
                  Target: {getTargetName(entry.brief_target).target_full_name}
                </Left>
                <Right>
                  {entry.record_status !== undefined &&
                    entry.record_status.charAt(0).toUpperCase() +
                      entry.record_status
                        .slice(1)
                        .toLowerCase()
                        .replace('_', ' ')}
                </Right>
              </Row>
            </Col>
          ))}
      </Typography>
    </>
  )
}

const Row = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid lightgrey 1px;
  margin-bottom: 1rem;
`
const TopRow = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Col = styled.span`
  width: 100%;
  text-align: left;
`
const Left = styled.span`
  width: 75%;
`
const Right = styled.span`
  align-self: flex-end;
  width: 23%;
`
