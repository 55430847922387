import { useRef, useEffect } from 'react'

import {
  Typography,
  Grow,
  Paper,
  Popper,
  Button,
  MenuList,
  ClickAwayListener,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { selectStyles } from '../builderStyles'

export const SelectLayout = (props) => {
  const classes = selectStyles()
  const { open, setOpen, title, children } = props

  const anchorRef = useRef(null)

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }
  return (
    <div className={classes.maxo}>
      <Button
        variant='text'
        className={classes.targetSelect}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        style={{
          borderRadius: 0,
          borderBottom: '1px grey solid',
          color: 'grey',
        }}
      >
        <Typography style={{ textTransform: 'Capitalize' }}>{title}</Typography>
        <KeyboardArrowDownIcon />
      </Button>
      <Popper
        className={classes.highIndex}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='menu-list-bullet-grow'
                  onKeyDown={handleListKeyDown}
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
