import { compact } from 'lodash'
import { ObjectPresenter } from './object_presenter'

export class AddressPresenter extends ObjectPresenter {
  get addressLineOne() {
    return compact([
      this.object.address_number,
      this.object.address_street,
    ]).join(' ')
  }

  get addressCityState() {
    return compact([this.object.address_city, this.object.address_state]).join(
      ', '
    )
  }
}
