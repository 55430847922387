import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Edit, AddCircle } from '@material-ui/icons'
import { Typography, Button } from '@material-ui/core'

import { api } from '../../../api/api'
import { FormWithTracking } from './FormWithTracking'
import {
  WActions,
  WContent,
  WTitle,
  WDescription,
  WList,
  WListItem,
  WListItemActions,
} from '../../wizard'
import { GOVERNING_FIELDS } from '../../../utils/forms'
import { addGoverning, updateGoverning } from '../../slices/targetSlice'
import { MomentRange } from '../../util/MomentRange'

const GoverningLine = ({ governing }) => (
  <>
    {governing.office}, {governing.office_district}
    &nbsp;
    <MomentRange
      fromDate={governing.office_start_year}
      toDate={governing.office_end_year}
      parser='YYYY'
    />
  </>
)

export const WGoverningsTab = ({ onSubmitSuccess, onExit }) => {
  let dispatch = useDispatch()

  const governings = useSelector((state) => state.target.governings)
  const [governing, setGoverning] = useState()

  const handleAdd = () => {
    setGoverning({})
  }

  const handleSubmit = async (body) => {
    if (governing.governing_id) {
      const response = await api.updateGoverning(governing.governing_id, body)
      dispatch(updateGoverning(response.data))
    } else {
      const response = await api.createGoverning({
        ...body,
      })
      dispatch(addGoverning(response.data))
    }

    setGoverning(null)
  }

  return (
    <>
      <WTitle>
        If the target has held office, provide those details here.
      </WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>
        For consecutive terms in the same seat, submit multiple entries with
        start and end years accordingly.
      </WDescription>
      <WContent style={{ paddingBottom: '2rem' }}>
        <WList style={{ width: '80%' }}>
          <WListItem onClick={handleAdd}>
            Add Another
            <WListItemActions>
              <AddCircle />
            </WListItemActions>
          </WListItem>
          {governings.map((governing) => (
            <WListItem
              key={governing.governing_id}
              onClick={() => setGoverning(governing)}
            >
              <GoverningLine governing={governing} />
              <WListItemActions>
                <Edit />
              </WListItemActions>
            </WListItem>
          ))}
        </WList>
      </WContent>

      {governing && (
        <>
          <Typography variant='h5' style={{ paddingBottom: '1rem' }}>
            {governing.governing_id ? (
              <>
                Editing <GoverningLine governing={governing} />
              </>
            ) : (
              'Add New Office Held'
            )}
          </Typography>
          <FormWithTracking
            objectId={governing.governing_id}
            relationType='many'
            initialAttributes={governing}
            fields={GOVERNING_FIELDS}
            onSubmit={handleSubmit}
          />
        </>
      )}

      <WActions>
        <Button variant='contained' onClick={onSubmitSuccess}>
          CONTINUE
        </Button>
        <Button variant='contained' onClick={onExit}>
          EXIT
        </Button>
      </WActions>
    </>
  )
}
