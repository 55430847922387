import moment from 'moment'

const TYPE_TO_FORMAT = {
  L: 'L', // 03/05/1986
  year: 'YYYY',
  timestamp: 'l h:mma',
  dob: 'MMM D, YYYY',
}

export const MomentDate = ({ date, type = 'L' }) => {
  if (!date) return null

  return <>{moment(date).format(TYPE_TO_FORMAT[type])}</>
}
