import { makeStyles, Grid, Paper, Typography } from '@material-ui/core'
import Note from '@material-ui/icons/Note'

const Report = ({ report }) => {
  const classes = useStyles()

  const { report_name } = report

  return (
    <Grid item xs={3}>
      <Paper className={classes.container}>
        <Note fontSize="large" />
        <Typography className={classes.paper}>{report_name}</Typography>
      </Paper>
    </Grid>
  )
}

export default Report

const useStyles = makeStyles({
  card: {
    minWidth: '100%',
  },
  bold: {
    fontWeight: 600,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100px',
  },
  paper: {
    maxWidth: '90%',
  },
})
