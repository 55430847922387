import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { TextField, MenuItem } from '@material-ui/core'

import { DatePicker } from '../builders/forms/DatePicker'

export const FormField = ({ field, value, onChange, formStatus }) => {
  const selectedValue = useSelector(field.selector || ((state) => state))

  const handleDateChange = (name, changes) => {
    onChange(name, changes.target.value)
  }

  const handleToggleChange = (event, value) => {
    onChange(event.target.name, value)
  }

  const handleChange = (event) => {
    onChange(event.target.name, event.target.value)
  }

  const renderField = (field) => {
    switch (field.type) {
      case 'date':
        return (
          <DatePicker
            fullWidth
            margin='none'
            disabled={formStatus === 'pending'}
            label={field.label}
            fieldName={field.name}
            currentDate={value || null}
            handleChange={handleDateChange}
          />
        )
      case 'boolean':
        return (
          <StyledToggleWrapper>
            <label>{field.label}</label>

            <ToggleButtonGroup
              value={value || ''}
              exclusive
              label={field.label}
              onChange={handleToggleChange}
            >
              <ToggleButton name={field.name} value={true}>
                Yes
              </ToggleButton>
              <ToggleButton name={field.name} value={false}>
                No
              </ToggleButton>
            </ToggleButtonGroup>
          </StyledToggleWrapper>
        )
      case 'select':
        const options =
          typeof field.options === 'function'
            ? field.options(selectedValue)
            : field.options

        return (
          <TextField
            fullWidth
            select
            disabled={formStatus === 'pending'}
            style={{ textAlign: 'left' }}
            label={field.label}
            name={field.name}
            onChange={handleChange}
            value={value || ''}
          >
            <MenuItem value=''>Select a {field.label}</MenuItem>
            {Object.keys(options).map((key) => (
              <MenuItem key={key} value={key}>
                {options[key]}
              </MenuItem>
            ))}
          </TextField>
        )
      default: // text
        return (
          <TextField
            fullWidth
            disabled={formStatus === 'pending'}
            label={field.label}
            name={field.name}
            onChange={handleChange}
            value={value || ''}
          />
        )
    }
  }

  return <>{renderField(field)}</>
}

const StyledToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
