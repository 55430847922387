import { useSelector } from 'react-redux'
import { Drawer } from '@material-ui/core'

import { BriefBuilder } from '../builders/BriefBuilder'
import { BulletBuilder } from '../builders/BulletBuilder'
import { FoldersPage } from '../folders/FoldersPage'
import { ErrorNote } from './Error'

export const RightPane = () => {
  const paneState = useSelector((state) => state.nav.rightState)
  const rightOpen = useSelector((state) => state.nav.rightOpen)
  return (
    <Drawer anchor={'right'} open={rightOpen} variant="persistent">
      {{
        editBullet: <BulletBuilder />,
        copyBullet: <BulletBuilder />,
        editBrief: <BriefBuilder />,
        newBullet: <BulletBuilder />,
        newBrief: <BriefBuilder />,
        viewFolders: <FoldersPage />,
        addToFolder: <FoldersPage newFolder={true} />,
      }[paneState] || <ErrorNote />}
    </Drawer>
  )
}
