import axios from 'axios'
import { errorHandler } from './errorHandler'

export const reports = {
  getReports: async (id) => {
    try {
      return await axios.get(`/reports/`)
    } catch (err) {
      errorHandler(err)
    }
  },
  getLinksByUser: async () => {
    try {
      const res = await axios.get('reports/drives/user')
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
}
