import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { api } from '../../api/api'

const initialState = {
  fetchStatus: 'idle', // idle | loading | succeeded | failed
  error: '', // string

  state: '',
  target_id: '',
  target_bio: '',
  target_dob: '',
  target_first_name: '',
  target_full_name: '',
  target_gender: '',
  target_last_name: '',
  target_middle_name: '',
  target_nick_name: '',
  target_party: '',
  target_photo: '',
  target_preferred_first: '',
  target_preferred_last: '',
  target_religion: '',
  target_suffix: '',
  target_title: '',
  created: null,
  updated: null,

  birth_address: [],
  home_address: [],

  addresses: [],
  education: [],
  candidacies: [],
  elections: [],
  family_members: [],
  governings: [],
  links: [],
  organizations: [],
  committees: [],
}

export const fetchTargetDetails = createAsyncThunk(
  'targets/fetchTargetDetails',
  async (targetId) => {
    const response = await api.getTargetDetail({ id: targetId })
    return response.data
  }
)

export const fetchTargetDetailsBackground = createAsyncThunk(
  'targets/fetchTargetDetailsBackground',
  async (targetId) => {
    const response = await api.getTargetDetail({ id: targetId })
    return response.data
  }
)

const targetSlice = createSlice({
  name: 'target',
  initialState: initialState,
  reducers: {
    updateTarget: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key]
      })
    },

    addAddress: (state, { payload }) => {
      state.addresses.push(payload)
    },
    updateAddress: (state, { payload }) => {
      const index = state.addresses.findIndex(
        (address) => address.address_id === payload.address_id
      )
      state.addresses[index] = { ...state.addresses[index], ...payload }
    },
    deleteAddress: (state, { payload }) => {
      state.addresses = state.addresses.filter(
        (address) => address.address_id !== payload.address_id
      )
    },

    addLink: (state, { payload }) => {
      state.links.push(payload)
    },
    updateLink: (state, { payload }) => {
      const index = state.links.findIndex(
        (link) => link.link_id === payload.link_id
      )
      state.links[index] = { ...state.links[index], ...payload }
    },
    deleteLink: (state, { payload }) => {
      state.links = state.links.filter(
        (link) => link.link_id !== payload.link_id
      )
    },

    addFamily: (state, { payload }) => {
      state.family_members.push(payload)
    },
    updateFamily: (state, { payload }) => {
      const index = state.family_members.findIndex(
        (family) => family.family_id === payload.family_id
      )
      state.family_members[index] = {
        ...state.family_members[index],
        ...payload,
      }
    },
    deleteFamily: (state, { payload }) => {
      state.family_members = state.family_members.filter(
        (family) => family.family_id !== payload.family_id
      )
    },

    addEducation: (state, { payload }) => {
      state.education.push(payload)
    },
    updateEducation: (state, { payload }) => {
      const index = state.education.findIndex(
        (edu) => edu.education_id === payload.education_id
      )
      state.education[index] = { ...state.education[index], ...payload }
    },
    deleteEducation: (state, { payload }) => {
      state.education = state.education.filter(
        (edu) => edu.education_id !== payload.education_id
      )
    },

    addCandidacy: (state, { payload }) => {
      state.candidacies.push(payload)
    },
    updateCandidacy: (state, { payload }) => {
      const index = state.candidacies.findIndex(
        (candidacy) => candidacy.candidacy_id === payload.candidacy_id
      )
      state.candidacies[index] = { ...state.candidacies[index], ...payload }
    },
    deleteCandidacy: (state, { payload }) => {
      state.candidacies = state.candidacies.filter(
        (candidacy) => candidacy.candidacy_id !== payload.candidacy_id
      )
    },

    addElection: (state, { payload }) => {
      state.elections.push(payload)
    },
    updateElection: (state, { payload }) => {
      const index = state.elections.findIndex(
        (election) => election.election_id === payload.election_id
      )
      state.elections[index] = { ...state.elections[index], ...payload }
    },
    deleteElection: (state, { payload }) => {
      state.elections = state.elections.filter(
        (election) => election.election_id !== payload.election_id
      )
    },

    addGoverning: (state, { payload }) => {
      state.governings.push(payload)
    },
    updateGoverning: (state, { payload }) => {
      const index = state.governings.findIndex(
        (governing) => governing.governing_id === payload.governing_id
      )
      state.governings[index] = { ...state.governings[index], ...payload }
    },
    deleteGoverning: (state, { payload }) => {
      state.governings = state.governings.filter(
        (governing) => governing.governing_id !== payload.governing_id
      )
    },

    addOrganization: (state, { payload }) => {
      state.organizations.push(payload)
    },
    updateOrganization: (state, { payload }) => {
      const index = state.organizations.findIndex(
        (organization) =>
          organization.organization_id === payload.organization_id
      )
      state.organizations[index] = {
        ...state.organizations[index],
        ...payload,
      }
    },
    deleteOrganization: (state, { payload }) => {
      state.organizations = state.organizations.filter(
        (organization) =>
          organization.organization_id !== payload.organization_id
      )
    },

    addCommittee: (state, { payload }) => {
      state.committees.push(payload)
    },
    updateCommittee: (state, { payload }) => {
      const index = state.committees.findIndex(
        (committee) =>
          committee.campaign_finance_id === payload.campaign_finance_id
      )
      state.committees[index] = {
        ...state.committees[index],
        ...payload,
      }
    },
    deleteCommittee: (state, { payload }) => {
      state.organizations = state.committees.filter(
        (committee) =>
          committee.campaign_finance_id !== payload.campaign_finance_id
      )
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTargetDetails.pending, (state, action) => {
        state.fetchStatus = 'loading'
      })
      .addCase(fetchTargetDetails.fulfilled, (state, action) => {
        return Object.assign({}, initialState, action.payload, {
          fetchStatus: 'succeeded',
        })
      })
      .addCase(fetchTargetDetails.rejected, (state, action) => {
        state.fetchStatus = 'failed'
        state.error = action.error.message
      })

      .addCase(fetchTargetDetailsBackground.fulfilled, (state, action) => {
        return Object.assign({}, initialState, action.payload, {
          fetchStatus: 'succeeded',
        })
      })
  },
})

export const selectTarget = createSelector(
  (state) => ({
    target_id: state.target.target_id,
    target_bio: state.target.target_bio,
    target_dob: state.target.target_dob,
    target_full_name: state.target.target_full_name,
    target_first_name: state.target.target_first_name,
    target_gender: state.target.target_gender,
    target_last_name: state.target.target_last_name,
    target_middle_name: state.target.target_middle_name,
    target_nick_name: state.target.target_nick_name,
    target_title: state.target.target_title,
    target_suffix: state.target.target_suffix,
    target_preferred_first: state.target.target_preferred_first,
    target_preferred_last: state.target.target_preferred_last,
    target_photo: state.target.target_photo,
    target_party: state.target.target_party,
    target_religion: state.target.target_religion,
    state: state.target.state,
    created: state.target.created,
    updated: state.target.updated,
    birth_address: state.target.birth_address,
    home_address: state.target.home_address,
  }),
  (targetAttributes) => targetAttributes
)

export const {
  updateTarget,

  addAddress,
  updateAddress,
  deleteAddress,

  addLink,
  updateLink,
  deleteLink,

  addFamily,
  updateFamily,
  deleteFamily,

  addCandidacy,
  updateCandidacy,
  deleteCandidacy,

  addEducation,
  updateEducation,
  deleteEducation,

  addElection,
  updateElection,
  deleteElection,

  addGoverning,
  updateGoverning,
  deleteGoverning,

  addOrganization,
  updateOrganization,
  deleteOrganization,

  addCommittee,
  updateCommittee,
  deleteCommittee,
} = targetSlice.actions
export default targetSlice.reducer
