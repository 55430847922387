import { Grid, Typography, TextField } from '@material-ui/core'
import { DatePicker } from './DatePicker'

export const SocialMediaForm = ({
  formState,
  setFormState,
  formErrors,
  classes,
  handleChange,
}) => (
  <Grid container item>
    <Typography variant='h5' style={{ marginTop: '1rem' }}>
      Citation
    </Typography>
    <Grid
      justifyContent='space-between'
      container
      className={classes.marginTop8}
      item
      xs={12}
    >
      <Grid item xs={4}>
        <TextField
          className={classes.marginTop8}
          value={formState.network_name}
          label='Network Name'
          required
          error={formErrors.network_name}
          onChange={(e) => handleChange('network_name', e)}
        />
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          id='cite-date'
          label='Cite Date'
          currentDate={formState.bullet_cite_date}
          handleChange={handleChange}
          fieldName='bullet_cite_date'
        />
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          id='access-date'
          label='Access Date'
          currentDate={formState.bullet_access_date}
          handleChange={handleChange}
          fieldName='bullet_access_date'
        />
      </Grid>
      <TextField
        className={classes.marginTop8}
        value={formState.network_user}
        fullWidth
        label='Network User'
        onChange={(e) =>
          setFormState({
            ...formState,
            network_user: e.target.value,
          })
        }
      />
    </Grid>
    <TextField
      value={formState.bullet_cite_url}
      className={classes.marginTop8}
      fullWidth
      label='Attribution URL'
      onChange={(e) =>
        setFormState({
          ...formState,
          bullet_cite_url: e.target.value,
        })
      }
    />
  </Grid>
)
