import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Edit, AddCircle } from '@material-ui/icons'
import { Typography, Button } from '@material-ui/core'

import { api } from '../../../api/api'
import { FormWithTracking } from './FormWithTracking'
import {
  WActions,
  WContent,
  WTitle,
  WDescription,
  WList,
  WListItem,
  WListItemActions,
} from '../../wizard'
import { ELECTION_FIELDS } from '../../../utils/forms'
import { addElection, updateElection } from '../../slices/targetSlice'
import { ELECTION_TYPE_CHOICES } from '../../../utils/choice_enums'

const ElectionLine = ({ election }) => (
  <>
    {election.election_office}, {election.election_district},&nbsp;
    {ELECTION_TYPE_CHOICES[election.election_type]}, {election.election_year}
  </>
)

export const WElectionsTab = ({ onSubmitSuccess, onExit }) => {
  let dispatch = useDispatch()

  const elections = useSelector((state) => state.target.elections)
  const [election, setElection] = useState()

  const handleAdd = () => {
    setElection({})
  }

  const handleSubmit = async (body) => {
    if (election.election_id) {
      const response = await api.updateElection(election.election_id, body)
      dispatch(updateElection(response.data))
    } else {
      const response = await api.createElection({
        ...body,
      })
      dispatch(addElection(response.data))
    }

    setElection(null)
  }

  return (
    <>
      <WTitle>
        Fill out target's previous and current elections, successful or
        otherwise.
      </WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>TKTKTK</WDescription>
      <WContent style={{ paddingBottom: '2rem' }}>
        <WList style={{ width: '80%' }}>
          <WListItem onClick={handleAdd}>
            Add Another
            <WListItemActions>
              <AddCircle />
            </WListItemActions>
          </WListItem>
          {elections.map((election) => (
            <WListItem
              key={election.election_id}
              onClick={() => setElection(election)}
            >
              <ElectionLine election={election} />
              <WListItemActions>
                <Edit />
              </WListItemActions>
            </WListItem>
          ))}
        </WList>
      </WContent>

      {election && (
        <>
          <Typography variant='h5' style={{ paddingBottom: '1rem' }}>
            {election.election_id
              ? `Editing ${election.election_office}, ${election.election_district}`
              : 'Add New Election'}
          </Typography>
          <FormWithTracking
            objectId={election.election_id}
            relationType='many'
            initialAttributes={election}
            fields={ELECTION_FIELDS}
            onSubmit={handleSubmit}
          />
        </>
      )}

      <WActions>
        <Button variant='contained' onClick={onSubmitSuccess}>
          CONTINUE
        </Button>
        <Button variant='contained' onClick={onExit}>
          EXIT
        </Button>
      </WActions>
    </>
  )
}
