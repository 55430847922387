import axios from 'axios'
import { errorHandler } from './errorHandler'
import { isEmpty } from 'lodash'

export const bullets = {
  getBullets: async ({
    startDate,
    endDate,
    fullTargets,
    selectedFolders,
    selectedTargets,
    keyword,
    excludeWord,
    themes,
    data_type,
    bullet_type,
    record_status,
    currentOffset,
  }) => {
    try {
      let url = `/bullets/research?limit=50&offset=${currentOffset * 50}`
      url += !isEmpty(startDate) ? `&smart_date__gte=${startDate}` : ''
      url += !isEmpty(endDate) ? `&smart_date__lte=${endDate}` : ''
      url += !isEmpty(keyword) ? `&icontains_many=${keyword}` : ''
      url += !isEmpty(excludeWord) ? `&iexcludes_many=${excludeWord}` : ''
      url += !isEmpty(themes) ? `&bullet_theme_list=${themes.join()}` : ''
      url += !isEmpty(bullet_type)
        ? `&bullet_type_list=${bullet_type
            .join()
            .toUpperCase()
            .replaceAll(' ', '_')}`
        : ''
      url += !isEmpty(data_type)
        ? `&content_type_list=${data_type
            .join()
            .toUpperCase()
            .replaceAll(' ', '_')}`
        : ''
      url += isEmpty(selectedTargets)
        ? `&target_universal_list=${fullTargets.map(
            (target) => target.target_id
          )}`
        : `&target_universal_list=${selectedTargets.map((target) => target.id)}`
      url += !isEmpty(selectedFolders)
        ? `&folder_id_list=${selectedFolders.map((folder) => folder.id)}`
        : ''
      url += !isEmpty(record_status)
        ? `&record_status_list=${record_status
            .join()
            .toUpperCase()
            .replaceAll(' ', '_')}`
        : ''

      const res = await axios.get(url)
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
  getThemes: async () => {
    try {
      return await axios.get(`/bullets/themes/profile`)
    } catch (err) {
      errorHandler(err)
    }
  },
  createBullet: async (body) => {
    try {
      let formData = new FormData()
      formData.append('bullet_file', body.bullet_file)
      for (let key in body) {
        if (body[key] !== null && !isEmpty(body[key]))
          formData.append(key, body[key])
      }
      const res = await axios.post(`/bullets/create`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
  updateBullet: async (body) => {
    try {
      let formData = new FormData()
      for (let key in body) {
        if (body[key] !== null && !isEmpty(body[key]))
          formData.append(key, body[key])
      }
      const res = await axios.patch(
        `/bullets/update/${body.bullet_id}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
  updateBulletBody: async ({ body, id }) => {
    try {
      const res = await axios.put(`/bullets/bulletcontent/update/${id}`, {
        ...body,
      })
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
  createBrief: async (body) => {
    try {
      const res = await axios.post(`/briefs/create`, { ...body })
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
  updateBrief: async ({ body, id }) => {
    try {
      const res = await axios.patch(`/briefs/update/${id}`, { ...body })
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
  updateBriefContents: async (body) => {
    try {
      const res = await axios.put(`/briefs/briefbullets/bulkupdate`, body)
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
  deleteItems: async (body) => {
    try {
      return await axios.post('/bullets/research/bulkdelete', { ...body })
    } catch (err) {
      errorHandler(err)
    }
  },
  trimClip: async (id, body) => {
    try {
      const res = await axios.put(`functionality/trim_video/${id}`, { ...body })
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
}
