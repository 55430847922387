import { pick } from 'lodash'

import { WTitle, WDescription } from '../../wizard'
import { api } from '../../../api/api'
import { TARGET_FIELDS } from '../../../utils/forms'
import { FormWithTracking } from './FormWithTracking'

export const WPhotoTab = ({ target, onSubmitSuccess, onExit }) => {
  const PHOTO_FIELDS = pick(TARGET_FIELDS, ['target_photo'])

  const handleSubmit = async (body) => {
    await api.patchTargetPhoto(target.target_id, body)
  }

  return (
    <>
      <WTitle>Profile pic time!</WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>
        If there is an official government photo of the target, choose that.
        Otherwise, pick a photo that would look nice on a fridge!
      </WDescription>
      <FormWithTracking
        objectId={target.target_id}
        initialAttributes={target}
        fields={PHOTO_FIELDS}
        onSubmit={handleSubmit}
        onSubmitSuccess={onSubmitSuccess}
        onExit={onExit}
      />
    </>
  )
}
