import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Checkbox,
  makeStyles,
  Grid,
  Collapse,
  Chip,
} from '@material-ui/core'

import { addSelectedBullet, removeSelectedBullet } from '../slices/bulletSlice'

import { Attachment } from './Attachments'
import { Citations } from './Citations'
import { SubHeader } from './SubHeader'
import { Actions } from './Actions'
import { Links } from './Links'

export const Bullet = ({ bullet, inBrief = false, inFolder = false }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectedBulletsList = useSelector(
    (state) => state.bullet.selected_bullets
  )
  const targetList = useSelector((state) => state.user.targets)
  const localTarget = useSelector((state) => state.bullet.localTarget)
  const isClient = useSelector((state) => state.user.isClient)
  const [expanded, setExpanded] = useState(false)
  const [currentTarget, setCurrentTarget] = useState('')

  const {
    bullet_header,
    body,
    bullet_id,
    bullet_url_2,
    bullet_file,
    bullet_theme,
    content_type,
    bullet_type,
    bullet_target,
  } = bullet

  useEffect(() => {
    if (!isEmpty(bullet.bullet_id)) {
      const thisTarget = targetList.filter((item) => {
        return item.target_id === bullet_target
      })
      setCurrentTarget(thisTarget[0].target_full_name)
    } else {
      setCurrentTarget(localTarget)
    }
  }, [bullet])

  const check = (selectedBulletsList) => {
    const ids = selectedBulletsList
    return ids.includes(bullet)
  }
  const handleChange = (event) => {
    if (event.target.checked) {
      dispatch(addSelectedBullet(bullet))
    } else {
      dispatch(removeSelectedBullet(bullet.bullet_id))
    }
  }

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <StyledContainer>
      {!inBrief && (
        <CheckContainer>
          <Checkbox
            color='primary'
            checked={check(selectedBulletsList)}
            onChange={handleChange}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          />
        </CheckContainer>
      )}
      {!inFolder && (
        <BulletCard>
          <CardHeader
            title={!isEmpty(bullet_header) && bullet_header}
            action={
              <Actions
                bullet={bullet}
                isClient={isClient}
                toggleExpand={toggleExpand}
              />
            }
            subheader={
              <SubHeader
                bullet={bullet}
                isClient={isClient}
                currentTarget={currentTarget}
              />
            }
            style={{ padding: '16px 16px 0 16px' }}
            titleTypographyProps={{ variant: 'h6' }}
          />
          <CardContent style={{ padding: '16px 16px 0 16px' }}>
            <Row>
              <Citations bullet={bullet} isModal={false} />
              {!inFolder && <Links bullet={bullet} />}
            </Row>
          </CardContent>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            {(content_type === 'VIDEO' || !isEmpty(bullet_file)) && (
              <CardContent>
                <Attachment
                  file={
                    content_type === 'VIDEO' && bullet_type !== 'GENERIC'
                      ? bullet_url_2
                      : bullet_file
                  }
                  isClient={isClient}
                  bullet_id={bullet_id}
                  isClip={content_type === 'VIDEO' && bullet_type !== 'GENERIC'}
                />
              </CardContent>
            )}
            <CardContent>
              {body && (
                <BodySection
                  dangerouslySetInnerHTML={{ __html: body }}
                ></BodySection>
              )}
            </CardContent>
            {!isEmpty(bullet_theme) && (
              <CardContent>
                <ChipRow>
                  {bullet_theme.map((tag) => (
                    <Chip key={tag} label={tag} style={{ margin: '.25rem' }} />
                  ))}
                </ChipRow>
              </CardContent>
            )}
          </Collapse>
        </BulletCard>
      )}
      {inFolder && (
        <Card className={classes.brief}>
          <Typography>{bullet_header}</Typography>
        </Card>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const BulletCard = styled(Card)`
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
const BodySection = styled(Grid)`
  padding: 20px 10px;
  max-height: 150px;
  overflow: scroll;
  margin: 10px 0px;
  background-color: #e0e0e0;
  border-radius: 5px;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
const ChipRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const useStyles = makeStyles({
  bold: {
    fontWeight: 600,
  },
  svg: {
    height: '15px !important',
    width: '15px !important',
  },
  more: {
    width: '20px !important',
  },
})
