import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {
  addItemChangeStatus,
  changeTargetID,
  changeBulletToEdit,
  addItemToDelete,
  changeBulletToCopy,
} from '../slices/bulletSlice'

import {
  setRightState,
  setRightOpen,
  setModalOpen,
  setModalState,
} from '../slices/navSlice'
import { IconButtons } from '../buttons/Buttons'

export const Actions = ({ bullet, isClient, toggleExpand }) => {
  const { bullet_header, record_status, bullet_type } = bullet

  const citation_record = {
    bullet_cite_source: bullet.bullet_cite_source,
    bullet_cite_type: bullet.bullet_cite_type,
    record_source: bullet.record_source,
    record_type: bullet.record_type,
    record_number: bullet.record_number,
    network_name: bullet.network_name,
    network_user: bullet.network_user,
    amendment_number: bullet.amendment_number,
    bill_number: bullet.bill_number,
    bullet_type: bullet.bullet_type,
    bullet_cite_author: bullet.bullet_cite_author,
    leg_vote_number: bullet.leg_vote_number,
    leg_action: bullet.leg_action,
    bullet_cite_date: bullet.bullet_cite_date,
    bullet_access_date: bullet.bullet_access_date,
    bullet_cite_url: bullet.bullet_cite_url,
  }

  const rightOpen = useSelector((state) => state.nav.rightOpen)
  const dispatch = useDispatch()
  const handleEdit = () => {
    if (bullet.bullet_target)
      dispatch(changeTargetID(bullet.bullet_target.target_id))
    dispatch(changeBulletToEdit(bullet))
    dispatch(setRightOpen(true))
    dispatch(setRightState('editBullet'))
  }

  const handleDelete = () => {
    dispatch(
      addItemToDelete({
        id: bullet.bullet_id,
        type: 'bullet',
        header: bullet_header,
        record_status,
        citation_record,
      })
    )
    dispatch(setModalState('delete'))
    dispatch(setModalOpen(true))
  }

  const handleChangeStatus = () => {
    dispatch(
      addItemChangeStatus({
        id: bullet.bullet_id,
        type: 'bullet',
        header: bullet.bullet_header,
        record_status,
        citation_record,
      })
    )
    dispatch(setModalState('changeStatus'))
    dispatch(setModalOpen(true))
  }

  const handleCopy = () => {
    dispatch(changeBulletToCopy(bullet))
    dispatch(setRightOpen(true))
    dispatch(setRightState('copyBullet'))
  }
  return (
    <IconContainer>
      {!rightOpen && !isClient && (
        <>
          <IconButtons
            iconType="edit"
            toolTipValue="Edit Bullet"
            handleChange={handleEdit}
          />
          <IconButtons
            iconType="status"
            toolTipValue="Change Bullet Status"
            handleChange={handleChangeStatus}
          />
          <IconButtons
            iconType="delete"
            toolTipValue="Delete Bullet"
            handleChange={handleDelete}
          />
          {bullet_type === 'CLIP' && (
            <IconButtons
              iconType="copy"
              toolTipValue="Copy Bullet"
              handleChange={handleCopy}
            />
          )}
        </>
      )}
      <IconButtons
        iconType="expand"
        toolTipValue="Read More"
        handleChange={toggleExpand}
      />
    </IconContainer>
  )
}

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0.25rem;
`
