import { Grid, Typography, TextField, InputLabel } from '@material-ui/core'
import { Selects } from '../selects/SelectPicker'
import { DatePicker } from './DatePicker'

export const VoteForm = ({
  formState,
  setFormState,
  formErrors,
  classes,
  handleChange,
}) => (
  <>
    <Grid container item>
      <Typography variant='h5' style={{ marginTop: '1rem' }}>
        Bill
      </Typography>
      <Grid justifyContent='space-between' container item xs={12}>
        <Grid item xs={5}>
          <TextField
            value={formState.leg_chamber}
            className={classes.marginTop8}
            fullWidth
            label='Chamber of Action'
            required
            error={formErrors.leg_chamber}
            onChange={(e) => handleChange('leg_chamber', e)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            value={formState.bill_number}
            className={classes.marginTop8}
            fullWidth
            label='Bill Number'
            required
            error={formErrors.bill_number}
            onChange={(e) => handleChange('bill_number', e)}
          />
        </Grid>
        <Grid className={classes.marginTop8} item xs={5}>
          <InputLabel shrink className={classes.marginTop8}>
            Bill Action
          </InputLabel>
          <Selects
            formState={formState}
            setFormState={setFormState}
            selectType={'leg_action'}
            selectName={'Bill Action'}
          />
        </Grid>
        {formState.leg_action === 'SPONSORSHIP' && (
          <Grid className={classes.marginTop8} item xs={5}>
            <InputLabel shrink className={classes.marginTop8}>
              Sponsor Type
            </InputLabel>
            <Selects
              formState={formState}
              setFormState={setFormState}
              selectType={'sponsor_type'}
              selectName={'Sponsor type'}
            />
          </Grid>
        )}
        {['VOTE', 'vote'].includes(formState.leg_action) && (
          <Grid className={classes.marginTop8} item xs={5}>
            <TextField
              value={formState.leg_vote_number}
              className={classes.marginTop8}
              type='number'
              label='Vote Number'
              onChange={(e) =>
                setFormState({
                  ...formState,
                  leg_vote_number: e.target.value,
                })
              }
            />
          </Grid>
        )}
        {['VOTE', 'vote'].includes(formState.leg_action) && (
          <>
            <Grid className={classes.marginTop8} item xs={5}>
              <TextField
                value={formState.leg_vote_question}
                className={classes.marginTop8}
                fullWidth
                label='Vote Question'
                error={formErrors.leg_vote_question}
                onChange={(e) => handleChange('leg_vote_question', e)}
              />
            </Grid>
            <Grid className={classes.marginTop8} item xs={5}>
              <InputLabel shrink className={classes.marginTop8}>
                Vote Direction
              </InputLabel>
              <Selects
                formState={formState}
                setFormState={setFormState}
                selectType={'leg_vote_direction'}
                selectName={'Vote Direction'}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
    <Grid container item>
      <Typography variant='h5' style={{ marginTop: '1rem' }}>
        Citation
      </Typography>
      <Grid
        justifyContent='space-between'
        container
        className={classes.marginTop8}
        item
        xs={12}
      >
        <Grid item xs={12}>
          <DatePicker
            id='cite-date'
            label='Cite Date'
            currentDate={formState.bullet_cite_date}
            handleChange={handleChange}
            fieldName='bullet_cite_date'
          />
        </Grid>
      </Grid>
      <TextField
        value={formState.bullet_cite_url}
        className={classes.marginTop8}
        fullWidth
        label='Attribution URL'
        onChange={(e) =>
          setFormState({
            ...formState,
            bullet_cite_url: e.target.value,
          })
        }
      />
    </Grid>
  </>
)
