import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  leftOpen: false,
  rightOpen: false,
  rightState: null,
  modalOpen: false,
  modalState: null,
}

const navSlice = createSlice({
  name: 'nav',
  initialState: initialState,
  reducers: {
    setLeftOpen: (state, { payload }) => {
      state.leftOpen = payload
    },
    setRightOpen: (state, { payload }) => {
      state.rightOpen = payload
    },
    setRightState: (state, { payload }) => {
      state.rightState = payload
    },
    setModalOpen: (state, { payload }) => {
      state.modalOpen = payload
    },
    setModalState: (state, { payload }) => {
      state.modalState = payload
    },
  },
})

export const {
  setRightOpen,
  setRightState,
  setLeftOpen,
  setModalOpen,
  setModalState,
} = navSlice.actions
export default navSlice.reducer
