export const ACCOUNT_TYPE_CHOICES = {
  PERSONAL: 'Personal',
  CAMPAIGN: 'Campaign',
  GOVERNMENT: 'Government',
  BUSINESS: 'Business',
}

export const ACCOUNT_SOURCE_CHOICES = {
  BALLOTPEDIA: 'Ballotpedia',
  BLOG: 'Blog',
  CRP_OPENSECRETS: 'CRP (OpenSecrets)',
  CSPAN: 'CSpan',
  FACEBOOK_ID: 'Facebook (ID)',
  FACEBOOK_USERNAME: 'Facebook (Username)',
  GOOGLE_ENTITY: 'Google Entity',
  GOVTRACK: 'GovTrack',
  INSTAGRAM: 'Instagram',
  LEGISCAN: 'LegiScan',
  OPENFEC: 'OpenFEC',
  OPEN_STATES: 'Open States',
  OTHER_SOCIAL: 'Other Social',
  OTHER: 'Other',
  PROPUBLICA: 'ProPublica',
  REDDIT: 'Reddit',
  TIK_TOK: 'Tik Tok',
  TWITTER: 'Twitter',
  VOTESMART: 'VoteSmart',
  WEBSITE: 'Website',
  YOUTUBE: 'YouTube',
}

export const GENDER_CHOICES = {
  FEMALE: 'Female',
  MALE: 'Male',
  NON_IDENTIFYING: 'Non-Identifying',
  OTHER: 'Other',
}

export const PARTY_CHOICES = {
  DEMOCRAT: 'Democrat',
  REPUBLICAN: 'Republican',
  INDEPENDENT: 'Independent',
  GREEN: 'Green',
  OTHER: 'Other',
}

export const RELIGION_CHOICES = {
  BAPTIST: 'Baptist',
  BUDDHISM: 'Buddhism',
  CATHOLIC: 'Catholic',
  CHRISTIANITY: 'Christianity',
  EASTERN_ORTHODOX: 'Eastern Orthodox',
  HINDUISM: 'Hinduism',
  ISLAM: 'Islam',
  JUDAISM: 'Judaism',
  LUTHERANISM: 'Lutheranism',
  METHODIST: 'Methodist',
  PRESBYTERIAN: 'Presbyterian',
  PROTESTANTISM: 'Protestantism',
  SHIA: 'Shia',
  SIKHISM: 'Sikhism',
  SUNNI: 'Sunni',
  QUAKER: 'Quaker',
  OTHER: 'Other',
}

export const FAMILY_TYPE_CHOICES = {
  PARTNER_UNMARRIED: 'Partner (Unmarried)',
  PARTNER_MARRIED: 'Partner (Married)',
  PARENT: 'Parent',
  CHILD: 'Child',
  SIBLING: 'Sibling',
  GRANDPARENT: 'Grandparent',
  GRANDCHILD: 'Grandchild',
  OTHER: 'Other',
}

export const OWNERSHIP_CHOICES = {
  RENT: 'Rent',
  OWN: 'Own',
  UNKNOWN: 'Unknown',
}

export const ADDRESS_TYPE_CHOICES = {
  PRIMARY: 'Primary',
  VACATION: 'Vacation',
  INVESTMENT_RENTAL: 'Investment (Rental)',
  OTHER: 'Other',
}

export const ADDRESS_STATUS_CHOICES = {
  CURRENT: 'Current',
  PAST: 'Past',
  BIRTHPLACE: 'Birthplace',
  UNKNOWN: 'Unknown',
}

export const EDUCATION_LEVEL_CHOICES = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  COLLEGE: 'College',
  GRADUATE: 'Graduate',
  PROFESSIONAL: 'Professional',
}

export const DEGREE_CHOICES = {
  HIGH_SCHOOL_DIPLOMA: 'High School Diploma',
  ASSOCIATES: "Associate's",
  BACHELORS: "Bachelor's",
  MASTERS: "Master's",
  DOCTORATE: 'Doctorate',
  JD: 'JD',
  MD: 'MD',
  DDS: 'DDS',
  OTHER: 'Other',
}

export const ELECTION_TYPE_CHOICES = {
  PRIMARY: 'Primary',
  GENERAL: 'General',
  RUNOFF: 'Runoff',
  OTHER: 'Other',
}

export const CANDIDATE_TYPE_CHOICES = {
  INCUMBENT: 'Incumbent',
  CHALLENGER: 'Challenger',
}

export const COMMITTEE_TYPE_CHOICES = {
  PRIMARY: 'Primary',
  JOINT: 'Joint',
  INDEPENDENT_EXPENDITURE: 'Independent Expenditure',
  OTHER: 'Other',
}

export const ORGANIZATION_TYPE_CHOICES = {
  COMMITTEE: 'Committee',
  CAUCUS: 'Caucus',
  NONPROFIT: 'Nonprofit',
  COMPANY: 'Company',
  OTHER: 'Other',
}

export const ORGANIZATION_ASSOCIATION_CHOICES = {
  MEMBERSHIP: 'Membership',
  EMPLOYMENT: 'Employment',
  OWNERSHIP: 'Ownership',
  INVESTMENT: 'Investment',
  PARTNERSHIP: 'Partnership',
}

export const SECTION_CHOICES = {
  INTRO: 'Intro',
  LINKS: 'Links',
  FIRST_LAST: 'First & Last',
  NAMES: 'Names',
  BIOGRAPHICAL: 'Biographical',
  PHOTO: 'Photo',
  FAMILY: 'Family',
  ADDRESSES: 'Addresses',
  EDUCATION: 'Education',
  CANDIDACY: 'Candidacy',
  ELECTIONS: 'Elections',
  ELECTED_OFFICES: 'Elected Offices',
  CAMPAIGN_FINANCE: 'Campaign Finance',
  ORGANIZATIONS: 'Organizations',
  UNKNOWN: 'Unknown',
}

export const STATE_CHOICES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  AA: 'Armed Forces Americas',
  AE: 'Armed Forces Europe',
  AP: 'Armed Forces Pacific',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}
