import styled from 'styled-components'

export const FormErrors = ({ errors }) => {
  if (errors.length === 0) return null

  console.debug('FormErrors: ', errors)

  return (
    <StyledUList>
      {errors.map((error, index) => (
        <li key={index}>
          {error.verbose_name} - {error.message}
        </li>
      ))}
    </StyledUList>
  )
}

const StyledUList = styled.ul`
  margin: 0 auto 30px;
  padding-left: 20px;
  color: red;
  text-align: left;
`
