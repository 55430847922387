import { configureStore } from '@reduxjs/toolkit'
import bulletReducer from './components/slices/bulletSlice'
import { setupListeners } from '@reduxjs/toolkit/query'
import userReducer from './components/slices/usersSlice'
import navReducer from './components/slices/navSlice'
import themeReducer from './components/slices/themeSlice'
import targetReducer from './components/slices/targetSlice'
import wizardReducer from './components/slices/wizardSlice'
import explorerSlice from './components/slices/explorerSlice'

export const store = configureStore({
  reducer: {
    explorer: explorerSlice,
    bullet: bulletReducer,
    user: userReducer,
    nav: navReducer,
    theme: themeReducer,
    target: targetReducer,
    wizard: wizardReducer,
  },
})
export default store

setupListeners(store.dispatch)
