import { MenuItem } from '@material-ui/core'

export const MenuItems = (props) => {
  const { setOpen, formState, setFormState, menuTypes, formType } = props

  return (
    <>
      {menuTypes.map((menu_type, index) => (
        <MenuItem
          key={index}
          style={{ textTransform: 'Capitalize' }}
          onClick={(e) => {
            setFormState({
              ...formState,
              [formType]: menu_type.const,
            })
            setOpen(false)
          }}
        >
          {menu_type.text}
        </MenuItem>
      ))}
    </>
  )
}
