import { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { RemoveCircleOutline } from '@material-ui/icons'
import styled from 'styled-components'
import { Citations } from '../bullets/Citations'

export const DragDropper = ({ setItems, dragItems }) => {
  const [drags, setDrags] = useState(dragItems)
  const dragEnd = (result) => {
    const neworder = [...drags]
    const [removed] = neworder.splice(result.source.index, 1)
    neworder.splice(result.destination.index, 0, removed)
    setDrags(neworder)
    setItems(neworder)
  }

  const removeBullet = (id) => {
    setItems(drags.filter((item) => item.bullet_id !== id))
    setDrags(drags.filter((item) => item.bullet_id !== id))
  }

  return (
    <StyledDragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <List {...provided.droppableProps} ref={provided.innerRef}>
            {drags.length > 0
              ? drags.map((item, index) => (
                  <Draggable
                    key={item.bullet_id}
                    draggableId={item.bullet_id}
                    index={index}
                  >
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ContainerComponent='li'
                        ContainerProps={{ ref: provided.innerRef }}
                      >
                        <Tooltip title={'Remove Bullet'}>
                          <IconButton
                            onClick={() => removeBullet(item.bullet_id)}
                          >
                            <RemoveCircleOutline />
                          </IconButton>
                        </Tooltip>
                        <Col new={item.new ? true : false}>
                          <Typography variant='h6'>
                            {item.bullet_header}
                          </Typography>

                          <StyledListItemTextCenter
                            primary={<Citations bullet={item} isModal={true} />}
                          />
                        </Col>
                      </ListItem>
                    )}
                  </Draggable>
                ))
              : null}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </StyledDragDropContext>
  )
}
const StyledDragDropContext = styled(DragDropContext)`
  min-height: 600px !important;
`
const Col = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: ${(props) => (props.new ? '#dce8f0' : '#d3d3d3')};
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`
const StyledListItemTextCenter = styled(ListItemText)`
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.54);
`
