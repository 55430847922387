export const MenuTypes = {
  bullet_type: [
    {
      const: 'GENERIC',
      text: 'Generic',
    },
    {
      const: 'VOTE',
      text: 'Vote',
    },
    {
      const: 'PUBLIC_RECORD',
      text: 'Public Record',
    },
    {
      const: 'SOCIAL_MEDIA',
      text: 'Social Media',
    },
    {
      const: 'CLIP',
      text: 'Clip',
    },
  ],
  sponsor_type: [
    {
      const: 'PRIMARY',
      text: 'Primary',
    },
    {
      const: 'CO_SPONSOR',
      text: 'Co-Sponsor',
    },
  ],
  leg_vote_direction: [
    {
      const: 'YEA',
      text: 'Yea',
    },
    {
      const: 'NAY',
      text: 'Nay',
    },
    {
      const: 'PRESENT',
      text: 'Present',
    },
    {
      const: 'DID_NOT_VOTE',
      text: 'Did Not Vote',
    },
  ],
  leg_vote_question: [
    {
      const: 'PASSAGE',
      text: 'Passage',
    },
    {
      const: 'TO_AMEND',
      text: 'To Amend',
    },
    {
      const: 'TABLE',
      text: 'Table',
    },
    {
      const: 'MOTION_TO_RECOMMIT',
      text: 'Motion to Recommit',
    },
  ],
  leg_action: [
    {
      const: 'VOTE',
      text: 'Vote',
    },
    {
      const: 'SPONSORSHIP',
      text: 'Sponsorship',
    },
  ],
  bullet_cite_type: [
    {
      const: 'BLOG',
      text: 'Blog',
    },
    {
      const: 'COLUMN',
      text: 'Column',
    },
    {
      const: 'EDITORIAL',
      text: 'Editorial',
    },
    {
      const: 'LETTER_TO_THE_EDITOR',
      text: 'Letter to the Editor',
    },
    {
      const: 'OP_ED',
      text: 'Op-ed',
    },
    {
      const: 'PRESS_RELEASE',
      text: 'Press Release',
    },
    {
      const: 'REPORT',
      text: 'Report',
    },
    {
      const: null,
      text: '',
    },
  ],
}

export const ContentMenuTypes = (bullet_type) => [
  {
    const: 'AUDIO',
    text: 'Audio',
  },
  {
    const: 'DOCUMENT',
    text: 'Document',
  },
  {
    const: 'FILE',
    text: 'File',
  },
  {
    const: 'IMAGE',
    text: 'Image',
  },
  {
    const: 'PRESENTATION',
    text: 'Presentation',
  },
  {
    const: 'TABLE',
    text: 'Table',
  },
  {
    const: 'TEXTONLY',
    text: 'Text Only',
  },
  {
    const: 'VIDEO',
    text: 'Video',
  },
]
