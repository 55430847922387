import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

export const TagsPieChart = (props) => {
  const { tally } = props

  const defaultOptions = {
    plugins: {
      legend: { display: true, position: 'left' },
    },
  }

  const chartData = {
    labels: Object.keys(tally),
    datasets: [
      {
        data: Object.values(tally),
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(153, 102, 255, 0.7)',
          'rgba(255, 159, 64, 0.7)',
        ],
        borderWidth: 1,
      },
    ],
  }

  return (
    <>
      <Pie data={chartData} options={defaultOptions} />
    </>
  )
}
