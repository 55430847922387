import { Grid, Typography, TextField } from '@material-ui/core'
import { DatePicker } from './DatePicker'
export const PublicRecordForm = ({
  formState,
  setFormState,
  formErrors,
  classes,
  handleChange,
}) => (
  <>
    <Grid container item>
      <Typography variant="h5" style={{ marginTop: '1rem' }}>
        Records
      </Typography>
      <Grid
        justifyContent="space-between"
        container
        className={classes.marginTop8}
        item
        xs={12}
      >
        <Grid item xs={5}>
          <TextField
            value={formState.record_source}
            className={classes.marginTop8}
            fullWidth
            label="Record Source"
            required
            error={formErrors.record_source}
            onChange={(e) => handleChange('record_source', e)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            value={formState.record_type}
            className={classes.marginTop8}
            fullWidth
            label="Record Type"
            required
            error={formErrors.record_type}
            onChange={(e) => handleChange('record_type', e)}
          />
        </Grid>
        <TextField
          value={formState.record_number}
          className={classes.marginTop8}
          fullWidth
          label="Record Number"
          onChange={(e) =>
            setFormState({
              ...formState,
              record_number: e.target.value,
            })
          }
        />
      </Grid>
    </Grid>
    <Grid container item>
      <Typography variant="h5" style={{ marginTop: '1rem' }}>
        Citation
      </Typography>
      <Grid
        justifyContent="space-between"
        container
        className={classes.marginTop8}
        item
        xs={12}
      >
        <Grid item xs={5}>
          <DatePicker
            id="cite-date"
            label="Cite Date"
            currentDate={formState.bullet_cite_date}
            handleChange={handleChange}
            fieldName="bullet_cite_date"
          />
        </Grid>
        <Grid item xs={5}>
          <DatePicker
            id="access-date"
            label="Access Date"
            currentDate={formState.bullet_access_date}
            handleChange={handleChange}
            fieldName="bullet_access_date"
          />
        </Grid>
      </Grid>
      <TextField
        value={formState.bullet_cite_url}
        className={classes.marginTop8}
        fullWidth
        label="Attribution URL"
        onChange={(e) =>
          setFormState({
            ...formState,
            bullet_cite_url: e.target.value,
          })
        }
      />
    </Grid>
  </>
)
