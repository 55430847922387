import axios from 'axios'
import { errorHandler } from './errorHandler'

export const wizards = {
  createWizard: async (body) => {
    try {
      return await axios.post(`/targets/wizards/create`, body)
    } catch (err) {
      errorHandler(err)
    }
  },
  updateWizard: async (id, body) => {
    try {
      return await axios.patch(`/targets/wizard/update/${id}`, {
        ...body,
      })
    } catch (err) {
      errorHandler(err)
    }
  },
  getWizardSectionByTarget: async (targetId) => {
    try {
      return await axios.get(`/targets/wizard/target/${targetId}`)
    } catch (err) {
      errorHandler(err)
    }
  },
  updateWizardSectionByTarget: async (targetId, body) => {
    try {
      return await axios.put(`/targets/wizard/user/${targetId}`, {
        ...body,
      })
    } catch (err) {
      errorHandler(err)
    }
  },
}
