import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Box, Typography } from '@material-ui/core'

import { api } from '../../api/api'
import { MomentDate } from '../util/MomentDate'
import { GENDER_CHOICES, RELIGION_CHOICES } from '../../utils/choice_enums'
import { EditableTextarea } from './EditableTextarea'
import { TargetPresenter } from '../../presenters/target_presenter'
import { updateTarget } from '../slices/targetSlice'
import { NewLineText } from '../util/NewLineText'

export const TargetBiography = (props) => {
  let dispatch = useDispatch()
  const target = new TargetPresenter(props.target)
  const hadx = target.home_address[0]
  const badx = target.birth_address[0]

  const onBiographySave = async (text) => {
    const response = await api.updateTarget({
      id: target.target_id,
      body: {
        target_first_name: target.target_first_name,
        target_last_name: target.target_last_name,
        target_bio: text,
      },
    })

    dispatch(updateTarget(response.data))
  }

  return (
    <Box sx={{ p: 3 }}>
      <section>
        <Typography variant='h6'>Biography</Typography>
        <EditableTextarea
          defaultValue={target.target_bio}
          onSave={onBiographySave}
        />
      </section>

      <StyledSection>
        <Typography variant='h6'>Names</Typography>
        <Box sx={{ backgroundColor: '#EEE' }}>
          <ul style={{ padding: '1rem 2rem', margin: 0 }}>
            <li>Legal Name: {target.legalName}</li>
            {target.target_nick_name && (
              <li>Nickname: {target.target_nick_name}</li>
            )}
            {target.preferredName && (
              <li>Preferred Name: {target.preferredName}</li>
            )}
          </ul>
        </Box>
      </StyledSection>

      <StyledSection>
        <Typography variant='h6'>Biographical Details</Typography>
        <Box sx={{ p: '1rem', backgroundColor: '#EEE' }}>
          Date of Birth:&nbsp;
          <MomentDate type='dob' date={target.target_dob} />
          <br />
          Gender: {GENDER_CHOICES[target.target_gender]}
          <br />
          Religion: {RELIGION_CHOICES[target.target_religion]}
        </Box>
      </StyledSection>

      <StyledSection>
        <Typography variant='h6'>Home and Birth Place</Typography>
        <Box sx={{ p: '1rem', backgroundColor: '#EEE' }}>
          <StyledPlaceWrapper>
            <div>Current Residence:</div>
            {hadx && <NewLineText text={hadx.address} />}
          </StyledPlaceWrapper>
          <br />
          <StyledPlaceWrapper>
            <div>Birth Place:</div>
            {badx && <NewLineText text={badx.address} />}
          </StyledPlaceWrapper>
        </Box>
      </StyledSection>
    </Box>
  )
}

const StyledSection = styled.section`
  margin-top: 20px;
`

const StyledPlaceWrapper = styled.div`
  display: flex;
  justify-content: left;

  > div:not(:first-child) {
    margin-left: 1rem;
  }
`
