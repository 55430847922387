import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { changeStatus } from '../slices/bulletSlice'
import { Citations } from '../bullets/Citations'

export const ChangeStatus = () => {
  const dispatch = useDispatch()
  const itemsChangeStatus = useSelector(
    (state) => state.bullet.itemsChangeStatus
  )
  const selectedStatus = useSelector((state) => state.bullet.status_to_set)

  const updateStatus = (val) => {
    dispatch(changeStatus(val))
  }

  return (
    <>
      <FormControl fullWidth style={{ marginBottom: '2rem' }}>
        <InputLabel id='status-options'>Select Status</InputLabel>
        <Select
          labelId='status-options'
          value={selectedStatus}
          label='Statuses'
          onChange={(e) => updateStatus(e.target.value)}
        >
          <MenuItem value={'EDITING'}>Editing</MenuItem>
          <MenuItem value={'PUBLISHED'}>Published</MenuItem>
          <MenuItem value={'PENDING_REVIEW'}>Pending Review</MenuItem>
        </Select>
      </FormControl>
      <Typography>
        {itemsChangeStatus.length > 0 && (
          <Row>
            <Left>Header</Left>
            <Center>
              <CiteRow>
                <Citation>Source</Citation>
              </CiteRow>
            </Center>
            <Right>Status</Right>
          </Row>
        )}
      </Typography>
      <Typography>
        {itemsChangeStatus.map((entry) => (
          <Typography>
            {entry.type === 'bullet' ? (
              <Row key={entry.id}>
                <Left>{entry.header}</Left>
                <Center>
                  <CiteRow>
                    <Citation>
                      <Citations
                        bullet={entry.citation_record}
                        isModal={true}
                      />
                    </Citation>
                  </CiteRow>
                </Center>
                <Right>
                  {entry.record_status.charAt(0).toUpperCase() +
                    entry.record_status.slice(1).toLowerCase()}
                </Right>
              </Row>
            ) : (
              <Row key={entry.id}>
                <BriefLeft>{entry.header}</BriefLeft>
                <Right>
                  {entry.record_status.charAt(0).toUpperCase() +
                    entry.record_status.slice(1).toLowerCase()}
                </Right>
              </Row>
            )}
          </Typography>
        ))}
      </Typography>
    </>
  )
}

const Row = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid lightgrey 1px;
  margin-bottom: 1rem;
`
const CiteRow = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Citation = styled.span`
  width: 100%;
  text-align: left;
`
const Left = styled.span`
  width: 55%;
`
const Center = styled.span`
  width: 35%;
`
const BriefLeft = styled.span`
  width: 90%;
`
const Right = styled.span`
  align-self: flex-start;
`
