import { useDispatch } from 'react-redux'
import { setRightState, setRightOpen } from '../slices/navSlice'

import { IconButton } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import CloseIcon from '@mui/icons-material/Close'

export const ErrorNote = () => {
  const dispatch = useDispatch()
  return (
    <Alert severity="error">
      <AlertTitle>
        Something went wrong please try again adding text to try deploying
      </AlertTitle>
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={() => {
          dispatch(setRightOpen(false))
          dispatch(setRightState(null))
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Alert>
  )
}
