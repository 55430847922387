import { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  Typography,
  makeStyles,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Button,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { addSelectedFolder } from '../slices/bulletSlice'
import { api } from '../../api/api'

const FolderPicker = () => {
  const [data, setData] = useState()

  const getData = async () => {
    try {
      const targets = await api.getTargets()
      const folders = await api.getFolders({
        targets: targets.data,
      })
      setData(folders.data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const dispatch = useDispatch()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleMenuItemClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    dispatch(
      addSelectedFolder({ name: event.target.innerText, id: event.target.id })
    )
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      <Button
        className={classes.targetSelect}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <Typography>Folder</Typography>
        <KeyboardArrowDownIcon />
      </Button>
      <Popper
        className={classes.highIndex}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                >
                  {data &&
                    data.map((folder) => {
                      return (
                        <MenuItem
                          className={classes.item}
                          onClick={handleMenuItemClose}
                          id={folder.folder_id}
                          key={folder.folder_id}
                        >
                          {folder.folder_name}
                        </MenuItem>
                      )
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default FolderPicker

const useStyles = makeStyles((theme) => ({
  targetSelect: {
    justifyContent: 'space-between',
    width: '100%',
    textTransform: 'none',
    textAlign: 'left',
    marginLeft: '-0.75rem',
  },
  highIndex: {
    zIndex: 9999,
  },
  paper: {
    left: '5px',
    marginLeft: '1rem !important',
    width: 'auto',
    maxHeight: '400px',
    overflowX: 'scroll',
  },
  item: {
    overflowWrap: 'normal',
    fontSize: '.75rem',
  },
}))
