import store from '../store'

import { userSlice } from '../components/slices/usersSlice'

export const errorHandler = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 422: // UnprocessableEntity (ValidationError)
        throw err.response.data
      case 401: // Unauthorized
        store.dispatch(userSlice.actions.setUser(null))
        if (!window.location.href.includes('/login')) {
          window.location.assign(
            process.env.REACT_APP_BASE_FRONTEND_URL + '/login'
          )
        }
        throw err
      default: // FIXME: 5XX?
        throw err
    }
  } else {
    throw err
  }
}
