import styled from 'styled-components'
import { useState, Fragment } from 'react'
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Typography,
} from '@material-ui/core'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export const TargetElections = (props) => {
  const { elections } = props
  const [isExpanded, setExpanded] = useState({})

  const toggleExpandElection = (election) => {
    if (election.committees.length === 0) {
      return
    }

    setExpanded((prevState) => {
      return {
        ...prevState,
        [election.election_id]: !prevState[election.election_id],
      }
    })
  }

  const renderDrawerIcon = (expanded) => {
    if (expanded) {
      return <KeyboardArrowDownIcon fontSize='small' />
    } else {
      return <KeyboardArrowRightIcon fontSize='small' />
    }
  }

  return (
    <Box sx={{ p: '1rem' }}>
      <TableContainer component={Box} sx={{ backgroundColor: '#EEE' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <StyledRowHead>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Office</TableCell>
              <TableCell>District</TableCell>
              <TableCell>Open?</TableCell>
              <TableCell>Candidate Type</TableCell>
              <TableCell>Winner?</TableCell>
            </StyledRowHead>
          </TableHead>
          <TableBody>
            {elections.map((election, index) => (
              <Fragment key={election.election_id}>
                <StyledRow
                  className={index % 2 === 0 ? 'even' : 'odd'}
                  onClick={() => toggleExpandElection(election)}
                >
                  <StyledCell>
                    {election.committees.length
                      ? renderDrawerIcon(isExpanded[election.election_id])
                      : ' '}
                  </StyledCell>
                  <StyledCell>{election.election_year}</StyledCell>
                  <StyledCell>{election.election_type}</StyledCell>
                  <StyledCell>{election.election_office}</StyledCell>
                  <StyledCell>{election.election_district}</StyledCell>
                  <StyledCell>
                    {election.election_open ? 'Yes' : 'No'}
                  </StyledCell>
                  <StyledCell>{election.target_candidate_type}</StyledCell>
                  <StyledCell>{election.election_winner && 'Yes'}</StyledCell>
                </StyledRow>
                {isExpanded[election.election_id] && (
                  <StyledRow
                    nested={'yes'}
                    className={index % 2 === 0 ? 'even' : 'odd'}
                  >
                    <StyledExtraCell colSpan={8}>
                      <Typography variant='h6'>
                        Associated Committees
                      </Typography>
                      <ul>
                        {election.committees.map((committee) => (
                          <li key={committee.committee_id}>
                            {committee.committee_name},{' '}
                            {committee.committee_type} ({committee.committee_id}
                            )
                          </li>
                        ))}
                      </ul>
                    </StyledExtraCell>
                  </StyledRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const StyledRowHead = styled(TableRow)`
  background: #d2d2d2;
  border-bottom: 2px solid darkgray;

  th {
    font-weight: bold;
  }
`

const StyledRow = styled(TableRow)`
  border-top: ${(props) => (props.nested ? 'none' : '1px solid lightgray')};
  background: #eee;

  &.even {
    background: #e3e3e3;
  }

  &:hover,
  &:focus {
    ${(props) => !props.nested && 'background: #d4d4d4;'};
  }
`

const StyledCell = styled(TableCell)`
  border-bottom: none;
`

const StyledExtraCell = styled(TableCell)`
  padding: 1rem 2rem 2rem;

  ul {
    margin: 0;
    padding-left: 1rem;
  }
`
