import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { flatten, uniqBy } from 'lodash'
import {
  Edit,
  AddCircle,
  CheckBoxOutlined,
  CheckBoxOutlineBlank,
} from '@material-ui/icons'
import { Typography, Button } from '@material-ui/core'

import { api } from '../../../api/api'
import { FormWithTracking } from './FormWithTracking'
import {
  WActions,
  WContent,
  WTitle,
  WDescription,
  WList,
  WListItem,
  WListItemActions,
} from '../../wizard'
import {
  COMMITTEE_TYPE_CHOICES,
  ELECTION_TYPE_CHOICES,
} from '../../../utils/choice_enums'
import { CAMPAIGN_FINANCE_FIELDS } from '../../../utils/forms'
import { updateElection } from '../../slices/targetSlice'
import { addCommittee, updateCommittee } from '../../slices/targetSlice'

const ElectionLine = ({ election }) => (
  <>
    {election.election_office}, {election.election_district},&nbsp;
    {ELECTION_TYPE_CHOICES[election.election_type]}, {election.election_year}
  </>
)

const CampaignFinanceLine = ({ campaignFinance }) => (
  <>
    {campaignFinance.committee_name},{' '}
    {COMMITTEE_TYPE_CHOICES[campaignFinance.committee_type]}
  </>
)

export const WCampaignFinancesTab = ({ onSubmitSuccess, onExit }) => {
  let dispatch = useDispatch()

  const elections = useSelector((state) => state.target.elections)
  const campaignFinances = useSelector((state) => state.target.committees)

  const [campaignFinance, setCampaignFinance] = useState()
  const [selectedCycles, setSelectedCycles] = useState({})

  useEffect(() => {
    if (!campaignFinance) return

    setSelectedCycles(
      elections.reduce((mapping, election) => {
        mapping[election.election_id] = election.committees.some(
          (committee) =>
            committee.campaign_finance_id ===
            campaignFinance.campaign_finance_id
        )

        return mapping
      }, {})
    )
  }, [campaignFinance, elections])

  const toggleCycle = (election_id) => {
    setSelectedCycles({
      ...selectedCycles,
      [election_id]: !selectedCycles[election_id],
    })
  }

  const handleAdd = () => {
    setCampaignFinance({})
  }

  const campaignFinancesFor = (election_id) =>
    elections
      .find((e) => e.election_id === election_id)
      .committees.map((c) => c.campaign_finance_id)

  const handleSubmit = async (body) => {
    if (campaignFinance.campaign_finance_id) {
      await handleUpdate(body)
    } else {
      await handleCreate(body)
    }

    setCampaignFinance(null)
  }

  const handleUpdate = async (body) => {
    const updateResponse = await api.updateCampaignFinance(
      campaignFinance.campaign_finance_id,
      body
    )
    dispatch(updateCommittee(updateResponse.data))

    Object.keys(selectedCycles).forEach(async (election_id) => {
      const campaign_finance_ids = campaignFinancesFor(election_id)

      // Add it if it wasn't there previously
      if (
        selectedCycles[election_id] &&
        campaign_finance_ids.indexOf(campaignFinance.campaign_finance_id) < 0
      ) {
        const response = await api.updateElection(election_id, {
          committees: campaign_finance_ids.concat(
            campaignFinance.campaign_finance_id
          ),
        })

        dispatch(updateElection(response.data))
      }

      // Remove it if it was there previously
      if (
        !selectedCycles[election_id] &&
        campaign_finance_ids.indexOf(campaignFinance.campaign_finance_id) > -1
      ) {
        const response = await api.updateElection(election_id, {
          committees: campaign_finance_ids.filter(
            (id) => id !== campaignFinance.campaign_finance_id
          ),
        })

        dispatch(updateElection(response.data))
      }
    })
  }

  const handleCreate = async (body) => {
    const createResponse = await api.createCampaignFinance({
      ...body,
    })
    dispatch(addCommittee(createResponse.data))
    const campaignFinanceId = createResponse.data.campaign_finance_id

    Object.keys(selectedCycles).forEach(async (election_id) => {
      const campaign_finance_ids = campaignFinancesFor(election_id)

      // Add selected cycles
      if (selectedCycles[election_id]) {
        const updateResponse = await api.updateElection(election_id, {
          committees: campaign_finance_ids.concat(campaignFinanceId),
        })
        dispatch(updateElection(updateResponse.data))
      }
    })
  }

  return (
    <>
      <WTitle>Campaign Finance</WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>
        Detail the campaign committees for the target and assign them to
        election cycles. If you need to add additional cycles, return to the
        ELECTIONS tab. NOTE: If candidate has run for many offices (e.g., both
        upper and lower chambers) they may have multiple candidate IDs.
      </WDescription>
      <WContent style={{ paddingBottom: '2rem' }}>
        <WList style={{ width: '80%' }}>
          <WListItem onClick={handleAdd}>
            Add Another
            <WListItemActions>
              <AddCircle />
            </WListItemActions>
          </WListItem>
          {campaignFinances.map((campaignFinance) => (
            <WListItem
              key={campaignFinance.campaign_finance_id}
              onClick={() => setCampaignFinance(campaignFinance)}
            >
              <CampaignFinanceLine campaignFinance={campaignFinance} />
              <WListItemActions>
                <Edit />
              </WListItemActions>
            </WListItem>
          ))}
        </WList>
      </WContent>

      {campaignFinance && (
        <>
          <Typography variant='h5' style={{ paddingBottom: '1rem' }}>
            {campaignFinance.campaign_finance_id ? (
              <>
                Editing{' '}
                <CampaignFinanceLine campaignFinance={campaignFinance} />
              </>
            ) : (
              'Add New Campaign Finance'
            )}
          </Typography>
          <FormWithTracking
            objectId={campaignFinance.campaign_finance_id}
            relationType='many'
            initialAttributes={campaignFinance}
            fields={CAMPAIGN_FINANCE_FIELDS}
            onSubmit={handleSubmit}
          >
            {campaignFinance.campaign_finance_id && (
              <WList style={{ width: '80%', marginTop: 60 }}>
                {elections.map((election) => (
                  <WListItem
                    key={election.election_id}
                    onClick={() => toggleCycle(election.election_id)}
                  >
                    <ElectionLine election={election} />
                    <WListItemActions>
                      {selectedCycles[election.election_id] ? (
                        <CheckBoxOutlined></CheckBoxOutlined>
                      ) : (
                        <CheckBoxOutlineBlank></CheckBoxOutlineBlank>
                      )}
                    </WListItemActions>
                  </WListItem>
                ))}
              </WList>
            )}
          </FormWithTracking>
        </>
      )}

      {/* CycleSelect */}

      <WActions>
        <Button variant='contained' onClick={onSubmitSuccess}>
          CONTINUE
        </Button>
        <Button variant='contained' onClick={onExit}>
          EXIT
        </Button>
      </WActions>
    </>
  )
}
