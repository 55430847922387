import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Edit, AddCircle } from '@material-ui/icons'
import { Typography, Button } from '@material-ui/core'

import { api } from '../../../api/api'
import { FormWithTracking } from './FormWithTracking'
import {
  WActions,
  WContent,
  WTitle,
  WDescription,
  WList,
  WListItem,
  WListItemActions,
} from '../../wizard'
import { FAMILY_FIELDS } from '../../../utils/forms'
import { FAMILY_TYPE_CHOICES } from '../../../utils/choice_enums'
import { addFamily, updateFamily } from '../../slices/targetSlice'

const FamilyLine = ({ family }) => (
  <>
    {family.family_first_name}
    &nbsp;
    {family.family_last_name} ({FAMILY_TYPE_CHOICES[family.family_type]})
  </>
)

export const WFamilyTab = ({ onSubmitSuccess, onExit }) => {
  let dispatch = useDispatch()
  const { targetId } = useParams()

  const familyMembers = useSelector((state) => state.target.family_members)
  const [member, setMember] = useState()

  const handleAdd = () => {
    setMember({})
  }

  const handleSubmit = async (body) => {
    if (member.family_id) {
      const response = await api.updateFamilyMember(member.family_id, body)
      dispatch(updateFamily(response.data))
    } else {
      const response = await api.createFamilyMember({
        ...body,
        target_id: targetId,
      })
      dispatch(addFamily(response.data))
    }

    setMember(null)
  }

  return (
    <>
      <WTitle>Let's add family members.</WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>
        If the target's party, religion, or other dropdown is not available,
        choose OTHER and the administrator will get in touch to add the correct
        option.
      </WDescription>
      <WContent style={{ paddingBottom: '2rem' }}>
        <WList style={{ width: '80%' }}>
          <WListItem onClick={handleAdd}>
            Add Another
            <WListItemActions>
              <AddCircle />
            </WListItemActions>
          </WListItem>
          {familyMembers.map((member) => (
            <WListItem key={member.family_id} onClick={() => setMember(member)}>
              <FamilyLine family={member} />
              <WListItemActions>
                <Edit />
              </WListItemActions>
            </WListItem>
          ))}
        </WList>
      </WContent>

      {member && (
        <>
          <Typography variant='h5' style={{ paddingBottom: '1rem' }}>
            {member.family_id
              ? `Editing ${member.family_full_name}`
              : 'Add New Family Member'}
          </Typography>
          <FormWithTracking
            objectId={member.family_id}
            relationType='many'
            initialAttributes={member}
            fields={FAMILY_FIELDS}
            onSubmit={handleSubmit}
          />
        </>
      )}

      <WActions>
        <Button variant='contained' onClick={onSubmitSuccess}>
          CONTINUE
        </Button>
        <Button variant='contained' onClick={onExit}>
          EXIT
        </Button>
      </WActions>
    </>
  )
}
