import { compact } from 'lodash'
import { ObjectPresenter } from './object_presenter'

export class TargetPresenter extends ObjectPresenter {
  get legalName() {
    return compact([
      this.object.target_first_name,
      this.object.target_middle_name,
      this.object.target_last_name,
      this.object.target_suffix,
    ]).join(' ')
  }

  get preferredName() {
    return compact([
      this.object.target_preferred_first,
      this.object.target_preferred_last,
    ]).join(' ')
  }
}
