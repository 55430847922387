import { useState } from 'react'
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

export const DatePicker = ({
  id,
  label,
  currentDate,
  fieldName,
  handleChange,
  fullWidth = false,
  margin = 'normal',
}) => {
  const [selectedDate, setSelectedDate] = useState(currentDate)
  const handleDateChange = (date) => {
    setSelectedDate(date)
    handleChange(fieldName, {
      target: { value: date !== null ? date.format('YYYY-MM-DD') : null },
    })
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        fullWidth={fullWidth}
        disableToolbar
        variant='inline'
        format='MM/DD/YYYY'
        margin={margin}
        id={id}
        label={label}
        value={selectedDate}
        onChange={(date) => handleDateChange(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  )
}
