import styled from 'styled-components'
import { BallTriangle } from 'react-loader-spinner'

export const LoadingBalls = ({ leftOpen }) => (
  <StyledLoading open={leftOpen}>
    <BallTriangle
      height='200'
      width='200'
      color='grey'
      arialLabel='loading-indicator'
    />
  </StyledLoading>
)

const StyledLoading = styled.div`
  display: flex;
  width: ${(props) => (props.open ? 'calc(100% - 250)' : '100%')};
  height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.open ? '240px' : 'auto')};
`
