/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Grid,
  Button,
  Typography,
  TextField,
} from '@material-ui/core'
import styled from 'styled-components'

import TargetPicker from '../util/TargetPicker'
import {
  changeBulletToEdit,
  changeLocalTargetID,
  changeLocalTarget,
  triggerRefreshList,
} from '../slices/bulletSlice'
import { setRightOpen, setRightState } from '../slices/navSlice'

import { api } from '../../api/api'
import { Bullet } from '../bullets/Bullet'
import { Selects } from './selects/SelectPicker'

import { BulletBuilderStyles } from './builderStyles'
import { sanitizeBulletForm } from './formStatesSanitizer'
import { VoteForm } from './forms/vote'
import { GenericForm } from './forms/generic'
import { PublicRecordForm } from './forms/publicRecord'
import { SocialMediaForm } from './forms/socialMedia'
import { Tags } from '../Tags/Tags'
import { StyledDropzone } from './Dropzone'

export const BulletBuilder = () => {
  const dispatch = useDispatch()
  const currentTarget = useSelector((state) => state.bullet.localTargetID)
  const bulletToEdit = useSelector((state) => state.bullet.bulletToEdit)
  const bulletToCopy = useSelector((state) => state.bullet.bullet_to_copy)
  const user = useSelector((state) => state.user.user)
  const rightState = useSelector((state) => state.nav.rightState)
  const userThemes = useSelector((state) => state.theme.userThemes)

  useEffect(() => {
    if (currentTarget) setIsReady(true)
  }, [currentTarget])

  const [newBullet, setNewBullet] = useState(null)
  const [isReady, setIsReady] = useState(false)
  const [formState, setFormState] = useState(
    bulletToEdit === null && bulletToCopy === null
      ? {
          bullet_target: currentTarget,
          bullet_header: null,
          bullet_theme: [],
          body: null,
          bullet_cite_source: null,
          bullet_cite_author: null,
          bullet_cite_date: null,
          bullet_cite_url: null,
          bullet_status: 'EDITING',
          content_type: 'TEXTONLY',
          bullet_type: 'GENERIC',
          bill_number: null,
          leg_chamber: null,
          leg_description: null,
          leg_vote_direction: null,
          leg_sponsor: null,
          bullet_cite_type: null,
          record_source: null,
          record_type: null,
          record_number: null,
          leg_action: 'VOTE',
          leg_title: null,
          leg_sponsorship_type: null,
          leg_vote_question: null,
          leg_vote_number: null,
          bullet_file: null,
          network_name: null,
          network_user: null,
          bullet_access_date: null,
          user: user,
        }
      : bulletToEdit === null && bulletToCopy !== null
      ? {
          bullet_header: bulletToCopy.bullet_header,
          bullet_theme: bulletToCopy.theme,
          body: bulletToCopy.body,
          bullet_cite_source: bulletToCopy.bullet_cite_source,
          bullet_cite_author: bulletToCopy.bullet_cite_author,
          bullet_cite_date: bulletToCopy.bullet_cite_date,
          bullet_cite_url: bulletToCopy.bullet_cite_url,
          bullet_status: 'EDITING',
          content_type: bulletToCopy.content_type,
          bullet_type: bulletToCopy.bullet_type,
          bill_number: bulletToCopy.bill_number,
          leg_chamber: bulletToCopy.leg_chamber,
          leg_description: bulletToCopy.leg_description,
          leg_vote_direction: bulletToCopy.leg_vote_direction,
          leg_sponsor: bulletToCopy.leg_sponsor,
          bullet_target: bulletToCopy.bullet_target,
          bullet_cite_type: bulletToCopy.bullet_cite_type,
          record_source: bulletToCopy.record_source,
          record_type: bulletToCopy.record_type,
          record_number: bulletToCopy.record_number,
          leg_action: bulletToCopy.leg_action,
          leg_sponsorship_type: bulletToCopy.leg_sponsorship_type,
          leg_vote_question: bulletToCopy.leg_vote_question,
          leg_vote_number: bulletToCopy.leg_vote_number,
          bullet_file: bulletToCopy.bullet_file,
          network_name: bulletToCopy.network_name,
          network_user: bulletToCopy.network_user,
          bullet_access_date: bulletToCopy.bullet_access_date,
          user: user,
        }
      : { ...bulletToEdit }
  )
  useEffect(() => {
    if (
      currentTarget === null &&
      bulletToEdit === null &&
      bulletToCopy === null
    )
      setIsReady(false)
    else setIsReady(true)
    if (bulletToCopy) dispatch(changeLocalTargetID(bulletToCopy.bullet_target))
    if (bulletToEdit) dispatch(changeLocalTargetID(bulletToEdit.bullet_target))
  }, [])

  const [formErrors, setFormErrors] = useState({
    bullet_header: false,
    bullet_theme: false,
    bullet_cite_source: false,
    bullet_cite_date: false,
    content_type: false,
    bullet_target: false,
    bullet_file: false,

    bill_number: false,
    leg_chamber: false,
    leg_vote_direction: false,

    record_source: false,
    record_type: false,

    leg_title: false,
    leg_sponsorship_type: false,
    leg_vote_question: false,

    network_name: false,
  })

  const validate = (fields) => {
    let validationPass = true
    let errorCopy = { ...formErrors }
    fields.forEach((field) => {
      if (formState[field] === null || formState[field] === '') {
        errorCopy[field] = true
        validationPass = false
      }
    })
    setFormErrors(errorCopy)

    return validationPass
  }

  const handleTags = (field, tags) => {
    setFormState({
      ...formState,
      [field]: tags,
      bullet_target: currentTarget,
    })
  }

  const handleChange = (field, event) => {
    setFormState({
      ...formState,
      [field]: event.target.value,
      bullet_target: currentTarget,
    })
    setFormErrors({
      ...formErrors,
      [field]: false,
    })
  }

  const handleFiles = (files) => {
    setFormState((formState) => ({
      ...formState,
      bullet_file: files[0],
      bullet_target: currentTarget,
    }))
  }

  const disbrief = () => {
    setFormState({
      ...formState,
      bullet_header: '',
      bullet_theme: [],
      body: null,
      bullet_cite_source: '',
      bullet_cite_author: '',
      bullet_cite_date: null,
      bullet_cite_url: '',
      bill_number: '',
      leg_chamber: '',
      leg_description: '',
      leg_vote_direction: null,
      leg_sponsor: null,
      bullet_target: currentTarget,
      bullet_cite_type: null,
      record_source: '',
      record_type: '',
      record_number: '',
      leg_action: 'VOTE',
      leg_title: '',
      leg_sponsorship_type: null,
      leg_vote_question: null,
      leg_vote_number: '',
      bullet_file: null,
      network_name: '',
      network_user: '',
    })
    dispatch(changeBulletToEdit(null))
    dispatch(setRightState(null))
    dispatch(changeLocalTarget(null))
    dispatch(changeLocalTargetID(null))
  }

  const cancel = () => {
    disbrief()
    dispatch(changeBulletToEdit(null))
    dispatch(setRightState(null))
    dispatch(setRightOpen(false))
  }

  const handleSubmit = async (e) => {
    try {
      const cleanedForm = await sanitizeBulletForm(formState, validate)
      cleanedForm.bullet_target = currentTarget
      const createdBullet = await api.createBullet(cleanedForm)

      const formSubmit = {
        ...cleanedForm,
        bullet_id: createdBullet.bullet_id,
      }
      setNewBullet(formSubmit)
      dispatch(setRightState(null))
      dispatch(setRightOpen(false))
      dispatch(triggerRefreshList())
      disbrief()
    } catch (err) {
      throw err
    }
  }

  const handleEdit = async () => {
    try {
      const formSubmit = {
        ...formState,
        bullet_id: bulletToEdit.bullet_id,
        bullet_target: currentTarget,
      }
      if (formSubmit.bullet_cite_date === null) {
        formSubmit.bullet_cite_date = 'null'
      }
      if (formSubmit.bullet_access_date === null) {
        formSubmit.bullet_access_date = 'null'
      }
      const res = await api.updateBullet(formSubmit)
      dispatch(setRightState(null))
      dispatch(setRightOpen(false))
      dispatch(triggerRefreshList())
      disbrief()
    } catch (err) {
      throw err
    }
  }

  const classes = BulletBuilderStyles()

  return (
    <BuilderContainer>
      <ScrollContainer>
        <Row>
          <Column>
            <Row>
              <Typography className={classes.header} variant='h5'>
                {bulletToEdit === null && bulletToCopy === null
                  ? 'Create New Bullet'
                  : bulletToEdit === null && bulletToCopy !== null
                  ? 'Copy Bullet'
                  : 'Edit Bullet'}
              </Typography>
            </Row>
            <Row>
              <TargetPicker
                isGlobal={false}
                formErrors={formErrors}
                errorField='bullet_target'
                setFormErrors={setFormErrors}
                formError={formErrors.bullet_target}
              />
              <Selects
                setFormState={setFormState}
                formState={formState}
                selectType={'bullet_type'}
                selectName={'Type'}
              />
            </Row>
          </Column>
        </Row>

        {isReady && (
          <Grid container>
            <Grid container direction='column'>
              <Grid item>
                <Typography variant='h5' style={{ marginTop: '1rem' }}>
                  Header
                </Typography>
                <TextField
                  fullWidth
                  required
                  multiline
                  label='Required'
                  error={formErrors.bullet_header}
                  value={formState.bullet_header}
                  className={classes.marginTop8}
                  onChange={(e) => handleChange('bullet_header', e)}
                />
              </Grid>
              <Grid item>
                <Typography variant='h5' style={{ marginTop: '1rem' }}>
                  Body
                </Typography>
                <div className={classes.marginTop8}>
                  <Selects
                    formState={formState}
                    setFormState={setFormState}
                    selectType={'content_type'}
                    selectName={'Content Type'}
                  />
                </div>
                <TextField
                  multiline
                  fullWidth
                  required
                  label='Required'
                  className={classes.marginTop8}
                  value={formState.body}
                  onChange={(e) => handleChange('body', e)}
                />
              </Grid>
              {(formState.bullet_type === 'GENERIC' ||
                formState.bullet_type === 'CLIP') && (
                <GenericForm
                  formState={formState}
                  setFormState={setFormState}
                  formErrors={formErrors}
                  classes={classes}
                  handleChange={handleChange}
                />
              )}
              {formState.bullet_type === 'VOTE' && (
                <VoteForm
                  formState={formState}
                  setFormState={setFormState}
                  formErrors={formErrors}
                  classes={classes}
                  handleChange={handleChange}
                />
              )}
              {formState.bullet_type === 'PUBLIC_RECORD' && (
                <PublicRecordForm
                  formState={formState}
                  setFormState={setFormState}
                  formErrors={formErrors}
                  classes={classes}
                  handleChange={handleChange}
                />
              )}
              {formState.bullet_type === 'SOCIAL_MEDIA' && (
                <SocialMediaForm
                  formState={formState}
                  setFormState={setFormState}
                  formErrors={formErrors}
                  classes={classes}
                  handleChange={handleChange}
                />
              )}
            </Grid>
            <Grid
              className={classes.marginTop8}
              style={{ width: '100%', paddingTop: '1rem' }}
            >
              <StyledDropzone
                handleChange={handleFiles}
                currentFile={
                  bulletToEdit !== null && bulletToEdit.bullet_file !== null
                    ? bulletToEdit.bullet_file.replace(
                        'https://s3.us-east-1.amazonaws.com/tappan-media-1/file_uploads/',
                        ''
                      )
                    : null
                }
              />
            </Grid>
            <Grid className={classes.marginTop8} style={{ width: '100%' }}>
              <Tags
                existingTags={userThemes}
                handleChange={handleTags}
                currentTags={
                  bulletToEdit !== null
                    ? bulletToEdit.bullet_theme
                    : bulletToCopy !== null
                    ? bulletToCopy.bullet_theme
                    : []
                }
              />
            </Grid>
            <Grid container item style={{ marginTop: '1rem' }}>
              <Typography variant='h5' style={{ marginTop: '1rem' }}>
                Bullet Preview
              </Typography>
              <Bullet inBrief bullet={{ ...formState }} />
            </Grid>
          </Grid>
        )}
      </ScrollContainer>
      <ActionsContainer>
        <Grid className={classes.marginTop24} container>
          <ButtonRow>
            {bulletToEdit !== null ? (
              <Button variant='contained' color='primary' onClick={handleEdit}>
                Save Changes
              </Button>
            ) : (
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
              >
                Save Bullet
              </Button>
            )}
            {rightState === 'editBullet' && (
              <Button onClick={disbrief}>Clear Form</Button>
            )}
            <Button onClick={cancel}>Cancel</Button>
          </ButtonRow>
        </Grid>
      </ActionsContainer>
    </BuilderContainer>
  )
}
const BuilderContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 100%;
`
const ScrollContainer = styled.div`
  max-height: 85vh;
  overflow-y: scroll;
`
const ActionsContainer = styled.div`
  display: flex;
  align-self: end;
  width: 100%;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
`
const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
