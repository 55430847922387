import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Edit, AddCircle } from '@material-ui/icons'
import { Typography, Button } from '@material-ui/core'

import { api } from '../../../api/api'
import { FormWithTracking } from './FormWithTracking'
import {
  WActions,
  WContent,
  WTitle,
  WDescription,
  WList,
  WListItem,
  WListItemActions,
} from '../../wizard'
import { CANDIDACY_FIELDS } from '../../../utils/forms'
import { addCandidacy, updateCandidacy } from '../../slices/targetSlice'

const CandidacyLine = ({ candidacy }) => <>{candidacy.office}</>

export const WCandidaciesTab = ({ onSubmitSuccess, onExit }) => {
  let dispatch = useDispatch()
  const { targetId } = useParams()

  const candidacies = useSelector((state) => state.target.candidacies)
  const [candidacy, setCandidacy] = useState()
  console.log(candidacy)

  const handleAdd = () => {
    setCandidacy({})
  }

  const handleSubmit = async (body) => {
    if (candidacy.candidacy_id) {
      const response = await api.updateCandidacy(candidacy.candidacy_id, body)
      dispatch(updateCandidacy(response.data))
    } else {
      const response = await api.createCandidacy({
        ...body,
        target_id: targetId,
      })
      dispatch(addCandidacy(response.data))
    }

    setCandidacy(null)
  }

  return (
    <>
      <WTitle>Fill out the levels of office the target has sought</WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>
        Add as many offices as the target has run for. For instance, if they've
        run for county school board, state senate, U.S. House, and U.S. Senate,
        they should have four candidacies.
      </WDescription>
      <WContent style={{ paddingBottom: '2rem' }}>
        <WList style={{ width: '80%' }}>
          <WListItem onClick={handleAdd}>
            Add Another
            <WListItemActions>
              <AddCircle />
            </WListItemActions>
          </WListItem>
          {candidacies.map((candidacy) => (
            <WListItem
              key={candidacy.candidacy_id}
              onClick={() => setCandidacy(candidacy)}
            >
              <CandidacyLine candidacy={candidacy} />
              <WListItemActions>
                <Edit />
              </WListItemActions>
            </WListItem>
          ))}
        </WList>
      </WContent>

      {candidacy && (
        <>
          <Typography variant='h5' style={{ paddingBottom: '1rem' }}>
            {candidacy.candidacy_id
              ? `Editing ${candidacy.office}`
              : 'Add New Candidacy'}
          </Typography>
          {/* TODO: do this for all the other tabs!!! */}
          <FormWithTracking
            objectId={candidacy.candidacy_id}
            relationType='many'
            initialAttributes={candidacy}
            fields={CANDIDACY_FIELDS}
            onSubmit={handleSubmit}
          />
        </>
      )}

      <WActions>
        <Button variant='contained' onClick={onSubmitSuccess}>
          CONTINUE
        </Button>
        <Button variant='contained' onClick={onExit}>
          EXIT
        </Button>
      </WActions>
    </>
  )
}
