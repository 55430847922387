import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
}

const explorerSlice = createSlice({
  name: 'explorer',
  initialState: initialState,
  reducers: {
    setList: (state, { payload }) => {
      state.list = payload
    },
    updateList: (state, { payload }) => {
      state.list = [...state.list, ...payload]
    },
  },
})

export const { setList, updateList } = explorerSlice.actions
export default explorerSlice.reducer
