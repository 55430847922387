import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import { api } from '../../api/api'

export const fetchTrackingsByTarget = createAsyncThunk(
  'wizard/fetchTrackingsByTarget',
  async (targetId) => {
    const response = await api.getTargetTracking(targetId)
    return response.data
  }
)

export const fetchActiveSectionByTarget = createAsyncThunk(
  'wizard/fetchActiveSectionByTarget',
  async (targetId) => {
    const response = await api.getWizardSectionByTarget(targetId)
    console.log('Active Wizard Section: ', response.data)
    return response.data
  }
)

export const updateActiveSectionByTarget = createAsyncThunk(
  'wizard/updateActiveSectionByTarget',
  async (body) => {
    try {
      const response = await api.updateWizardSectionByTarget(body.target_id, {
        section: body.section,
      })
      return response.data
    } catch (err) {
      // NB. Change wizard section even if request failed
      return { section: body.section }
    }
  }
)

const initialState = {
  fetchStatus: 'idle', // idle | loading | succeeded | failed
  formStatus: 'clean',
  isDisabled: false,

  error: '', // string

  activeSection: 'INTRO',

  trackings: [],
}

const wizardSlice = createSlice({
  name: 'wizard',
  initialState: initialState,
  reducers: {
    setFormStatus: (state, { payload }) => {
      state.formStatus = payload
    },
    setDisabled: (state, { payload }) => {
      state.isDisabled = payload
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTrackingsByTarget.fulfilled, (state, action) => {
      state.trackings = action.payload
    })

    builder.addCase(fetchActiveSectionByTarget.fulfilled, (state, action) => {
      state.activeSection = action.payload
    })

    builder.addCase(updateActiveSectionByTarget.fulfilled, (state, action) => {
      state.activeSection = action.payload.section
    })
  },
})

const getTrackings = (state) => state.wizard.trackings
const getObjectId = (_, objectId) => objectId // NB. used for passing a parameter
export const getTrackingMapByObjectId = createSelector(
  getTrackings,
  getObjectId,
  (trackings, objectId) => {
    return trackings
      .filter((tracking) => tracking.obj_id === objectId)
      .reduce((acc, tracking) => ({ ...acc, [tracking.field]: tracking }), {})
  }
)

export const { setFormStatus, setDisabled } = wizardSlice.actions

export default wizardSlice.reducer
