import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, Navigate } from 'react-router-dom'
import moment from 'moment'
import { Edit, AddCircle } from '@mui/icons-material'
import {
  WizardContainer,
  WTitle,
  WContent,
  WList,
  WListItem,
  WListItemActions,
} from '../../wizard'

const TargetLine = ({ target }) => (
  <>
    {target.target_title && `${target.target_title} `}
    {target.target_full_name}
    {target.target_dob && ` (DOB: ${moment(target.target_dob).format('L')})`}
  </>
)

export const TargetsWizardList = () => {
  let navigate = useNavigate()
  const targets = useSelector((state) => state.user.targets)
  const [target, setTarget] = useState()

  const handleAdd = () => {
    navigate(`/targets/wizard/new`)
  }

  if (target) {
    return <Navigate to={`/targets/wizard/${target.target_id}/edit`} />
  }

  return (
    <WizardContainer>
      <WTitle style={{ paddingBottom: '1rem' }}>
        Do you want to edit an existing target or create one from scratch?
      </WTitle>
      <WContent style={{ paddingBottom: '1rem' }}>
        <WList style={{ width: '80%' }}>
          <WListItem onClick={handleAdd}>
            Add Another
            <WListItemActions>
              <AddCircle />
            </WListItemActions>
          </WListItem>
          {targets.map((target) => (
            <WListItem key={target.target_id} onClick={() => setTarget(target)}>
              <TargetLine target={target} />
              <WListItemActions>
                <Edit />
              </WListItemActions>
            </WListItem>
          ))}
        </WList>
      </WContent>
    </WizardContainer>
  )
}
