import axios from 'axios'
import { errorHandler } from './errorHandler'

export const governings = {
  createGoverning: async (body) => {
    try {
      return await axios.post(`/targets/governing/create`, body)
    } catch (err) {
      errorHandler(err)
    }
  },
  updateGoverning: async (id, body) => {
    try {
      return await axios.patch(`/targets/governing/update/${id}`, {
        ...body,
      })
    } catch (err) {
      errorHandler(err)
    }
  },
  deleteGoverning: async (id) => {
    try {
      return await axios.delete(`/targets/governing/delete/${id}`)
    } catch (err) {
      errorHandler(err)
    }
  },
}
