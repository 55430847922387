import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

import { Card, CardHeader, CardContent, Collapse } from '@material-ui/core'

import { IconButtons } from '../buttons/Buttons'
import { setFolderRefetch, setSelectedFolder } from '../slices/bulletSlice'
import { api } from '../../api/api'
import { Citations } from '../bullets/Citations'

import { setModalOpen, setModalState } from '../slices/navSlice'
import { EditFolder } from './EditFolder'

const Folder = ({ folder, newFolder, setIsCreate }) => {
  const dispatch = useDispatch()
  const [folderTargets, setFolderTargets] = useState([])
  const [expanded, setExpanded] = useState(false)
  const allTargets = useSelector((state) => state.user.targets)
  const folderRefetct = useSelector((state) => state.bullet.folderRefetch)
  const [isEdit, setIsEdit] = useState(false)

  const {
    folder_name,
    folder_detail,
    folder_id,
    bullets,
    briefs,
    folder_target,
    counts,
  } = folder

  const handleDeleteFolder = async () => {
    await api.deleteFolder(folder_id)
    dispatch(setFolderRefetch(!folderRefetct))
  }

  useEffect(() => {
    if (folder.folder_id === null) {
      const tempFolders = []
      folder.bullets.forEach((bullet) => {
        tempFolders.push(bullet.bullet_target)
      })
      folder.briefs.forEach((brief) => {
        tempFolders.push(brief.brief_target)
      })
      tempFolders.forEach((target) => {
        const match = allTargets.find((item) => item.target_id === target)
        if (!isEmpty(match) && !folderTargets.includes(match.target_full_name))
          setFolderTargets((folderTargets) => [
            ...folderTargets,
            match.target_full_name,
          ])
      })
    } else {
      folder_target.forEach((target) => {
        const match = allTargets.find((item) => item.target_id === target)
        if (!isEmpty(match) && !folderTargets.includes(match.target_full_name))
          setFolderTargets((folderTargets) => [
            ...folderTargets,
            match.target_full_name,
          ])
      })
    }
  }, [folder_target])

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const handleClick = (id) => {
    if (id !== null) {
      handleAddToFolder(id)
    } else {
      setIsCreate(true)
    }
  }

  const handleAddToFolder = () => {
    dispatch(setSelectedFolder(folder))
    dispatch(setModalState('addToFolder'))
    dispatch(setModalOpen(true))
  }

  const handleEditFolder = () => {
    setIsEdit(true)
  }

  return (
    <Container>
      {!isEdit && (
        <FolderCard>
          <StyledCardHeader
            onClick={(e) =>
              !newFolder ? e.preventDefault() : handleClick(folder.folder_id)
            }
            newFolder={newFolder}
            title={folder_name}
            subheader={
              <PRow>
                <PLeft>{`Target(s): ${folderTargets.map(
                  (target) => ' ' + target
                )}`}</PLeft>
                <PRight>{counts}</PRight>
              </PRow>
            }
            action={
              <IconContainer>
                {!newFolder && (
                  <IconButtons
                    iconType='edit'
                    toolTipValue='Edit Folder'
                    handleChange={handleEditFolder}
                  />
                )}
                {!newFolder && (
                  <IconButtons
                    iconType='delete'
                    toolTipValue='Delete Folder'
                    handleChange={handleDeleteFolder}
                  />
                )}
                {(!isEmpty(bullets) || !isEmpty(briefs)) && (
                  <IconButtons
                    iconType='expand'
                    toolTipValue='View Folder Details'
                    handleChange={toggleExpand}
                  />
                )}
              </IconContainer>
            }
          />
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <StyledCardContent>
              <Row>{folder_detail}</Row>
              {!isEmpty(bullets) && (
                <Bullets>
                  <CardHeader
                    title={
                      <Row>
                        <TitleLeft>Bullet(s)</TitleLeft>
                        <TitleCenter>
                          <CiteRow>Citation</CiteRow>
                        </TitleCenter>
                      </Row>
                    }
                  />
                  {bullets.map((bullet, index) => (
                    <Row key={bullet.bullet_id + index}>
                      <Left>{bullet.bullet_header}</Left>
                      <Center>
                        <CiteRow>
                          <Citations bullet={bullet} isModal={false} />
                        </CiteRow>
                      </Center>
                    </Row>
                  ))}
                </Bullets>
              )}
              {!isEmpty(briefs) && (
                <Briefs>
                  <CardHeader
                    title={
                      <Row>
                        <TitleLeft>Brief(s)</TitleLeft>
                      </Row>
                    }
                  />
                  {briefs.map((brief, index) => (
                    <Row key={brief.brief_id + index}>{brief.brief_header}</Row>
                  ))}
                </Briefs>
              )}
            </StyledCardContent>
          </Collapse>
        </FolderCard>
      )}
      {isEdit && <EditFolder incommingFolder={folder} setIsEdit={setIsEdit} />}
    </Container>
  )
}

export default Folder

const Container = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 1rem;
  width: 100%;
`
const FolderCard = styled(Card)`
  flex-direction: column;
`
const StyledCardHeader = styled(CardHeader)`
  ${(props) =>
    props.newFolder &&
    `&:hover {
      background: lightgrey;
    }`}
`
const Bullets = styled(Card)`
  padding: 8px;
  margin-bottom: 1rem;
`
const Briefs = styled(Card)`
  padding: 8px;
`
const StyledCardContent = styled(CardContent)`
  height: auto;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
const CiteRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Left = styled.span`
  width: 60%;
`
const Center = styled.span`
  width: 40%;
`
const TitleLeft = styled.span`
  width: 60%;
  font-size: 1.222rem;
`
const TitleCenter = styled.span`
  width: 40%;
  font-size: 1.222rem;
`
const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const PRow = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
const PLeft = styled.span`
  width: 75%;
`
const PRight = styled.span`
  width: 25%;
`
