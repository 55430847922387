import styled from 'styled-components'
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from '@material-ui/core'
import { MomentDate } from '../util/MomentDate'
import { FamilyPresenter } from '../../presenters/family_presenter'

export const TargetFamily = (props) => {
  const { family_members } = props
  const presentableMembers = family_members.map(
    (member) => new FamilyPresenter(member)
  )

  return (
    <Box sx={{ p: '1rem' }}>
      <TableContainer component={Box} sx={{ backgroundColor: '#EEE' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <StyledRowHead>
              <TableCell>Type</TableCell>
              <TableCell>First</TableCell>
              <TableCell>Middle</TableCell>
              <TableCell>Last</TableCell>
              <TableCell>Nick</TableCell>
              <TableCell>Preferred</TableCell>
              <TableCell>Full</TableCell>
              <TableCell>Suffix</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
            </StyledRowHead>
          </TableHead>
          <TableBody>
            {presentableMembers.map((member, index) => (
              <StyledRow
                key={member.family_id}
                className={index % 2 === 0 ? 'even' : 'odd'}
              >
                <StyledCell>{member.family_type}</StyledCell>
                <StyledCell>{member.family_first_name}</StyledCell>
                <StyledCell>{member.family_middle_name}</StyledCell>
                <StyledCell>{member.family_last_name}</StyledCell>
                <StyledCell>{member.family_nick_name}</StyledCell>
                <StyledCell>{member.preferredName}</StyledCell>
                <StyledCell>{member.family_full_name}</StyledCell>
                <StyledCell>{member.family_suffix}</StyledCell>
                <StyledCell>
                  <MomentDate date={member.family_dob} />
                </StyledCell>
                <StyledCell>{member.family_gender}</StyledCell>
                <StyledCell>
                  <MomentDate date={member.family_start_date} />
                </StyledCell>
                <StyledCell>
                  <MomentDate date={member.family_end_date} />
                </StyledCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const StyledRowHead = styled(TableRow)`
  background: #d2d2d2;
  border-bottom: 2px solid darkgray;

  th {
    font-weight: bold;
  }
`

const StyledRow = styled(TableRow)`
  border-top: ${(props) => (props.nested ? 'none' : '1px solid lightgray')};
  background: #eee;

  &.even {
    background: #e3e3e3;
  }

  &:hover,
  &:focus {
    ${(props) => !props.nested && 'background: #d4d4d4;'};
  }
`

const StyledCell = styled(TableCell)`
  border-bottom: none;
`
