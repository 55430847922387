import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setRightOpen, setRightState } from '../slices/navSlice'
import styled from 'styled-components'
import { Button, Container, Typography } from '@material-ui/core'
import SearchBar from '@snekcode/mui-search-bar'
import { isEmpty } from 'lodash'
import NewFolder from './NewFolder'

import { TargetSelector } from './TargetSelector'
import Folder from './Folder'
import { api } from '../../api/api'
import { IconButtons } from '../buttons/Buttons'

export const FoldersPage = ({ newFolder = false }) => {
  const folderTargets = useSelector((state) => state.bullet.folder_targets)
  const selected_briefs = useSelector((state) => state.bullet.selected_briefs)
  const selected_bullets = useSelector((state) => state.bullet.selected_bullets)
  const refetch = useSelector((state) => state.bullet.folderRefetch)
  const allTargets = useSelector((state) => state.user.targets)
  const [lookUpVal, setLookUpVal] = useState('')
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState(data)
  const [isCreate, setIsCreate] = useState(false)

  const dispatch = useDispatch()

  const currentTargets = useSelector((state) => state.bullet.selected_targets)

  const getFolders = async () => {
    const tempTargets = []
    currentTargets.forEach((t) => {
      tempTargets.push({
        target_id: t.id,
        name: t.name,
      })
    })
    const searchTargets = !isEmpty(folderTargets) ? folderTargets : allTargets
    try {
      const folders = await api.getFoldersByTargets({
        targets: searchTargets,
      })
      setData(folders)
      setFilteredData(folders)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getFolders()
  }, [folderTargets, refetch])

  const handleCreateNewFolder = () => {
    setIsCreate(true)
  }

  const filterFolders = () => {
    if (lookUpVal !== '') {
      setFilteredData(
        data.filter((item) => {
          if (item.folder_detail !== null) {
            return (
              item.folder_name
                .toLowerCase()
                .includes(lookUpVal.toLowerCase()) ||
              item.folder_detail.toLowerCase().includes(lookUpVal.toLowerCase())
            )
          } else
            return item.folder_name
              .toLowerCase()
              .includes(lookUpVal.toLowerCase())
        })
      )
    } else {
      clearSearchFilter()
    }
  }

  const clearSearchFilter = () => {
    setFilteredData(data)
  }

  return (
    <FolderContainer>
      <Col>
        <Row>
          <Typography variant='h6'>Folders</Typography>
          <SearchBar
            value={lookUpVal}
            onChange={(val) => {
              setLookUpVal(val)
            }}
            onRequestSearch={() => filterFolders()}
            onBlur={() => filterFolders()}
            onCancelSearch={() => {
              setLookUpVal('')
              clearSearchFilter()
            }}
            placeholder={'Search'}
          />
          <IconButtons
            iconType='newFolder'
            toolTipValue='Create New Folder'
            handleChange={handleCreateNewFolder}
          />
        </Row>
        <Row>
          <TargetSelector />
        </Row>
      </Col>
      {newFolder && (
        <Col>
          <Row>
            <Typography variant='h6'>{`${selected_bullets.length} bullets, ${selected_briefs.length} briefs selected`}</Typography>
          </Row>
        </Col>
      )}
      <FolderCol>
        {newFolder && !isCreate && (
          <Folder
            key='new_folder'
            setIsCreate={setIsCreate}
            newFolder={newFolder}
            folder={{
              briefs: selected_briefs,
              bullets: selected_bullets,
              counts: `${selected_bullets.length} bullets, ${selected_briefs.length} briefs`,
              folder_detail: null,
              folder_id: null,
              folder_name: 'Create New Folder',
              folder_target: folderTargets,
            }}
          />
        )}
        {isCreate && (
          <NewFolder
            setIsCreate={setIsCreate}
            incommingFolder={{
              briefs: selected_briefs,
              bullets: selected_bullets,
              counts: `${selected_bullets.length} bullets, ${selected_briefs.length} briefs`,
              folder_detail: null,
              folder_id: null,
              folder_name: 'Create New Folder',
            }}
            incommingTargets={folderTargets}
          />
        )}
        {filteredData.map((folder) => (
          <Folder
            key={folder.folder_id}
            folder={folder}
            newFolder={newFolder}
          />
        ))}
      </FolderCol>

      <Button
        style={{ alignSelf: 'end' }}
        onClick={() => {
          dispatch(setRightOpen(false))
          dispatch(setRightState(null))
        }}
      >
        Return to Explorer
      </Button>
    </FolderContainer>
  )
}

const FolderContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px !important;
  height: 100vh;
  margin-top: 2rem;
  margin-bottom: 2rem;
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`
const FolderCol = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  height: 65vh;
  width: 100%;
  overflow-y: scroll;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`
