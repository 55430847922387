import styled from 'styled-components'
import { isEmpty } from 'lodash'

export const SubHeader = ({ bullet, isClient, currentTarget }) => {
  const { record_status, content_type, bullet_type } = bullet
  return (
    <Row>
      <Col>
        {!isEmpty(record_status) && !isClient
          ? 'Status: ' +
            record_status
              .toLowerCase()
              .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())
              .replace('_', ' ')
          : ''}
      </Col>
      {!isClient && <Col>Target: {currentTarget}</Col>}
      {!isEmpty(bullet_type) && (
        <Col>
          Type:{' '}
          {bullet_type
            .toLowerCase()
            .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())
            .replace('_', ' ') +
            '/' +
            content_type
              .toLowerCase()
              .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())
              .replace('only', '')}
        </Col>
      )}
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const Col = styled.div`
  max-width: 25%;
  margin: 0 1rem 0 0;
  text-transform: capitalize;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
