import { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { setFolderTargets } from '../slices/bulletSlice'

export const TargetSelector = () => {
  const dispatch = useDispatch()
  const currentTargets = useSelector((state) => state.bullet.selected_targets)
  const allTargets = useSelector((state) => state.user.targets)
  const [cleanedTargets, setCleanedTargets] = useState([])
  const [targets, setTargets] = useState([])

  useEffect(() => {
    const temp = []
    allTargets.forEach((t) => {
      temp.push({
        name: t.target_full_name,
        id: t.target_id,
      })
    })
    setCleanedTargets(temp)
  }, [allTargets])

  useEffect(() => {
    addTarget(currentTargets)
  }, [])

  const handleKeyDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
    switch (event.key) {
      case ' ': {
        addTarget(event)
        break
      }
      case '13': {
        addTarget(event)
        break
      }
      default:
    }
  }

  const addTarget = (value) => {
    setTargets(value)
    const tempTarget = []
    value.forEach((t) => {
      tempTarget.push({
        target_id: t.id,
        name: t.name,
      })
    })
    dispatch(setFolderTargets(tempTarget))
  }
  const removeTarget = (val) => {
    const deleted = targets.filter((t) => t.name !== val)
    setTargets(deleted)
    const tempTarget = []
    deleted.forEach((t) => {
      tempTarget.push({
        target_id: t.id,
        name: t.name,
      })
    })
    dispatch(setFolderTargets(tempTarget))
  }
  return (
    <Autocomplete
      fullWidth
      multiple
      id='targets-filled'
      value={targets}
      options={cleanedTargets}
      getOptionLabel={(option) => option.name}
      onChange={(e, newTarget) => {
        if (!targets.some((t) => t.name === e.target.innerHTML)) {
          addTarget(newTarget)
        } else {
          removeTarget(e.target.innerHTML)
        }
      }}
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown
        return (
          <TextField {...params} label='Targets' placeholder='Add a target' />
        )
      }}
    />
  )
}
