import styled from 'styled-components'
import { useState, Fragment } from 'react'
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Typography,
} from '@material-ui/core'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export const TargetGovernings = (props) => {
  const { governings } = props
  const [isExpanded, setExpanded] = useState({})

  const toggleExpandGoverning = (governing_id) => {
    setExpanded((prevState) => {
      return { ...prevState, [governing_id]: !prevState[governing_id] }
    })
  }

  return (
    <Box sx={{ p: '1rem' }}>
      <TableContainer component={Box} sx={{ backgroundColor: '#EEE' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <StyledRowHead>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Start Year</TableCell>
              <TableCell>End Year</TableCell>
              <TableCell>Office</TableCell>
              <TableCell>District</TableCell>
            </StyledRowHead>
          </TableHead>
          <TableBody>
            {governings.map((governing, index) => (
              <Fragment key={governing.governing_id}>
                <StyledRow
                  className={index % 2 === 0 ? 'even' : 'odd'}
                  onClick={() => toggleExpandGoverning(governing.governing_id)}
                >
                  <StyledCell>
                    {isExpanded[governing.governing_id] ? (
                      <KeyboardArrowDownIcon fontSize='small' />
                    ) : (
                      <KeyboardArrowRightIcon fontSize='small' />
                    )}
                  </StyledCell>
                  <StyledCell>{governing.office_start_year}</StyledCell>
                  <StyledCell>{governing.office_end_year}</StyledCell>
                  <StyledCell>{governing.office}</StyledCell>
                  <StyledCell>{governing.office_district}</StyledCell>
                </StyledRow>
                {isExpanded[governing.governing_id] && (
                  <StyledRow
                    nested={'yes'}
                    className={index % 2 === 0 ? 'even' : 'odd'}
                  >
                    <StyledExtraCell colSpan={8}>
                      <Typography variant='h6'>
                        Associated Committees
                      </Typography>
                      <ul>
                        {governing.committees.map((committee) => (
                          <li key={committee.organization_id}>
                            {committee.organization_type} -{' '}
                            {committee.organization_name} (
                            {committee.organization_start_year} -{' '}
                            {committee.organization_end_year})
                          </li>
                        ))}
                      </ul>
                    </StyledExtraCell>
                  </StyledRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const StyledRowHead = styled(TableRow)`
  background: #d2d2d2;
  border-bottom: 2px solid darkgray;

  th {
    font-weight: bold;
  }
`

const StyledRow = styled(TableRow)`
  border-top: ${(props) => (props.nested ? 'none' : '1px solid lightgray')};
  background: #eee;

  &.even {
    background: #e3e3e3;
  }

  &:hover,
  &:focus {
    ${(props) => !props.nested && 'background: #d4d4d4;'};
  }
`

const StyledCell = styled(TableCell)`
  border-bottom: none;
`

const StyledExtraCell = styled(TableCell)`
  padding: 1rem 2rem 2rem;

  ul {
    margin: 0;
    padding-left: 1rem;
  }
`
