import { pick } from 'lodash'

import { WTitle, WDescription } from '../../wizard'
import { api } from '../../../api/api'
import { TARGET_FIELDS } from '../../../utils/forms'
import { FormWithTracking } from './FormWithTracking'

export const WNamesTab = ({ target, onSubmitSuccess, onExit }) => {
  const NAME_FIELDS = pick(TARGET_FIELDS, [
    'target_first_name',
    'target_last_name',
    'target_title',
    'target_middle_name',
    'target_nick_name',
    'target_preferred_first',
    'target_preferred_last',
    'target_suffix',
  ])

  const handleSubmit = async (body) => {
    await api.patchTarget(target.target_id, body)
  }

  return (
    <>
      <WTitle>Does the target have preferred names?</WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>
        Targets may go by names other than their legal. Let's fill in preferred
        and other name components.
      </WDescription>
      <FormWithTracking
        objectId={target.target_id}
        initialAttributes={target}
        fields={NAME_FIELDS}
        onSubmit={handleSubmit}
        onSubmitSuccess={onSubmitSuccess}
        onExit={onExit}
      />
    </>
  )
}
