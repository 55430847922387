import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Checkbox,
} from '@material-ui/core'
import { addSelectedBrief, removeSelectedBrief } from '../slices/bulletSlice'
import { Bullet } from '../bullets/Bullet'

import { Actions } from './Actions'

export const Brief = ({ brief, inBuilder = false, inFolder = false }) => {
  const dispatch = useDispatch()
  const [currentTarget, setCurrentTarget] = useState('')
  const targetList = useSelector((state) => state.user.targets)
  const { bullets, brief_header, brief_body, record_status, brief_target } =
    brief
  const selectedBriefsList = useSelector(
    (state) => state.bullet.selected_briefs
  )
  const isClient = useSelector((state) => state.user.isClient)
  const check = () => {
    return selectedBriefsList.includes(brief)
  }
  useEffect(() => {
    if (brief_target !== undefined) {
      const thisTarget = targetList.filter((item) => {
        return item.target_id === brief_target
      })
      setCurrentTarget(thisTarget[0].target_full_name)
    } else {
      setCurrentTarget(undefined)
    }
  }, [brief])

  const handleChange = (event) => {
    if (event.target.checked) {
      dispatch(addSelectedBrief(brief))
    } else {
      dispatch(removeSelectedBrief(brief.brief_id))
    }
  }

  return (
    <StyledContainer>
      {!inFolder && !inBuilder && (
        <CheckContainer>
          <Checkbox
            color='primary'
            checked={check(selectedBriefsList)}
            onChange={handleChange}
          />
        </CheckContainer>
      )}
      <BriefCard>
        <BriefHeader
          style={{ padding: '16px 16px 0 16px' }}
          action={
            <Actions
              brief={brief}
              inFolder={inFolder}
              inBuilder={inBuilder}
              isClient={isClient}
            />
          }
          title={!isEmpty(brief_header) ? brief_header : ''}
          subheader={
            <Row>
              <Col>
                {!isEmpty(record_status) && !isClient
                  ? 'Status: ' +
                    record_status
                      .toLowerCase()
                      .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())
                      .replace('_', ' ')
                  : ''}
              </Col>

              {!isClient && <Col>Target: {currentTarget}</Col>}
            </Row>
          }
        />
        {![null, undefined, 'undefined', ''].includes(brief_body) && (
          <CardContent style={{ padding: '0 16px 0 16px' }}>
            <CardBody>{brief_body}</CardBody>
          </CardContent>
        )}
        <CardContent style={{ padding: '16px 16px 0 16px' }}>
          {bullets && (
            <>
              {bullets.map((bullet, index) => (
                <Row key={bullet.bullet_id + index}>
                  <Bullet
                    key={bullet.bullet_id + index}
                    bullet={bullet}
                    inFolder={inFolder}
                    inBrief={true}
                  />
                </Row>
              ))}
            </>
          )}
        </CardContent>
      </BriefCard>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 24px;
  flex-direction: row;
  justfy-content: center;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
const BriefCard = styled(Card)`
  padding: 8px;
  background-color: #d3d3d3;
  width: 100%;
`
const BriefHeader = styled(CardHeader)`
  margin-bottom: 10px;
  padding-bottom: 0px;
`
const CardBody = styled(Typography)`
  padding: 10px 10px;
  max-height: 200px;
  overflow: scroll;
  margin: 10px 0px;
  background-color: #bdbdbd;
  border-radius: 5px;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const Col = styled.div`
  max-width: 25%;
  margin: 0 1rem 0 0;
  text-transform: capitalize;
`
const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
