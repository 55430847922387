import { compact } from 'lodash'
import { ObjectPresenter } from './object_presenter'

export class FamilyPresenter extends ObjectPresenter {
  get preferredName() {
    return compact([
      this.object.family_preferred_first,
      this.object.family_preferred_last,
    ]).join(' ')
  }
}
