import { useNavigate } from 'react-router-dom'

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'

export const TargetsSelect = ({ targets, targetId }) => {
  const navigate = useNavigate()

  const handleChange = (event) => {
    const newTargetId = event.target.value
    if (newTargetId !== targetId) {
      navigate(`/targets/${newTargetId}`)
    }
  }

  return (
    <Box sx={{ minWidth: 300 }}>
      <FormControl fullWidth>
        <InputLabel id='input-select-target'>Select a Target</InputLabel>
        <Select
          labelId='input-select-target'
          value={targetId}
          placeholder='Select a Target'
          onChange={handleChange}
        >
          {targets.map((target) => (
            <MenuItem value={target.target_id} key={target.target_id}>
              {target.target_title} {target.target_full_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
