import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Tabs, Tab, Button } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import { LoadingBalls } from '../util/LoadingBalls'
import { TargetsSelect } from './TargetsSelect'
import { TargetProfile } from './TargetProfile'
import { TargetBiography } from './TargetBiography'
import { TargetAddresses } from './TargetAddresses'
import { TargetFamily } from './TargetFamily'
import { TargetEducation } from './TargetEducation'
import { TargetLinks } from './TargetLinks'
import { TargetElections } from './TargetElections'
import { TargetGovernings } from './TargetGovernings'
import { TargetOrganizations } from './TargetOrganizations'
import { TargetStatistics } from './TargetStatistics'
import { fetchTargetDetails, selectTarget } from '../slices/targetSlice'

export const TargetsPage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const targetId = params.targetId || '131CFE12' // FIXME
  const isReadOnly = useSelector((state) => state.user.client)
  const fetchStatus = useSelector((state) => state.target.status)
  const target = useSelector(selectTarget)
  const family_members = useSelector((state) => state.target.family_members)
  const education = useSelector((state) => state.target.education)
  const links = useSelector((state) => state.target.links)
  const elections = useSelector((state) => state.target.elections)
  const governings = useSelector((state) => state.target.governings)
  const organizations = useSelector((state) => state.target.organizations)
  const leftOpen = useSelector((state) => state.nav.leftOpen)
  const targets = useSelector((state) => state.user.targets)
  const [activeTab, setActiveTab] = useState('biography')

  const handleChange = (event, newTab) => {
    setActiveTab(newTab)
  }

  useEffect(() => {
    dispatch(fetchTargetDetails(targetId))
  }, [dispatch, targetId])

  if (fetchStatus === 'idle' || fetchStatus === 'loading') {
    return <LoadingBalls open={leftOpen} />
  }

  return (
    <StyledTargetsContainer leftOpen={leftOpen}>
      <StyledProfileSection>
        <TargetProfile target={target} />
        <div>
          {targets.length > 0 && (
            <TargetsSelect targets={targets} targetId={targetId} />
          )}

          {!isReadOnly && (
            <Link
              to={`/targets/wizard`}
              style={{
                textDecoration: 'none',
                display: 'inline-block',
                marginTop: 16,
                width: '100%',
              }}
            >
              <Button fullWidth variant='contained'>
                Launch Wizard
              </Button>
            </Link>
          )}
        </div>
      </StyledProfileSection>

      <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
        <StyledTabs
          value={activeTab}
          onChange={handleChange}
          variant='scrollable'
        >
          <StyledTab label='Biography' value='biography' />
          <StyledTab label='Addresses' value='addresses' />
          <StyledTab label='Family' value='family' />
          <StyledTab label='Education' value='education' />
          <StyledTab label='Web Accounts' value='links' />
          <StyledTab label='Elections' value='elections' />
          <StyledTab label='Offices Held' value='governings' />
          <StyledTab
            label='Organizations &amp; Affiliations'
            value='organizations'
          />
          <StyledTab label='Research Statistics' value='statistics' />
        </StyledTabs>
      </Box>

      <div role='tabpanel' hidden={activeTab !== 'biography'}>
        <TargetBiography target={target} />
      </div>
      <div role='tabpanel' hidden={activeTab !== 'addresses'}>
        <TargetAddresses />
      </div>
      <div role='tabpanel' hidden={activeTab !== 'family'}>
        <TargetFamily family_members={family_members} />
      </div>
      <div role='tabpanel' hidden={activeTab !== 'education'}>
        <TargetEducation education={education} />
      </div>
      <div role='tabpanel' hidden={activeTab !== 'links'}>
        <TargetLinks links={links} />
      </div>
      <div role='tabpanel' hidden={activeTab !== 'elections'}>
        <TargetElections elections={elections} />
      </div>
      <div role='tabpanel' hidden={activeTab !== 'governings'}>
        <TargetGovernings governings={governings} />
      </div>
      <div role='tabpanel' hidden={activeTab !== 'organizations'}>
        <TargetOrganizations organizations={organizations} />
      </div>
      <div role='tabpanel' hidden={activeTab !== 'statistics'}>
        <TargetStatistics target_id={targetId} />
      </div>
    </StyledTargetsContainer>
  )
}

const StyledProfileSection = styled.section`
  background: #dce8f0;
  margin-top: 0;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
`

const StyledTargetsContainer = styled.div`
  width: ${(props) => (props.leftOpen ? 'calc(100% - 250)' : '100%')};
  margin: 0 0 60px;
  margin-left: ${(props) => (props.leftOpen ? '250px' : '0')};
`

const StyledTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    justify-content: space-between;
  }
`

const StyledTab = styled(Tab)`
  min-width: 100px;
`
