import styled from 'styled-components'
import { Grid, Paper } from '@material-ui/core'

import logoBallotpedia from '../../static/img/logos/logo-ballotpedia.png'
import logoBlog from '../../static/img/logos/logo-blog.png'
import logoCspan from '../../static/img/logos/logo-cspan.png'
import logoFacebook from '../../static/img/logos/logo-facebook.png'
import logoGoogle from '../../static/img/logos/logo-google.png'
import logoGovtrack from '../../static/img/logos/logo-govtrack.png'
import logoInstagram from '../../static/img/logos/logo-instagram.png'
import logoLegiscan from '../../static/img/logos/logo-legiscan.png'
import logoOpenfec from '../../static/img/logos/logo-openfec.png'
import logoOpenstates from '../../static/img/logos/logo-openstates.png'
import logoOpensecrets from '../../static/img/logos/logo-opensecrets.png'
import logoPropublica from '../../static/img/logos/logo-propublica.png'
import logoReddit from '../../static/img/logos/logo-reddit.png'
import logoTiktok from '../../static/img/logos/logo-tiktok.png'
import logoTwitter from '../../static/img/logos/logo-twitter.png'
import logoVotesmart from '../../static/img/logos/logo-votesmart.png'
import logoYoutube from '../../static/img/logos/logo-youtube.png'

const TwitterCard = (props) => {
  const { account_source, account, account_type } = props

  const renderIcon = (account_source) => {
    const mapSourceToLogo = {
      BALLOTPEDIA: logoBallotpedia,
      BLOG: logoBlog,
      CRP_OPENSECRETS: logoOpensecrets,
      CSPAN: logoCspan,
      FACEBOOK_ID: logoFacebook,
      FACEBOOK_USERNAME: logoFacebook,
      GOVTRACK: logoGovtrack,
      INSTAGRAM: logoInstagram,
      LEGISCAN: logoLegiscan,
      OPENFEC: logoOpenfec,
      OPEN_STATES: logoOpenstates,
      PROPUBLICA: logoPropublica,
      REDDIT: logoReddit,
      TIK_TOK: logoTiktok,
      TWITTER: logoTwitter,
      VOTESMART: logoVotesmart,
      WEBSITE: null,
      YOUTUBE: logoYoutube,
      GOOGLE_ENTITY: logoGoogle,
      OTHER_SOCIAL: null,
      OTHER: null,
    }

    const imageSrc = mapSourceToLogo[account_source]
    if (!imageSrc) {
      return
    }

    return (
      <StyledImageWrapper>
        <img src={imageSrc} alt={`${account_source} Icon`} />
      </StyledImageWrapper>
    )
  }

  const renderLink = (account_source, account) => {
    const mapSourceToUrl = {
      BALLOTPEDIA: `https://ballotpedia.org/${account}`,
      BLOG: account,
      CRP_OPENSECRETS: account,
      CSPAN: account,
      FACEBOOK_ID: `https://www.facebook.com/${account}`,
      FACEBOOK_USERNAME: `https://www.facebook.com/${account}`,
      GOVTRACK: `https://www.govtrack.us/congress/members/${account}`,
      INSTAGRAM: `https://www.instagram.com/${account}`,
      LEGISCAN: account,
      OPENFEC: `https://www.fec.gov/data/candidates/?q=${account}`,
      OPEN_STATES: `https://openstates.org/person/${account}`,
      OTHER: account,
      OTHER_SOCIAL: account,
      PROPUBLICA: `https://projects.propublica.org/represent/members/${account}`,
      REDDIT: `https://www.reddit.com/user/${account}`,
      TIK_TOK: `https://www.tiktok.com/@${account}`,
      TWITTER: `https://twitter.com/${account}`,
      VOTESMART: `https://justfacts.votesmart.org/candidate/${account}`,
      WEBSITE: account,
      YOUTUBE: `https://www.youtube.com/${account}`,
    }

    const url = mapSourceToUrl[account_source]

    return (
      <>
        {url ? (
          <>
            <div>
              <a href={url} target='_blank' rel='noreferrer'>
                {account_source}
              </a>
            </div>
            {account_source === 'TWITTER' && (
              <div style={{ color: '#088F8F' }}>@{account}</div>
            )}
          </>
        ) : (
          <>
            <div>{account_source}</div>
            <div>ID: {account}</div>
          </>
        )}
      </>
    )
  }

  return (
    <StyledCard>
      {renderIcon(account_source)}
      {renderLink(account_source, account)}
      <div>
        <em>{account_type}</em>
      </div>
    </StyledCard>
  )
}

export const TargetLinks = (props) => {
  const { links } = props

  return (
    <Grid container>
      {links.map((link) => (
        <Grid item xs={6} md={3} key={link.link_id}>
          <StyledPaperWrapper>
            <StyledPaper elevation={4}>
              <TwitterCard {...link} />
            </StyledPaper>
          </StyledPaperWrapper>
        </Grid>
      ))}
    </Grid>
  )
}

const StyledPaperWrapper = styled.div`
  margin: 2rem auto 0;
  width: 75%;
  padding-bottom: 66%;
  position: relative;
`

const StyledPaper = styled(Paper)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ddd;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledCard = styled.div`
  text-align: center;
`
const StyledImageWrapper = styled.div`
  padding: 0 25% 6px;
`
