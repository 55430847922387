import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { setRole, setIsClient, setUser } from '../slices/usersSlice'
import { api } from '../../api/api'

export const PrivateRoute = () => {
  const dispatch = useDispatch()
  const stateUser = useSelector((state) => state.user.user)
  const [localUser, setLocalUser] = useState(stateUser)

  useEffect(() => {
    if (stateUser === null || stateUser === undefined) getUser()
    else return true
  }, [])

  const getUser = async () => {
    try {
      const currentUser = await api.getUser()
      setLocalUser(currentUser.id)
      dispatch(setUser(currentUser.id))
      if (currentUser.is_superuser) {
        dispatch(setRole('ADMIN'))
        dispatch(setIsClient(false))
      } else if (currentUser.is_staff) {
        dispatch(setRole('STAFF'))
        dispatch(setIsClient(false))
      } else {
        dispatch(setRole('CLIENT'))
        dispatch(setIsClient(true))
      }
    } catch (err) {
      setLocalUser(null)
      throw err
    }
  }
  return localUser !== null || localUser !== undefined ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  )
}
