import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { EditOutlined, ClearOutlined, DoneOutlined } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'

export const EditableTextarea = ({ defaultValue, onSave }) => {
  const isReadOnly = useSelector((state) => state.user.client)
  const [isEditing, setEditing] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const [tempText, setTempText] = useState(defaultValue)

  useEffect(() => {
    setTempText(defaultValue)
  }, [defaultValue])

  const handleSave = async (event) => {
    setSaving(true)

    try {
      await onSave(tempText)
    } catch (err) {
      throw err
    }

    setSaving(false)
    setEditing(false)
  }

  const handleChange = (event) => {
    setTempText(event.target.value)
  }

  const handleClear = (event) => {
    setTempText(defaultValue)
    setEditing(false)
  }

  const renderActions = () => {
    if (!isEditing) {
      return <EditOutlined onClick={() => setEditing(true)} />
    }

    if (isSaving) {
      return <CircularProgress size={24} />
    } else {
      return (
        <>
          <ClearOutlined onClick={handleClear} />
          <DoneOutlined onClick={handleSave} />
        </>
      )
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      {!isReadOnly && (
        <StyledActionsWrapper>{renderActions()}</StyledActionsWrapper>
      )}

      {isEditing && !isSaving ? (
        <StyledTextarea
          multiline
          defaultValue={tempText}
          placeholder='Type something...'
          onChange={handleChange}
        />
      ) : (
        <StyledBiographyBox>{tempText}</StyledBiographyBox>
      )}
    </div>
  )
}

const StyledBiographyBox = styled(Box)`
  box-sizing: border-box;
  height: 123.5px; // NB. matching to prevent shift
  padding: 2rem;
  background: #eee;
  border: 1px solid #eee; // NB. matching to prevent shift
  overflow-y: scroll;
`
const StyledTextarea = styled('textarea')`
  box-sizing: border-box;
  height: 120px;
  width: 100%;
  padding: 2rem;
  color: black;
  background: white;
  border: 1px solid black;
  border-radius: 0;
  resize: none;

  // match base fonts...
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
`

const StyledActionsWrapper = styled.div`
  height: 16px;
  position: absolute;
  top: 0.5rem;
  right: 1rem;

  svg {
    cursor: pointer;
  }
`
