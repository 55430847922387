import React, { Component, createRef } from 'react'
import './editor.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faVolumeMute,
  faVolumeUp,
  faPause,
  faPlay,
  faSync,
  faDownload,
} from '@fortawesome/free-solid-svg-icons'

class Editor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMuted: false,
      playing: false,
      currently_grabbed: { index: 0, type: 'none' },
      difference: 0.2,
      current_warning: null,
    }
    this.playVideo = createRef()
    this.progressBar = createRef()
    this.playBackBar = createRef()
  }

  reader = new FileReader()

  componentDidMount() {
    // Check if video ended
    var self = this
    this.playVideo.current.addEventListener('timeupdate', function () {
      var curr_idx = self.state.currently_grabbed.index
      var seek =
        ((self.playVideo.current.currentTime -
          self.props.timings[curr_idx].start) /
          self.playVideo.current.duration) *
        100
      self.progressBar.current.style.width = `${seek}%`
      if (
        self.playVideo.current.currentTime >=
        self.props.timings[self.props.timings.length - 1].end
      ) {
        self.playVideo.current.pause()
        self.setState({ playing: false })
      } else if (
        self.playVideo.current.currentTime >= self.props.timings[curr_idx].end
      ) {
        if (curr_idx + 1 < self.props.timings.length) {
          self.setState(
            { currently_grabbed: { index: curr_idx + 1, type: 'start' } },
            () => {
              self.progressBar.current.style.width = '0%'
              self.progressBar.current.style.left = `${
                (self.props.timings[curr_idx + 1].start /
                  self.playVideo.current.duration) *
                100
              }%`
              self.playVideo.current.currentTime =
                self.props.timings[curr_idx + 1].start
            }
          )
        }
      }
    })

    window.addEventListener('keyup', function (event) {
      if (event.key === ' ') {
        self.play_pause()
      }
    })
    var time = this.props.timings
    this.playVideo.current.onloadedmetadata = () => {
      if (time.length === 0) {
        time.push({ start: 0, end: this.playVideo.current.duration })
        this.props.updateState({ timings: time }, () => {
          this.addActiveSegments()
        })
      } else {
        this.addActiveSegments()
      }
    }
  }
  componentWillUnmount() {
    this.props.updateState({ timings: [] })
  }

  reset = () => {
    this.playVideo.current.pause()
    this.setState({
      isMuted: false,
      playing: false,
      currently_grabbed: { index: 0, type: 'none' },
      difference: 0.2,
      current_warning: null,
    })
    this.props.updateState(
      { timings: [{ start: 0, end: this.playVideo.current.duration }] },
      () => {
        this.playVideo.current.currentTime = this.props.timings[0].start
        this.progressBar.current.style.left = `${
          (this.props.timings[0].start / this.playVideo.current.duration) * 100
        }%`
        this.progressBar.current.style.width = '0%'
        this.addActiveSegments()
      }
    )
  }

  play_pause = () => {
    var self = this
    if (this.state.playing) {
      this.playVideo.current.pause()
    } else {
      if (
        self.playVideo.current.currentTime >=
        self.props.timings[self.props.timings.length - 1].end
      ) {
        self.playVideo.current.pause()
        self.setState(
          { playing: false, currently_grabbed: { index: 0, type: 'start' } },
          () => {
            self.playVideo.current.currentTime = self.props.timings[0].start
            self.progressBar.current.style.left = `${
              (self.props.timings[0].start / self.playVideo.current.duration) *
              100
            }%`
            self.progressBar.current.style.width = '0%'
          }
        )
      }
      this.playVideo.current.play()
    }
    this.setState({ playing: !this.state.playing })
  }

  updateProgress = (event) => {
    var playbackRect = this.playBackBar.current.getBoundingClientRect()
    var seekTime =
      ((event.clientX - playbackRect.left) / playbackRect.width) *
      this.playVideo.current.duration
    this.playVideo.current.pause()
    // find where seekTime is in the segment
    var index = -1
    var counter = 0
    for (let times of this.props.timings) {
      if (seekTime >= times.start && seekTime <= times.end) {
        index = counter
      }
      counter += 1
    }
    if (index === -1) {
      return
    }
    this.setState(
      { playing: false, currently_grabbed: { index: index, type: 'start' } },
      () => {
        this.progressBar.current.style.width = '0%' // Since the width is set later, this is necessary to hide weird UI
        this.progressBar.current.style.left = `${
          (this.props.timings[index].start / this.playVideo.current.duration) *
          100
        }%`
        this.playVideo.current.currentTime = seekTime
      }
    )
  }

  startGrabberMove = (event) => {
    this.playVideo.current.pause()
    var playbackRect = this.playBackBar.current.getBoundingClientRect()
    var seekRatio = (event.clientX - playbackRect.left) / playbackRect.width
    const index = this.state.currently_grabbed.index
    const type = this.state.currently_grabbed.type
    window.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', this.startGrabberMove)
      this.addActiveSegments()
    })
    var time = this.props.timings
    var seek = this.playVideo.current.duration * seekRatio
    if (
      type === 'start' &&
      seek >
        (index !== 0 ? time[index - 1].end + this.state.difference + 0.2 : 0) &&
      seek < time[index].end - this.state.difference
    ) {
      this.progressBar.current.style.left = `${seekRatio * 100}%`
      this.playVideo.current.currentTime = seek
      time[index]['start'] = seek
      this.setState({ playing: false })
      this.props.updateState({ timings: time })
    } else if (
      type === 'end' &&
      seek > time[index].start + this.state.difference &&
      seek <
        (index !== this.props.timings.length - 1
          ? time[index + 1].start - this.state.difference - 0.2
          : this.playVideo.current.duration)
    ) {
      this.progressBar.current.style.left = `${
        (time[index].start / this.playVideo.current.duration) * 100
      }%`
      this.playVideo.current.currentTime = time[index].start
      time[index]['end'] = seek
      this.setState({ playing: false })
      this.props.updateState({ timings: time })
    }
    this.progressBar.current.style.width = '0%'
  }

  renderGrabbers = () => {
    if (!this.playVideo.current) return []
    return this.props.timings.map((x, index) => (
      <div key={'grabber_' + index}>
        <div
          className="grabber start"
          style={{
            left: `${(x.start / this.playVideo.current.duration) * 100}%`,
          }}
          onMouseDown={(event) => {
            this.setState(
              { currently_grabbed: { index: index, type: 'start' } },
              () => {
                window.addEventListener('mousemove', this.startGrabberMove)
              }
            )
          }}
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            width="10"
            height="14"
            viewBox="0 0 10 14"
            xmlSpace="preserve"
          >
            <path
              className="st0"
              d="M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z"
            />
          </svg>
        </div>
        <div
          className="grabber end"
          style={{
            left: `${(x.end / this.playVideo.current.duration) * 100}%`,
          }}
          onMouseDown={(event) => {
            this.setState(
              { currently_grabbed: { index: index, type: 'end' } },
              () => {
                window.addEventListener('mousemove', this.startGrabberMove)
              }
            )
          }}
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            width="10"
            height="14"
            viewBox="0 0 10 14"
            xmlSpace="preserve"
          >
            <path
              className="st0"
              d="M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z"
            />
          </svg>
        </div>
      </div>
    ))
  }

  addActiveSegments = () => {
    var self = this
    var colors = ''
    if (
      this.props.timings[0] !== undefined &&
      self.playVideo.current !== null &&
      self.playVideo.current !== undefined
    ) {
      var counter = 0
      colors += `, rgb(240, 240, 240) 0%, rgb(240, 240, 240) ${
        (this.props.timings[0].start / self.playVideo.current.duration) * 100
      }%`
      for (let times of this.props.timings) {
        if (counter > 0) {
          colors += `, rgb(240, 240, 240) ${
            (this.props.timings[counter - 1].end /
              self.playVideo.current.duration) *
            100
          }%, rgb(240, 240, 240) ${
            (times.start / self.playVideo.current.duration) * 100
          }%`
        }
        colors += `, #ccc ${
          (times.start / self.playVideo.current.duration) * 100
        }%, #ccc ${(times.end / self.playVideo.current.duration) * 100}%`
        counter += 1
      }
      colors += `, rgb(240, 240, 240) ${
        (this.props.timings[counter - 1].end /
          self.playVideo.current.duration) *
        100
      }%, rgb(240, 240, 240) 100%`
      self.playBackBar.current.style.background = `linear-gradient(to right${colors})`
    }
  }

  saveVideo = () => {
    var metadata = {
      trim_times: this.props.timings,
      mute: this.state.isMuted,
    }
    this.props.saveVideo(metadata)
  }

  render = () => {
    return (
      <div className="wrapper">
        <video
          className="video"
          autoload="metadata"
          muted={this.state.isMuted}
          ref={this.playVideo}
          onClick={this.play_pause.bind(this)}
        >
          <source src={this.props.videoUrl} type="video/mp4" />
        </video>
        <div className="playback">
          {this.renderGrabbers()}
          <div
            className="seekable"
            ref={this.playBackBar}
            onClick={this.updateProgress}
          ></div>
          <div className="progress" ref={this.progressBar}></div>
        </div>

        <div className="controls">
          <div className="player-controls">
            <button
              className="settings-control"
              title="Reset Video"
              onClick={this.reset}
            >
              <FontAwesomeIcon icon={faSync} />
            </button>
            <button
              className="settings-control"
              title="Mute/Unmute Video"
              onClick={() => this.setState({ isMuted: !this.state.isMuted })}
            >
              {this.state.isMuted ? (
                <FontAwesomeIcon icon={faVolumeMute} />
              ) : (
                <FontAwesomeIcon icon={faVolumeUp} />
              )}
            </button>
          </div>
          <div className="player-controls">
            <button
              className="play-control"
              title="Play/Pause"
              onClick={this.play_pause.bind(this)}
            >
              {this.state.playing ? (
                <FontAwesomeIcon icon={faPause} />
              ) : (
                <FontAwesomeIcon icon={faPlay} />
              )}
            </button>
          </div>
          <div>
            <button
              className="settings-control trim-control margined"
              title="Save Video"
              onClick={this.saveVideo}
            >
              <FontAwesomeIcon icon={faDownload} />
            </button>
          </div>
        </div>
        {this.state.current_warning !== null && (
          <div className={'warning'}>
            {this.warnings[this.state.current_warning]}
          </div>
        )}
      </div>
    )
  }
}

export default Editor
