import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import store from './store'
import './index.css'

import { PrivateRoute } from './components/login/PrivateRoute'
import { Login } from './components/login/Login'
import Navbar from './components/nav/Navbar'
import { Explorer } from './components/explorer/Explorer'
import ReportsPage from './components/reports/ReportsPage'
import { TargetsPage } from './components/targets/TargetsPage'
import { TargetsWizardList } from './components/targets/wizard/TargetsWizardList'
import { TargetWizardBio } from './components/targets/wizard/TargetWizardBio'
import { TargetWizardEdit } from './components/targets/wizard/TargetWizardEdit'
import { Modal } from './components/modal/Modal'
import { NotFound } from './components/404/404'

const theme = createTheme({
  palette: {
    primary: {
      main: '#5894b7',
    },
    secondary: {
      main: '#dce8f0',
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Navbar />
          <Modal />
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route exact path='/' element={<PrivateRoute />}>
              <Route path='/' element={<Explorer />} />
            </Route>
            <Route exact path='/reports' element={<PrivateRoute />}>
              <Route path='/reports' element={<ReportsPage />} />
            </Route>
            <Route path='/targets' element={<PrivateRoute />}>
              <Route path='wizard/new' element={<TargetWizardBio />} />
              <Route
                path='wizard/:targetId/edit'
                element={<TargetWizardEdit />}
              />
              <Route path='wizard' element={<TargetsWizardList />} />
              <Route path=':targetId' element={<TargetsPage />} />
              <Route index element={<TargetsPage />} />
            </Route>
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
