import { useState } from 'react'

import { SelectLayout } from './selectLayout'
import { MenuItems } from './menuItems'
import { MenuTypes, ContentMenuTypes } from './menuNames'
import { FilterText } from '../builderUtils'

export const Selects = (props) => {
  const { formState, setFormState, selectType, selectName } = props
  const [open, setOpen] = useState(false)

  const getTitle = () => {
    if (selectType === 'content_type') {
      return formState.content_type !== null ||
        formState.content_type !== undefined
        ? FilterText(
            formState.content_type,
            ContentMenuTypes(formState.bullet_type)
          ) !== undefined
          ? FilterText(
              formState.content_type,
              ContentMenuTypes(formState.bullet_type)
            )
          : 'Content Type'
        : 'Content Type'
    } else {
      return formState[selectType] !== null ||
        formState[selectType] !== undefined
        ? FilterText(formState[selectType], MenuTypes[selectType])
        : selectName
    }
  }
  return (
    <SelectLayout
      formState={formState}
      setFormState={setFormState}
      title={getTitle()}
      open={open}
      setOpen={setOpen}
    >
      {selectType === 'content_type' ? (
        <MenuItems
          formState={formState}
          setFormState={setFormState}
          open={open}
          setOpen={setOpen}
          menuTypes={ContentMenuTypes(formState.bullet_type)}
          formType={selectType}
        />
      ) : (
        <MenuItems
          formState={formState}
          setFormState={setFormState}
          open={open}
          setOpen={setOpen}
          menuTypes={MenuTypes[selectType]}
          formType={selectType}
        />
      )}
    </SelectLayout>
  )
}
