import axios from 'axios'
import { errorHandler } from './errorHandler'

export const organizations = {
  createOrganization: async (body) => {
    try {
      return await axios.post(`/targets/organization/create`, body)
    } catch (err) {
      errorHandler(err)
    }
  },
  updateOrganization: async (id, body) => {
    try {
      return await axios.patch(`/targets/organization/update/${id}`, {
        ...body,
      })
    } catch (err) {
      errorHandler(err)
    }
  },
  deleteOrganization: async (id) => {
    try {
      return await axios.delete(`/targets/organization/delete/${id}`)
    } catch (err) {
      errorHandler(err)
    }
  },
}
