import styled from 'styled-components'
import { IconButton, Tooltip } from '@material-ui/core'
import {
  EditOutlined,
  DeleteOutlined,
  NoteAddOutlined,
  PostAddOutlined,
  PublishedWithChangesOutlined,
  DriveFileMoveOutlined,
  AddCircleOutline,
  FolderOutlined,
  MoreHorizOutlined,
  ExpandMore,
  ContentCutOutlined,
  ClearOutlined,
  NoteOutlined,
  DownloadOutlined,
  NorthEastOutlined,
  CreateNewFolderOutlined,
} from '@mui/icons-material'

export const IconButtons = ({ handleChange, toolTipValue, iconType }) => (
  <Tooltip title={toolTipValue}>
    <Icon onClick={handleChange}>
      {
        {
          delete: <DeleteOutlined />,
          edit: <EditOutlined />,
          viewFolders: <FolderOutlined />,
          status: <PublishedWithChangesOutlined />,
          addToFolder: <DriveFileMoveOutlined />,
          newFolder: <CreateNewFolderOutlined />,
          newBullet: <AddCircleOutline />,
          newBrief: <NoteAddOutlined />,
          addBullet: <PostAddOutlined />,
          more: <MoreHorizOutlined />,
          expand: <ExpandMore />,
          copy: <ContentCutOutlined />,
          clearSelected: <ClearOutlined />,
          folderOpen: <NoteOutlined />,
          linkout: <NorthEastOutlined style={{ color: '#00000065' }} />,
          download: <DownloadOutlined style={{ color: '#00000065' }} />,
        }[iconType]
      }
    </Icon>
  </Tooltip>
)

const Icon = styled(IconButton)`
  width: 5px;
  height: 5px;
`
