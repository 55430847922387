import styled from 'styled-components'
import { isEmpty, isNumber } from 'lodash'
import { Typography, Link } from '@material-ui/core'
import moment from 'moment'

export const Citations = ({ bullet, isModal }) => {
  const {
    bullet_cite_source,
    bullet_cite_type,
    record_source,
    record_type,
    record_number,
    network_name,
    network_user,
    amendment_number,
    bill_number,
    bullet_type,
    bullet_cite_author,
    leg_vote_number,
    leg_action,
  } = bullet
  return (
    <>
      {['CLIP', 'GENERIC'].includes(bullet_type) && (
        <Row isModal={isModal}>
          <Typography style={{ textTransform: 'capitalize' }}>
            {!isEmpty(bullet_cite_source) && `${bullet_cite_source}`}
            {!isEmpty(bullet_cite_author) && `, ${bullet_cite_author}`}
            {!isEmpty(bullet_cite_type) &&
              `, ${bullet_cite_type
                .toLowerCase()
                .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())
                .replace(/_/gm, bullet_cite_type === 'OP_ED' ? '-' : ' ')}`}
            <DateLinks bullet={bullet} isModal={isModal} />
          </Typography>
        </Row>
      )}
      {['PUBLIC_RECORD', 'SOCIAL_MEDIA'].includes(bullet_type) && (
        <Row isModal={isModal}>
          <Typography>
            {bullet_type === 'PUBLIC_RECORD' && (
              <span>
                {!isEmpty(record_source) && `${record_source}`}
                {!isEmpty(record_type) && `, ${record_type}`}
                {!isEmpty(record_number) && ` #${record_number}`}
              </span>
            )}
            {bullet_type === 'SOCIAL_MEDIA' &&
              !isEmpty(network_name) &&
              `${network_name}`}
            {!isEmpty(network_user) &&
              `${
                ['twitter', 'instagram'].includes(network_name)
                  ? `, @${network_user}`
                  : `, ${network_user}`
              }`}
            <DateLinks bullet={bullet} isModal={isModal} />
          </Typography>
        </Row>
      )}
      {['VOTE'].includes(bullet_type) && (
        <Row isModal={isModal}>
          <Typography>
            {!isEmpty(bill_number) && `${bill_number}`}
            {!isEmpty(amendment_number) && `, ${amendment_number}`}
            {leg_action === 'VOTE' && isNumber(leg_vote_number)
              ? `, Vote ${leg_vote_number}`
              : ', Vote'}
            {leg_action === 'SPONSORSHIP' && `, Sponsorship`}
            <DateLinks bullet={bullet} isModal={isModal} />
          </Typography>
        </Row>
      )}
    </>
  )
}

const DateLinks = ({ bullet, isModal }) => {
  const { bullet_cite_date, bullet_access_date, bullet_cite_url } = bullet
  return (
    <span>
      {!isEmpty(bullet_cite_date) && (
        <span>
          {', '}
          {!isEmpty(bullet_cite_url) && !isModal ? (
            <Link href={bullet_cite_url} rel='noreferrer' target='_blank'>
              {moment(bullet_cite_date).format('MM/DD/YYYY')}
            </Link>
          ) : (
            moment(bullet_cite_date).format('MM/DD/YYYY')
          )}
        </span>
      )}

      {!isEmpty(bullet_access_date) && (
        <span>
          {', '}
          Accessed:{' '}
          {!isEmpty(bullet_cite_url) && !isModal ? (
            <Link href={bullet_cite_url} rel='noreferrer' target='_blank'>
              {moment(bullet_access_date).format('MM/DD/YYYY')}
            </Link>
          ) : (
            moment(bullet_access_date).format('MM/DD/YYYY')
          )}
        </span>
      )}
    </span>
  )
}
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.isModal ? '100%' : '94%')};
`
