import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { Citations } from '../bullets/Citations'

export const Delete = () => {
  const itemsToDelete = useSelector((state) => state.bullet.itemsToDelete)

  return (
    <div>
      <Typography variant={'h5'} style={{ margin: '1rem 0 1rem 0' }}>
        {itemsToDelete.length > 0 && (
          <Row>
            <Left>Header</Left>
            <Center>
              <CiteRow>
                <Citation>Source</Citation>
              </CiteRow>
            </Center>
            <Right>Status</Right>
          </Row>
        )}
      </Typography>

      {itemsToDelete.map((entry) => (
        <Typography>
          {entry.type === 'bullet' ? (
            <Row key={entry.id}>
              <Left>{entry.header}</Left>
              <Center>
                <CiteRow>
                  <Citation>
                    <Citations bullet={entry.citation_record} isModal={true} />
                  </Citation>
                </CiteRow>
              </Center>
              <Right>
                {entry.record_status.charAt(0).toUpperCase() +
                  entry.record_status.slice(1).toLowerCase().replace('_', ' ')}
              </Right>
            </Row>
          ) : (
            <Row key={entry.id}>
              <BriefLeft>{entry.header}</BriefLeft>
              <Right>
                {entry.record_status.charAt(0).toUpperCase() +
                  entry.record_status.slice(1).toLowerCase().replace('_', ' ')}
              </Right>
            </Row>
          )}
        </Typography>
      ))}
      <Typography variant={'caption'} style={{ margin: '1rem 0 1rem 0' }}>
        * Deleting a brief will also delete all it's attached bullets
      </Typography>
    </div>
  )
}
const Row = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid lightgrey 1px;
  margin-bottom: 1rem;
`
const CiteRow = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Citation = styled.span`
  width: 100%;
  text-align: left;
`
const Left = styled.span`
  width: 55%;
`
const Center = styled.span`
  width: 35%;
`
const BriefLeft = styled.span`
  width: 90%;
`
const Right = styled.span`
  align-self: flex-end;
`
