import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Checkbox, Button, makeStyles } from '@material-ui/core'

import {
  removeSelectedTarget,
  removeSelectedFolder,
  removeSelectedType,
  removeSelectedBulletType,
  removeSelectedStatuses,
} from '../slices/bulletSlice'

const SelectedFilter = ({ name, value, type }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(true)

  const handleRemove = () => {
    setChecked(!checked)
    switch (type) {
      case 'target':
        dispatch(removeSelectedTarget(value.id))
        break
      case 'folder':
        dispatch(removeSelectedFolder(value.id))
        break
      case 'data_type':
        dispatch(removeSelectedType(value))
        break
      case 'bullet_type':
        dispatch(removeSelectedBulletType(value))
        break
      case 'record_status':
        dispatch(removeSelectedStatuses(value))
        break
      default:
        return
    }
  }

  return (
    <div className={classes.hundo}>
      <Button onClick={handleRemove} className={classes.hundo}>
        <Checkbox
          color='primary'
          checked={checked}
          disableRipple
          disableFocusRipple
          disableTouchRipple
        />
        {name}
      </Button>
    </div>
  )
}

export default SelectedFilter

const useStyles = makeStyles((theme) => ({
  hundo: {
    width: '100%',
  },
}))
