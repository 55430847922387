import { useSelector } from 'react-redux'
import { useState, useRef, useEffect } from 'react'

import {
  Link as MaterialLink,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@material-ui/core'

import { api } from '../../api/api'

export const DocumentLinks = () => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (e) => {
    setOpen(false)
  }

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const user = useSelector((state) => state.user.user)
  const [reportLinks, setReportLinks] = useState([])

  useEffect(() => {
    getLinks()
  }, [user])

  const getLinks = async () => {
    try {
      const links = await api.getLinksByUser()
      setReportLinks(links)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <>
      {reportLinks.length === 0 && (
        <>
          <MaterialLink
            color='inherit'
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}
          >
            Documents
          </MaterialLink>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuItem disabled>None Assigned</MenuItem>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
      {reportLinks.length === 1 && (
        <MaterialLink
          onClick={() => window.open(reportLinks[0].hyperlink, '_blank')}
          color='inherit'
        >
          Documents
        </MaterialLink>
      )}
      {reportLinks.length > 1 && (
        <>
          <MaterialLink
            color='inherit'
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}
          >
            Documents
          </MaterialLink>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id='menu-list-grow'
                      onKeyDown={handleListKeyDown}
                    >
                      {reportLinks.map((link) => {
                        return (
                          <MenuItem
                            onClick={(e) => {
                              window.open(link.hyperlink, '_blank')
                              setOpen(false)
                            }}
                            id={link.drive_id}
                            key={link.drive_id}
                          >
                            {link.drive_name}
                          </MenuItem>
                        )
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </>
  )
}
