import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import {
  changeSelectedBriefs,
  changeSelectedBullets,
  clearItemsChangeStatus,
  clearItemsToDelete,
  clearStatus,
  setFolderRefetch,
  setSelectedFolder,
  triggerRefreshList,
} from '../slices/bulletSlice'

import {
  setModalOpen,
  setModalState,
  setRightOpen,
  setRightState,
} from '../slices/navSlice'
import { api } from '../../api/api'

export const ModalButtons = ({ modalState }) => {
  const folderRefetch = useSelector((state) => state.bullet.folderRefetch)
  const itemsToDelete = useSelector((state) => state.bullet.itemsToDelete)
  const selectedBullets = useSelector((state) => state.bullet.selected_bullets)
  const selectedBriefs = useSelector((state) => state.bullet.selected_briefs)
  const selectedFolder = useSelector((state) => state.bullet.selected_folder)
  const itemsChangeStatus = useSelector(
    (state) => state.bullet.itemsChangeStatus
  )

  const statusToSet = useSelector((state) => state.bullet.status_to_set)

  const dispatch = useDispatch()
  //button handlers for all actions
  const handleClear = () => {
    if (modalState === 'delete') clearDelete()
    if (modalState === 'changeStatus') clearChangeStatus()
    closeModal()
  }

  const handleConfirm = () => {
    if (modalState === 'delete') handleDelete()
    if (modalState === 'changeStatus') handleStatus()
    if (modalState === 'unsaved') clearSelections()
    closeRight()
    closeModal()
    if (modalState === 'addToFolder') handleAddToFolder()
  }

  const handleAddToFolder = async () => {
    try {
      await api.updateFolder(selectedFolder.folder_id, {
        briefs: selectedBriefs.map((briefs) => briefs.brief_id),
        bullets: selectedBullets.map((bullets) => bullets.bullet_id),
      })
      dispatch(setFolderRefetch(!folderRefetch))
      clearSelections()
      dispatch(setSelectedFolder(null))
      closeRight()
    } catch (err) {
      console.error(err)
    }
  }

  const closeModal = () => {
    dispatch(setModalState(null))
    dispatch(setModalOpen(false))
  }
  const closeRight = () => {
    dispatch(setRightOpen(false))
    dispatch(setRightState(null))
  }
  const clearSelections = () => {
    dispatch(changeSelectedBriefs([]))
    dispatch(changeSelectedBullets([]))
  }
  const clearDelete = () => {
    dispatch(clearItemsToDelete())
  }

  const handleDelete = () => {
    const toBeDeleted = { delete_bullets: [], delete_briefs: [] }
    itemsToDelete.forEach((item) => {
      if (item.type === 'bullet') toBeDeleted.delete_bullets.push(item.id)
      if (item.type === 'brief') toBeDeleted.delete_briefs.push(item.id)
    })
    try {
      api.deleteItems(toBeDeleted)
      clearDelete()
      clearSelections()
      dispatch(triggerRefreshList())
    } catch (err) {
      throw err
    }
  }

  //change status action
  const clearChangeStatus = () => {
    dispatch(clearStatus())
    dispatch(clearItemsChangeStatus())
  }

  const handleStatus = () => {
    const change_bullets = []
    const change_briefs = []
    itemsChangeStatus.forEach((item) => {
      if (item.type === 'bullet') change_bullets.push(item.id)
      if (item.type === 'brief') change_briefs.push(item.id)
    })
    change_bullets.forEach((bullet) => {
      api.updateBullet({
        record_status: statusToSet,
        bullet_id: bullet,
      })
    })
    change_briefs.forEach((brief) => {
      api.updateBrief({
        body: { record_status: statusToSet },
        id: brief,
      })
    })
    clearChangeStatus()
    dispatch(triggerRefreshList())
  }

  return (
    <ButtonRow>
      <StyledButton
        onClick={handleConfirm}
        variant='contained'
        disabled={
          modalState === 'changeStatus' &&
          [null, undefined, ''].includes(statusToSet)
            ? true
            : false
        }
      >
        {{
          changeStatus: 'Change',
          bulletSave: 'Okay',
          briefSave: 'Okay',
          bulletCreated: 'Okay',
          briefCreated: 'Okay',
          bulletCancel: 'Discard',
          briefCancel: 'Discard',
          delete: 'Delete',
          addToFolder: 'Continue',
          unsaved: 'Discard',
        }[modalState] || 'Error'}
      </StyledButton>
      <StyledButton onClick={handleClear} variant='contained'>
        {['changeStatus', 'delete', 'addToFolder'].includes(modalState)
          ? 'Cancel'
          : [
              'bulletSave',
              'bulletCreated',
              'briefSave',
              'briefCreated',
              'bulletDiscard',
              'briefDiscard',
              'delete',
              'addToFolder',
              'unsaved',
            ].includes(modalState)
          ? 'Continue Editing'
          : 'Error'}
      </StyledButton>
    </ButtonRow>
  )
}

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
const StyledButton = styled(Button)`
  margin: 24px 0 8px 0;
`
