import styled from 'styled-components'
import { Box, Typography } from '@material-ui/core'

export const WizardContainer = styled(Box)`
  padding: 2rem;
  width: 50%;
  margin: 60px auto 0;
  text-align: center;
  border: 1px solid gray;
  position: relative;
`

export const WList = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 5px 0;
  background: gainsboro;
  border: 1px solid black;
`

export const WListItem = styled.li`
  padding: 8px 1rem;
  text-align: left;
  position: relative;

  &:hover {
    background: lightgray;
    cursor: pointer;
  }
`

export const WListItemActions = styled.div`
  position: absolute;
  right: 1rem;
  top: 4px;

  svg {
    font-size: 1.5rem;
  }
`

export const WTitle = styled(Typography)`
  padding: 0 3rem;
  font-family: OpenSans, sans-serif;
  font-size: 2rem;
  font-weight: bold;
`

export const WDescription = styled(Typography)`
  padding: 0 2rem;
  font-family: OpenSans, sans-serif;
  font-size: 1.25rem;
`

export const WContent = styled.div`
  padding: 0;
`

export const WActions = styled.div`
  margin-top: 50px;

  button {
    min-width: 33%;
    background: purple;
    color: white;
    font-size: 1rem;
  }

  button:last-child {
    margin-left: 20px;
  }
`
