import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { IconButtons } from '../buttons/Buttons'

export const Links = ({ bullet }) => {
  const { bullet_url_2, bullet_url_3, bullet_file } = bullet

  const handleClick = (url, type) => {
    if (type === 'download') {
      window.open(url, 'download')
    } else {
      window.open(url)
    }
  }

  return (
    <IconContainer>
      {!isEmpty(bullet_file) && (
        <IconButtons
          iconType="download"
          toolTipValue="Download Attachment"
          handleChange={() => handleClick(bullet_file, 'download')}
        />
      )}
      {!isEmpty(bullet_url_2) && (
        <IconButtons
          iconType="linkout"
          toolTipValue="Open"
          handleChange={() => handleClick(bullet_url_2, 'new tab')}
        />
      )}
      {!isEmpty(bullet_url_3) && (
        <IconButtons
          iconType="linkout"
          toolTipValue="Open"
          handleChange={() => handleClick(bullet_url_3, 'new tab')}
        />
      )}
    </IconContainer>
  )
}

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 6%;
  margin-right: -0.5rem;
`
