import styled from 'styled-components'
import { useState } from 'react'
import { useEffect } from 'react'
import { BallTriangle } from 'react-loader-spinner'
import { Box, Grid, Typography } from '@material-ui/core'
import { api } from '../../api/api'
import { MomentDate } from '../util/MomentDate'

import { TagsPieChart } from './TagsPieChart'

// TODO: Find better home for presenter logic
const mapContentToString = {
  Biographical: 'biographical factual claims',
  Research: 'research factual claims',
  Sources: 'unique sources',
  Themes: 'tags',
}

export const TargetStatistics = (props) => {
  const { target_id } = props
  const [loading, setLoading] = useState(true)
  const [statistics, setStatistics] = useState({})

  const getTargetStatistics = async (id) => {
    try {
      const response = await api.getTargetStatistics({ id: id })
      setStatistics(response.data)
      console.log(response.data)
      setLoading(false)
    } catch (err) {
      throw err
    }
  }

  useEffect(() => {
    setLoading(true)
    getTargetStatistics(target_id)
  }, [target_id])

  if (loading) {
    return (
      <BallTriangle
        height='200'
        width='200'
        color='grey'
        arialLabel='loading-indicator'
      />
    )
  }

  const { timestamps, counts, theme_counts } = statistics

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <StyledBox>
          <Typography variant='h6'>Themes</Typography>
          <TagsPieChart tally={theme_counts} />
        </StyledBox>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid item xs={12} md={12}>
          <StyledBox>
            <Typography variant='h6'>Content Statistics</Typography>
            <ul>
              {Object.entries(counts).map(([resource, count]) => (
                <li key={resource}>
                  {count} {mapContentToString[resource]}
                </li>
              ))}
            </ul>
          </StyledBox>
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledBox>
            <Typography variant='h6'>Timestamps</Typography>
            <ul>
              {Object.entries(timestamps).map(([resource, timestamp]) => (
                <li key={resource}>
                  Last {resource} update:&nbsp;
                  <MomentDate date={timestamp} type='timestamp' />
                </li>
              ))}
            </ul>
          </StyledBox>
        </Grid>
      </Grid>
    </Grid>
  )
}

const StyledBox = styled(Box)`
  background: #ddd;

  margin: 1rem;
  padding: 1rem;
`
