import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Edit, AddCircle } from '@material-ui/icons'
import { Typography, Button } from '@material-ui/core'

import { api } from '../../../api/api'
import { FormWithTracking } from './FormWithTracking'
import {
  WActions,
  WContent,
  WTitle,
  WDescription,
  WList,
  WListItem,
  WListItemActions,
} from '../../wizard'
import { ORGANIZATION_FIELDS } from '../../../utils/forms'
import { addOrganization, updateOrganization } from '../../slices/targetSlice'
import {
  ORGANIZATION_ASSOCIATION_CHOICES,
  ORGANIZATION_TYPE_CHOICES,
} from '../../../utils/choice_enums'
import { MomentRange } from '../../util/MomentRange'

const OrganizationLine = ({ organization }) => (
  <>
    {organization.organization_name},{' '}
    {ORGANIZATION_TYPE_CHOICES[organization.organization_type]}{' '}
    {
      ORGANIZATION_ASSOCIATION_CHOICES[
        organization.organization_association_type
      ]
    }
    &nbsp;
    <MomentRange
      fromDate={organization.organization_start_year}
      toDate={organization.organization_end_year}
      parser='YYYY'
    />
  </>
)

export const WOrganizationsTab = ({ onSubmitSuccess, onExit }) => {
  let dispatch = useDispatch()
  const { targetId } = useParams()

  const organizations = useSelector((state) => state.target.organizations)
  const [organization, setOrganization] = useState()

  const handleAdd = () => {
    setOrganization({})
  }

  const handleSubmit = async (body) => {
    if (organization.organization_id) {
      const response = await api.updateOrganization(
        organization.organization_id,
        body
      )
      dispatch(updateOrganization(response.data))
    } else {
      const response = await api.createOrganization({
        ...body,
        target_id: targetId,
      })
      dispatch(addOrganization(response.data))
    }

    setOrganization(null)
  }

  return (
    <>
      <WTitle>
        Add any other organizations and affiliations that are relevant here.{' '}
      </WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>TKTKTK</WDescription>
      <WContent style={{ paddingBottom: '2rem' }}>
        <WList style={{ width: '80%' }}>
          <WListItem onClick={handleAdd}>
            Add Another
            <WListItemActions>
              <AddCircle />
            </WListItemActions>
          </WListItem>
          {organizations.map((organization) => (
            <WListItem
              key={organization.organization_id}
              onClick={() => setOrganization(organization)}
            >
              <OrganizationLine organization={organization} />
              <WListItemActions>
                <Edit />
              </WListItemActions>
            </WListItem>
          ))}
        </WList>
      </WContent>

      {organization && (
        <>
          <Typography variant='h5' style={{ paddingBottom: '1rem' }}>
            {organization.organization_id ? (
              <>
                Editing <OrganizationLine organization={organization} />
              </>
            ) : (
              'Add New Organization'
            )}
          </Typography>
          <FormWithTracking
            objectId={organization.organization_id}
            relationType='many'
            initialAttributes={organization}
            fields={ORGANIZATION_FIELDS}
            onSubmit={handleSubmit}
          />
        </>
      )}

      <WActions>
        <Button variant='contained' onClick={onSubmitSuccess}>
          CONTINUE
        </Button>
        <Button variant='contained' onClick={onExit}>
          EXIT
        </Button>
      </WActions>
    </>
  )
}
