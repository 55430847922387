import { useSelector } from 'react-redux'

import clsx from 'clsx'
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Divider,
} from '@material-ui/core'

import ReportList from './ReportList'
import { useEffect, useState } from 'react'
import { api } from '../../api/api'

const drawerWidth = 250

const ReportsPage = () => {
  const classes = useStyles()
  const open = useSelector((state) => state.nav.leftOpen)
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const getReports = async () => {
    try {
      const reports = api.getReports()
      setData(reports.data)
    } catch (err) {
      throw err
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    getReports()
  }, [])
  const ReportsLoading = ReportList

  return (
    <>
      <Container
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        maxWidth="md"
      >
        <Typography variant="h6" className={classes.header}>
          <b>Reports page placeholder</b>
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={3}>
          <ReportsLoading isLoading={isLoading} reports={data} />
        </Grid>
      </Container>
    </>
  )
}

export default ReportsPage

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['padding', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create(['padding', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingLeft: drawerWidth,
  },
  grid2: {
    width: '100%',
    flexDirection: 'row',
    margin: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    paddingBottom: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    marginBottom: '12px',
  },
}))
