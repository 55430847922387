import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Edit,
  AddCircle,
  ThumbDownOutlined,
  ThumbUpOutlined,
  Delete,
} from '@material-ui/icons'
import { Typography, Button, CircularProgress } from '@material-ui/core'

import { api } from '../../../api/api'
import { FormWithTracking } from './FormWithTracking'
import {
  WActions,
  WContent,
  WTitle,
  WDescription,
  WList,
  WListItem,
  WListItemActions,
} from '../../wizard'
import {} from '../../../utils/forms'
import {
  ACCOUNT_SOURCE_CHOICES,
  ACCOUNT_TYPE_CHOICES,
} from '../../../utils/choice_enums'
import {
  addLink,
  updateLink,
  deleteLink,
  fetchTargetDetailsBackground,
} from '../../slices/targetSlice'
import { LINK_FIELDS } from '../../../utils/forms'
import { setDisabled, fetchTrackingsByTarget } from '../../slices/wizardSlice'

const LinkLine = ({ link }) => (
  <>
    {ACCOUNT_SOURCE_CHOICES[link.account_source]},{' '}
    {ACCOUNT_TYPE_CHOICES[link.account_type]} (
    {link.account_source === 'TWITTER' && '@'}
    {link.account})
  </>
)

export const WLinksTab = ({ onSubmitSuccess, onExit }) => {
  let dispatch = useDispatch()
  const { targetId } = useParams()

  const links = useSelector((state) => state.target.links)
  const [link, setLink] = useState()
  const [step, setStep] = useState(0)
  const [retrieved, setRetrieved] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleAdd = () => {
    setLink({})
  }

  const handleDeleteClick = async (event, link_id) => {
    event.stopPropagation()

    try {
      await api.deleteLink(link_id)
      dispatch(deleteLink({ link_id: link_id }))
    } catch (err) {
      console.error(err)
      alert(`There was an error deleting link: ${link_id}`)
    }

    setLink(null)
  }

  const handleSave = async (body) => {
    if (link.link_id) {
      const response = await api.updateLink(link.link_id, body)
      dispatch(updateLink(response.data))
    } else {
      const response = await api.createLink({
        ...body,
        target_id: targetId,
      })
      dispatch(addLink(response.data))
    }

    setLink(null)
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      dispatch(setDisabled(true))
      setStep(1)
      const response = await api.getDataFromLinks(targetId)
      setRetrieved(response.data)

      // Update the target slice
      dispatch(fetchTargetDetailsBackground(targetId))
      dispatch(fetchTrackingsByTarget(targetId))
    } catch (err) {
      // N.B. 422 Unprocessable Entity
      setRetrieved({
        message: err.message,
        source_status_dict: err.source_status_dict,
      })
    }

    setIsSubmitting(false)
    dispatch(setDisabled(false))
  }

  const retrieveFailedFor = (link) => {
    return (
      retrieved.source_status_dict[link.account_source] &&
      retrieved.source_status_dict[link.account_source][link.account] ===
        'FAILED'
    )
  }

  return (
    <>
      {step === 0 && (
        <>
          <WTitle>Add accounts, handles, websites.</WTitle>
          <WDescription style={{ paddingBottom: '1rem' }}>
            Please add as many accounts for each source as you can (e.g.,
            personal and campaign Twitter accounts). For supported sources,
            we'll retrieve information about the target.
          </WDescription>
          <WContent style={{ paddingBottom: '2rem' }}>
            <WList style={{ width: '80%' }}>
              <WListItem onClick={handleAdd}>
                Add Another
                <WListItemActions>
                  <AddCircle />
                </WListItemActions>
              </WListItem>
              {links.map((link) => (
                <WListItem key={link.link_id} onClick={() => setLink(link)}>
                  <LinkLine link={link} />
                  <WListItemActions>
                    <Edit />
                    <Delete
                      onClick={(e) => handleDeleteClick(e, link.link_id)}
                    />
                  </WListItemActions>
                </WListItem>
              ))}
            </WList>
          </WContent>

          {link && (
            <>
              <Typography variant='h5' style={{ paddingBottom: '1rem' }}>
                {link.link_id ? (
                  <>
                    Editing <LinkLine link={link}></LinkLine>
                  </>
                ) : (
                  <>Add New Data Link</>
                )}
              </Typography>
              <FormWithTracking
                objectId={link.link_id}
                relationType='many'
                initialAttributes={link}
                fields={LINK_FIELDS}
                onSubmit={handleSave}
              />
            </>
          )}

          <WActions>
            <Button
              variant='contained'
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              SUBMIT
            </Button>
            <Button
              variant='contained'
              disabled={isSubmitting}
              onClick={onExit}
            >
              EXIT
            </Button>
          </WActions>
        </>
      )}

      {step === 1 && (
        <>
          <WTitle>Retrieving Information from Accounts</WTitle>
          <WDescription style={{ paddingBottom: '1rem' }}>
            Please wait while data is retrieved from the links you provided.
            This process could take a few minutes or longer. This screen will
            redirect when the process is complete.
          </WDescription>

          <WContent style={{ paddingBottom: '2rem' }}>
            <WList style={{ width: '80%' }}>
              {links.map((link) => (
                <WListItem key={link.link_id}>
                  <LinkLine link={link} />
                  <WListItemActions>
                    {isSubmitting ? (
                      <CircularProgress
                        style={{ width: 24, height: 24 }}
                      ></CircularProgress>
                    ) : (
                      <>
                        {retrieveFailedFor(link) ? (
                          <ThumbDownOutlined></ThumbDownOutlined>
                        ) : (
                          <ThumbUpOutlined></ThumbUpOutlined>
                        )}
                      </>
                    )}
                  </WListItemActions>
                </WListItem>
              ))}
            </WList>
          </WContent>

          {retrieved && retrieved.message && (
            <WDescription
              style={{
                color: 'gray',
                paddingBottom: '1rem',
              }}
            >
              {retrieved.message}
            </WDescription>
          )}

          <WActions>
            <Button
              variant='contained'
              disabled={isSubmitting}
              onClick={onSubmitSuccess}
            >
              CONTINUE
            </Button>
            <Button
              variant='contained'
              disabled={isSubmitting}
              onClick={onExit}
            >
              EXIT
            </Button>
          </WActions>
        </>
      )}
    </>
  )
}
