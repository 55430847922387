import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import clsx from 'clsx'
import {
  ClickAwayListener,
  Grow,
  Paper,
  MenuItem,
  MenuList,
  Button,
  Popper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import {
  changeTarget,
  changeTargetID,
  changeLocalTarget,
  changeLocalTargetID,
  addSelectedTarget,
} from '../slices/bulletSlice'

import { setTargetList } from '../slices/usersSlice'
import { useNavigate } from 'react-router-dom'
import { api } from '../../api/api'

const TargetPicker = ({
  setFormErrors,
  formErrors,
  isGlobal = true,
  isPane = false,
  formError = false,
  errorField,
}) => {
  const [localTarget, setLocalTarget] = useState(null)
  const selectedTargets = useSelector((state) => state.bullet.selected_targets)
  const targetID = useSelector((state) => state.bullet.targetID)
  const localID = useSelector((state) => state.bullet.localTargetID)
  const navigate = useNavigate()
  const [data, setData] = useState()

  const getTargets = async () => {
    try {
      const targets = await api.getTargets()
      setData(targets.data)
    } catch (err) {
      navigate('/login')
    }
  }

  useEffect(() => {
    getTargets()
  }, [])

  useEffect(() => {
    if (data) {
      dispatch(setTargetList(data))
      for (let i = 0; i < data.length; i++) {
        if (isGlobal) {
          if (targetID === data[i].target_id) {
            dispatch(changeTarget(data[i].target_full_name))
          }
        } else {
          if (localID === data[i].target_id) {
            setLocalTarget(data[i].target_full_name)
          }
        }
      }
    }
  }, [targetID, data])

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const targo = useSelector((state) => state.bullet.target)
  const dispatch = useDispatch()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleMenuItemClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    if (formError) {
      setFormErrors({
        ...formErrors,
        [errorField]: false,
      })
    }

    if (isGlobal) {
      dispatch(changeTarget(event.target.innerText))
      dispatch(changeTargetID(event.target.id))
    } else {
      dispatch(changeLocalTarget(event.target.innerText))
      dispatch(changeLocalTargetID(event.target.id))
      setLocalTarget(event.target.innerText)
    }

    if (isPane && !selectedTargets.some((e) => e.id === event.target.id)) {
      dispatch(
        addSelectedTarget({ name: event.target.innerText, id: event.target.id })
      )
    }
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.maxo}>
      <Button
        className={clsx(classes.targetSelect, {
          [classes.errorButton]: formError,
        })}
        ref={anchorRef}
        variant={isPane ? 'text' : 'outlined'}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <AccountCircleIcon fontSize='medium' />
        <Typography>
          {!isPane &&
            (isGlobal && targo === null ? 'Select Target' : isGlobal && targo)}
          {!isPane &&
            (!isGlobal && localTarget === null
              ? 'Select Target'
              : !isGlobal && localTarget)}
          {isPane && 'Target'}
        </Typography>
        <KeyboardArrowDownIcon />
      </Button>
      <Popper
        className={classes.highIndex}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              style={{
                width: 'auto',
                maxHeight: '400px',
                overflow: 'scroll',
                zIndex: 9999,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                >
                  {data &&
                    data.map((target) => {
                      return (
                        <MenuItem
                          onClick={handleMenuItemClose}
                          id={target.target_id}
                          key={target.target_id}
                        >
                          {target.target_full_name}
                        </MenuItem>
                      )
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default TargetPicker

const useStyles = makeStyles((theme) => ({
  targetSelect: {
    justifyContent: 'space-between',
    width: '100%',
    textTransform: 'none',
    marginLeft: '-.75rem',
  },
  errorButton: {
    border: '1px solid red',
  },
  highIndex: {
    zIndex: 5,
  },
  maxo: {
    width: '250px',
    display: 'flex',
    maxHeight: '50px',
  },
}))
