import { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

export const Tags = ({ handleChange, existingTags, currentTags }) => {
  const [tags, setTags] = useState([...currentTags])
  useEffect(() => {
    handleChange('bullet_theme', tags)
  }, [tags])
  const handleKeyDown = (event) => {
    switch (event.key) {
      case ',':
      case ' ': {
        event.preventDefault()
        event.stopPropagation()
        if (event.target.value.length > 0) {
          setTags([...tags, event.target.value])
        }
        break
      }
      default:
    }
  }
  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-filled"
      value={tags}
      options={
        existingTags !== undefined
          ? existingTags.map((option) => option.theme_name)
          : []
      }
      freeSolo
      filterSelectedOptions
      onChange={(e, newTag) => setTags(newTag)}
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown
        return (
          <TextField
            {...params}
            label="Tags"
            placeholder="Search or add a tag"
          />
        )
      }}
    />
  )
}
