import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Editor from './EditorClass'
import { api } from '../../api/api'
import { triggerRefreshList } from '../slices/bulletSlice'

export const Clip = ({ url, bullet_id }) => {
  const dispatch = useDispatch()

  const getMetadata = (metadata) => {
    updateClip(metadata)
  }

  const updateClip = async (metadata) => {
    try {
      await api.trimClip(bullet_id, metadata.trim_times[0])
      dispatch(triggerRefreshList())
    } catch (err) {
      throw err
    }
  }

  const [state, updateState] = useState({
    isUpload: true,
    videoUrl: url,
    isDarkMode: false,
    timings: [],
  })

  useEffect(() => {
    updateState((state) => ({ ...state, videoUrl: url }))
  }, [url])

  return (
    <Row>
      {state.videoUrl !== undefined && (
        <Editor
          videoUrl={url}
          saveVideo={getMetadata}
          timings={state.timings}
          updateState={(values) =>
            updateState((state) => ({ ...state, ...values }))
          }
        />
      )}
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`
