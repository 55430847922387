import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  hasErrors: false,
  user: null,
  token: { access: null, refresh: null },
  client: true,
  targets: [],
  role: 'CLIENT',
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    getUser: (state) => {
      state.loading = true
    },
    getUserFailure: (state) => {
      state.loading = false
      state.hasErrors = true
    },
    setUser: (state, { payload }) => {
      state.user = payload
    },
    setTargetList: (state, { payload }) => {
      state.targets = payload
    },
    setIsClient: (state, { payload }) => {
      state.client = payload
    },
    setRole: (state, { payload }) => {
      state.role = payload
    },
  },
})

export const {
  getUser,
  getUserFailure,
  setCredentials,
  setTargetList,
  setUser,
  setIsClient,
  setRole,
} = userSlice.actions

export default userSlice.reducer
