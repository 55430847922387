import axios from 'axios'
import { user } from './user'
import { bullets } from './bullets'
import { folders } from './folders'
import { reports } from './reports'
import { targets } from './targets'
import { addresses } from './addresses'
import { links } from './links'
import { family } from './family'
import { education } from './education'
import { elections } from './elections'
import { governings } from './governings'
import { campaign_finances } from './campaign_finances'
import { organizations } from './organizations'
import { candidacies } from './candidacies'
import { wizards } from './wizards'
const { REACT_APP_BASE_BACKEND_URL } = process.env

axios.defaults.withCredentials = true
axios.defaults.baseURL = REACT_APP_BASE_BACKEND_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'

console.log("In src/api/api.js");
console.log("axios.defaults.baseURL:", axios.defaults.baseURL);

export const api = {
  ...user,
  ...bullets,
  ...targets,
  ...folders,
  ...reports,
  ...addresses,
  ...links,
  ...family,
  ...education,
  ...elections,
  ...governings,
  ...campaign_finances,
  ...organizations,
  ...candidacies,
  ...wizards,
}
