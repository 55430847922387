import styled from 'styled-components'
import { Typography } from '@material-ui/core'

import placeholderImage from '../../static/img/placeholder-photo.png'

export const TargetProfile = (props) => {
  const {
    target_photo,
    target_title,
    target_full_name,
    target_party,
    home_address,
  } = props.target

  const avatarUrl = target_photo ? target_photo : placeholderImage
  const homeState = home_address[0] && home_address[0].address_state
  return (
    <StyledWrapper>
      <StyledProfileImageWrapper>
        <StyledProfileImage src={avatarUrl} alt={target_full_name} />
      </StyledProfileImageWrapper>
      <StyledProfileSummary>
        <Typography variant='h5'>
          {target_title} {target_full_name}
        </Typography>
        <StyledList>
          <li>Party: {target_party}</li>
          <li>State: {homeState || 'None'}</li>
        </StyledList>
      </StyledProfileSummary>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
`

const StyledProfileImageWrapper = styled.div`
  width: 110px;
  height: 135px;
  background: white;
  border: 5px solid white;
`

const StyledProfileImage = styled.img`
  height: 100%;
  object-fit: contain;
`

const StyledProfileSummary = styled.div`
  margin-left: 1rem;
`

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  margin-top: 10px;
  padding: 0;
`
