import axios from 'axios'
import { errorHandler } from './errorHandler'

export const user = {
  getProfile: async (id) => {
    try {
      const res = await axios.get(`/profiles/?${id}`)
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
  getUser: async () => {
    try {
      const res = await axios.get(`/api/v1/profiles/me/`)
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
  logout: async () => {
    try {
      return await axios.post(`/api/v1/auth/logout/`)
    } catch (err) {
      throw err
    }
  },
}
