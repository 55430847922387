import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allThemes: [],
  userThemes: [],
  selectedThemes: [],
}

const themeSlice = createSlice({
  name: 'theme',
  initialState: initialState,
  reducers: {
    setAllThemes: (state, { payload }) => {
      state.allThemes = payload
    },
    setUserThemes: (state, { payload }) => {
      state.userThemes = payload
    },
    addSelectedTheme: (state, { payload }) => {
      state.selectedThemes.push(payload)
    },
    removeSelectedTheme: (state, { payload }) => {
      state.selectedThemes.splice(
        state.selectedThemes.findIndex((theme) => theme === payload),
        1
      )
    },
    setSelectedThemes: (state, { payload }) => {
      state.selectedThemes = payload
    },
  },
})

export const {
  setUserThemes,
  setAllThemes,
  addSelectedTheme,
  removeSelectedTheme,
  setSelectedThemes,
} = themeSlice.actions
export default themeSlice.reducer
