import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Edit, AddCircle } from '@material-ui/icons'
import { Typography, Button } from '@material-ui/core'

import { api } from '../../../api/api'
import { FormWithTracking } from './FormWithTracking'
import {
  WActions,
  WContent,
  WTitle,
  WDescription,
  WList,
  WListItem,
  WListItemActions,
} from '../../wizard'
import { ADDRESS_FIELDS } from '../../../utils/forms'
import { addAddress, updateAddress } from '../../slices/targetSlice'
import { MomentRange } from '../../util/MomentRange'
import { AddressPresenter } from '../../../presenters/address_presenter'

const AddressLine = ({ address }) => {
  const presenter = new AddressPresenter(address)

  return (
    <>
      {presenter.addressLineOne}, {address.address_city},{' '}
      {address.address_state}
      &nbsp;
      <MomentRange
        fromDate={address.address_date_from}
        toDate={address.address_date_to}
      />
    </>
  )
}

export const WAddressesTab = ({ onSubmitSuccess, onExit }) => {
  let dispatch = useDispatch()
  const { targetId } = useParams()

  const addresses = useSelector((state) => state.target.addresses)
  const [address, setAddress] = useState()

  const handleAdd = () => {
    setAddress({})
  }

  const handleSubmit = async (body) => {
    if (address.address_id) {
      const response = await api.updateAddress(address.address_id, body)
      dispatch(updateAddress(response.data))
    } else {
      const response = await api.createAddress({
        ...body,
        target_id: targetId,
      })
      dispatch(addAddress(response.data))
    }

    setAddress(null)
  }

  return (
    <>
      <WTitle>
        Addresses associated to a target help us identify their assets,
        relationships, and more.
      </WTitle>
      <WDescription style={{ paddingBottom: '1rem' }}>TKTKTK</WDescription>
      <WContent style={{ paddingBottom: '2rem' }}>
        <WList style={{ width: '80%' }}>
          <WListItem onClick={handleAdd}>
            Add Another
            <WListItemActions>
              <AddCircle />
            </WListItemActions>
          </WListItem>
          {addresses.map((address) => (
            <WListItem
              key={address.address_id}
              onClick={() => setAddress(address)}
            >
              <AddressLine address={address} />
              <WListItemActions>
                <Edit />
              </WListItemActions>
            </WListItem>
          ))}
        </WList>
      </WContent>

      {address && (
        <>
          <Typography variant='h5' style={{ paddingBottom: '1rem' }}>
            {address.address_id
              ? `Editing ${address.address_city}, ${address.address_state}`
              : 'Add New Address'}
          </Typography>
          <FormWithTracking
            objectId={address.address_id}
            relationType='many'
            initialAttributes={address}
            fields={ADDRESS_FIELDS}
            onSubmit={handleSubmit}
          />
        </>
      )}

      <WActions>
        <Button variant='contained' onClick={onSubmitSuccess}>
          CONTINUE
        </Button>
        <Button variant='contained' onClick={onExit}>
          EXIT
        </Button>
      </WActions>
    </>
  )
}
