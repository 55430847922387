import { forwardRef } from 'react'
import { useSelector } from 'react-redux'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core'

import { ModalButtons } from './ModalButtons'
import { Delete } from './Delete'
import { ChangeStatus } from './ChangeStatus'
import { Cancel } from './Cancel'
import { AddToFolder } from './AddToFolder'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export const Modal = () => {
  const modalOpen = useSelector((state) => state.nav.modalOpen)
  const modalState = useSelector((state) => state.nav.modalState)

  return (
    <div>
      <Dialog
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {{
            changeStatus: 'Change Status',
            bulletSave: 'Bullet Saved',
            briefSave: 'Brief Saved',
            bulletCreated: 'Bullet Created',
            briefCreated: 'Brief Created',
            bulletDiscard: 'Discard Bullet',
            briefDiscard: 'Discard Brief',
            bulletCancel: 'Cancel Bullet Changes',
            briefCancel: 'Cancel Brief Changes',
            delete: 'Delete Item(s)',
            addToFolder: 'Add Item(s) to Folder',
            unsaved: 'There are unsaved changes',
          }[modalState] || 'An unknown error has occured please try again'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {{
              changeStatus: <ChangeStatus />,
              bulletSave: <Cancel text="Bullet Saved" />,
              bulletDiscard: <Cancel text="Discard Bullet" />,
              briefDiscard: <Cancel text="Discard Brief" />,
              bulletCancel: <Cancel text="Cancel Bullet Changes" />,
              briefCancel: <Cancel text="Cancel Brief Changes" />,
              delete: <Delete />,
              addToFolder: <AddToFolder />,
              unsaved: (
                <Cancel text="Discard your changes or continue editing" />
              ),
            }[modalState] || '404 not found'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButtons modalState={modalState} />
        </DialogActions>
      </Dialog>
    </div>
  )
}
