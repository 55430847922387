import axios from 'axios'
import { errorHandler } from './errorHandler'

export const campaign_finances = {
  createCampaignFinance: async (body) => {
    try {
      return await axios.post(`/targets/campaignfinance/create`, body)
    } catch (err) {
      errorHandler(err)
    }
  },
  updateCampaignFinance: async (id, body) => {
    try {
      return await axios.patch(`/targets/campaignfinance/update/${id}`, {
        ...body,
      })
    } catch (err) {
      errorHandler(err)
    }
  },
  deleteCampaignFinance: async (id) => {
    try {
      return await axios.delete(`/targets/campaignfinance/delete/${id}`)
    } catch (err) {
      errorHandler(err)
    }
  },
}
