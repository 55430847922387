import { makeStyles } from '@material-ui/core'

export const selectStyles = makeStyles(() => ({
  targetSelect: {
    justifyContent: 'space-between',
    width: '100%',
    textTransform: 'none',
  },
  highIndex: {
    zIndex: 5,
  },
  maxo: {
    maxWidth: '250px',
    display: 'flex',
  },
}))

export const BulletBuilderStyles = makeStyles((theme) => ({
  first: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    maxWidth: '75%',
  },
  header: {
    paddingBottom: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    marginBottom: '12px',
  },
  marginTop8: {
    marginTop: '8px',
  },
  marginTop16: {
    marginTop: '16px',
  },
  marginTop24: {
    marginTop: '24px',
    flexWrap: 'nowrap',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '24px',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  marginTopNeg8: {
    marginTop: '-8px',
  },
}))
