import axios from 'axios'
import { errorHandler } from './errorHandler'

export const folders = {
  getFolders: async ({ targets }) => {
    try {
      return await axios.get(
        `/folders/?${targets
          .map((target) => `folder_target=${target.target_id}&`)
          .join('')}`
      )
    } catch (err) {
      errorHandler(err)
    }
  },
  getFoldersByTargets: async ({ targets }) => {
    try {
      const res = await axios.get(
        `/folders/?${targets
          .map((target) =>
            target !== undefined ? `folder_target=${target.target_id}&` : ''
          )
          .join('')}`
      )
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  },
  createFolder: async (body) => {
    try {
      return await axios.post(`/folders/create`, body)
    } catch (err) {
      errorHandler(err)
    }
  },
  updateFolder: async (id, body) => {
    try {
      return await axios.patch(`/folders/update/${id}`, body)
    } catch (err) {
      errorHandler(err)
    }
  },
  deleteFolder: async (id) => {
    try {
      return await axios.delete(`/folders/delete/${id}`)
    } catch (err) {
      errorHandler(err)
    }
  },
}
