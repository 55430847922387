import Report from './Report'

const ReportList = ({ reports }) => (
  <>
    {reports &&
      reports.map((report) => (
        <Report key={report.report_id} report={report} />
      ))}
  </>
)

export default ReportList
