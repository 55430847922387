import { CardMedia } from '@material-ui/core'
import styled from 'styled-components'
import { Clip } from '../video/Clips'

export const Attachment = ({ file, bullet_id, isClient, isClip }) => {
  if (file instanceof File || file === null) {
    return <CardMedia />
  } else {
    const fileType = file.split(/[#?]/)[0].split('.').pop().trim().toLowerCase()
    const imgs = ['png', 'jpeg', 'jpg', 'svg', 'gif']
    const videos = ['mov', 'mp4', 'm3u']
    return (
      <CardMedia>
        {imgs.includes(fileType) ? (
          <Row>
            <Col isImg={true}>
              <Img src={file} />
            </Col>
          </Row>
        ) : videos.includes(fileType) || isClip ? (
          <Col isImg={false}>
            <Clip url={file} bullet_id={bullet_id} isClient={isClient} />
          </Col>
        ) : (
          ''
        )}
      </CardMedia>
    )
  }
}
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
`
const Col = styled.div`
  max-width: ${(props) => (props.isImg ? '300px' : 'auto')};
  max-height: ${(props) => (props.isImg ? '300px' : 'auto')};
  margin: 0.5rem;
`
const Img = styled.img`
  height: 90%;
  width: auto;
`
