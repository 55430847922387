import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Collapse, Button, Divider, TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { changeKeyword, changeExcludeWord } from '../slices/bulletSlice'

export const KeywordSearch = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const include = useSelector((state) => state.bullet.keyword)
  const exclude = useSelector((state) => state.bullet.excludeWord)
  const [includeVal, setIncludeVal] = useState(null)
  const [excludeVal, setExcludeVal] = useState(null)
  const handleClick = (e) => {
    setOpen(!open)
  }
  useEffect(() => {
    setIncludeVal(include)
  }, [include])
  useEffect(() => {
    setExcludeVal(exclude)
  }, [exclude])

  return (
    <>
      <StyledButton onClick={handleClick}>Keyword Search</StyledButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Column>
          <KeywordField
            label='Include'
            value={includeVal === null ? '' : includeVal}
            onChange={(e) => setIncludeVal(e.target.value)}
            onBlur={(e) => {
              dispatch(
                changeKeyword(e.target.value === '' ? null : e.target.value)
              )
            }}
          />
          <KeywordField
            label='Exclude'
            value={excludeVal === null ? '' : excludeVal}
            onChange={(e) => setExcludeVal(e.target.value)}
            onBlur={(e) => {
              dispatch(
                changeExcludeWord(e.target.value === '' ? null : e.target.value)
              )
            }}
          />
        </Column>
      </Collapse>
      <Divider />
    </>
  )
}

const StyledButton = styled(Button)`
  text-transform: capitalize;
  font-size: 1rem;
  text-align: left;
  justify-content: left;
  margin-left: 0.25rem;
`

const Column = styled.div`
  width: 90%;
  display: flex !important;
  flex-direction: column !important;
  align-items: space-around !important;
  justify-content: space-around !important;
  text-align: center;
  padding: 0.5rem;
`
const KeywordField = styled(TextField)`
  margin-bottom: 10px;
  & .MuiOutlinedInput-input {
    padding: 12px 0 8px !important;
  }
`
