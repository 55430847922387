import { Grid, Typography, TextField, InputLabel } from '@material-ui/core'
import { Selects } from '../selects/SelectPicker'
import { DatePicker } from './DatePicker'

export const GenericForm = ({
  formState,
  setFormState,
  formErrors,
  classes,
  handleChange,
}) => (
  <Grid container item>
    <Typography variant="h5" style={{ marginTop: '1rem' }}>
      Citation
    </Typography>
    <Grid
      justifyContent="space-between"
      container
      className={classes.marginTop8}
      item
      xs={12}
    >
      <Grid item xs={5}>
        <TextField
          className={classes.marginTop8}
          label="Source Name"
          required
          error={formErrors.bullet_cite_source}
          value={formState.bullet_cite_source}
          onChange={(e) => handleChange('bullet_cite_source', e)}
        />
      </Grid>
      <Grid item xs={5}>
        <DatePicker
          id="cite-date"
          label="Cite Date"
          currentDate={formState.bullet_cite_date}
          handleChange={handleChange}
          fieldName="bullet_cite_date"
        />
      </Grid>
    </Grid>
    <TextField
      className={classes.marginTop8}
      fullWidth
      label="Attribution URL"
      value={formState.bullet_cite_url}
      onChange={(e) =>
        setFormState({
          ...formState,
          bullet_cite_url: e.target.value,
        })
      }
    />
    <Grid
      container
      className={classes.marginTop8}
      justifyContent="space-between"
    >
      <Grid item xs={5}>
        <DatePicker
          id="access-date"
          label="Access Date"
          currentDate={formState.bullet_access_date}
          handleChange={handleChange}
          fieldName="bullet_access_date"
        />
      </Grid>
      <Grid item xs={5}>
        <InputLabel shrink className={classes.marginTop8}>
          Cite Type
        </InputLabel>
        <Selects
          formState={formState}
          setFormState={setFormState}
          selectType={'bullet_cite_type'}
          selectName={'Bullet Citation Type'}
        />
      </Grid>
    </Grid>
    {formState.bullet_cite_type !== null && (
      <Grid
        container
        className={classes.marginTop8}
        justifyContent="space-between"
      >
        <Grid item xs={5}>
          <TextField
            className={classes.marginTop8}
            label="Source Author"
            fullWidth
            value={formState.bullet_cite_author}
            onChange={(e) =>
              setFormState({
                ...formState,
                bullet_cite_author: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            className={classes.marginTop8}
            label="Cite Title"
            fullWidth
            value={formState.bullet_cite_title}
            onChange={(e) =>
              setFormState({
                ...formState,
                bullet_cite_title: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    )}
  </Grid>
)
