import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
} from '@material-ui/core'

import { updateAddress } from '../slices/targetSlice'
import { FormErrors } from '../util/FormErrors'
import { FormField } from '../util/FormField'
import { ADDRESS_FIELDS } from '../../utils/forms'
import { AddressPresenter } from '../../presenters/address_presenter'

export const AddressEditDialog = ({
  open,
  initialAddress,
  onCloseEdit,
  onSaveEdit,
}) => {
  const dispatch = useDispatch()
  const [address, setAddress] = useState({ ...initialAddress })
  const [errors, setErrors] = useState([])
  const [formStatus, setFormStatus] = useState('clean')
  const presenter = new AddressPresenter(address)

  useEffect(() => {
    setAddress({ ...initialAddress })
  }, [initialAddress])

  const handleChange = (key, value) => {
    setFormStatus('dirty')
    setAddress({
      ...address,
      [key]: value,
    })
  }

  const handleSave = async () => {
    try {
      setFormStatus('pending')
      const response = await onSaveEdit(address.address_id, { ...address })
      dispatch(updateAddress(response.data))
      setFormStatus('clean')
      onCloseEdit()
    } catch (err) {
      setFormStatus('dirty')
      setErrors(err.errors)
    }
  }

  if (!initialAddress) return null

  return (
    <Dialog open={open} onClose={onCloseEdit}>
      <DialogTitle>
        <div style={{ textAlign: 'center' }}>
          Edit Address{' '}
          {presenter.addressCityState && `(${presenter.addressCityState})`}
        </div>
      </DialogTitle>
      <DialogContent>
        <form autoComplete='off' noValidate>
          <Grid container spacing={3}>
            <FormErrors errors={errors} />
            {Object.values(ADDRESS_FIELDS).map((field) => (
              <Grid item xs={12} key={field.name}>
                {field.type === 'select' ? (
                  <FormControl fullWidth>
                    <FormField
                      field={field}
                      value={address[field.name]}
                      onChange={handleChange}
                      formStatus={formStatus}
                    />
                  </FormControl>
                ) : (
                  <FormField
                    field={field}
                    value={address[field.name]}
                    onChange={handleChange}
                    formStatus={formStatus}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCloseEdit}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
