import moment from 'moment'

// | from date  | to date  | display        |
// |------------|----------|----------------|
// | 2020       | 2022     | (2020-2022)    |
// | 2020       | null     | (2020-current) |
// | null       | 2022     | (2022)         |
// | null       | null     | <no display>   |
export const MomentRange = ({ fromDate, toDate, parser = 'YYYY-MM-DD' }) => {
  if (!fromDate && !toDate) return null

  const fromDisplay = fromDate && moment(fromDate, parser).format('Y')
  const toDisplay = toDate ? moment(toDate, parser).format('Y') : 'current'

  if (!fromDate) {
    return <>({toDisplay})</>
  }

  return (
    <>
      ({fromDisplay}-{toDisplay})
    </>
  )
}
