import { Fragment } from 'react'

export const NewLineText = ({ text }) => {
  const lines = text.split('\n')
  const lastIndex = lines.length - 1

  return (
    <div>
      {lines.map((line, index) => (
        <Fragment key={index}>
          {line}
          {index < lastIndex && <br />}
        </Fragment>
      ))}
    </div>
  )
}
