import axios from 'axios'
import { errorHandler } from './errorHandler'

export const targets = {
  getTargets: async () => {
    try {
      return await axios.get(`/targets/profile`)
    } catch (err) {
      errorHandler(err)
    }
  },
  getTargetDetail: async ({ id }) => {
    try {
      return await axios.get(`/targets/details/${id}`)
    } catch (err) {
      errorHandler(err)
    }
  },
  getTargetStatistics: async ({ id }) => {
    try {
      return await axios.get(`/targets/statistics/${id}`)
    } catch (err) {
      errorHandler(err)
    }
  },
  createTarget: async (body) => {
    try {
      return await axios.post(`/targets/create`, body)
    } catch (err) {
      errorHandler(err)
    }
  },
  updateTarget: async ({ id, body }) => {
    try {
      return await axios.put(`/targets/update/${id}`, {
        ...body,
      })
    } catch (err) {
      errorHandler(err)
    }
  },
  patchTarget: async (id, body) => {
    try {
      return await axios.patch(`/targets/update/${id}`, {
        ...body,
      })
    } catch (err) {
      errorHandler(err)
    }
  },
  patchTargetPhoto: async (id, body) => {
    try {
      let formData = new FormData()
      formData.append('target_photo', body.target_photo)
      return await axios.patch(`/targets/update/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    } catch (err) {
      errorHandler(err)
    }
  },
  getDataFromLinks: async (id) => {
    try {
      return await axios.get(`/targets/getdata/${id}`)
    } catch (err) {
      errorHandler(err)
    }
  },
  getTargetTracking: async (id) => {
    try {
      return await axios.get(`/targets/tracking/single/${id}`)
    } catch (err) {
      errorHandler(err)
    }
  },
}
