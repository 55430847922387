import styled from 'styled-components'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Box, Tab, Tabs } from '@material-ui/core'

import { LoadingBalls } from '../../util/LoadingBalls'
import { WizardContainer, WTitle, WDescription, WActions } from '../../wizard'
import { WLinksTab } from './WLinksTab'
import { WNamesTab } from './WNamesTab'
import { WBiographyTab } from './WBiographyTab'
import { WPhotoTab } from './WPhotoTab'
import { WFamilyTab } from './WFamilyTab'
import { WAddressesTab } from './WAddressesTab'
import { WEducationTab } from './WEducationTab'
import { WElectionsTab } from './WElectionsTab'
import { WCandidaciesTab } from './WCandidaciesTab'
import { WGoverningsTab } from './WGoverningsTab'
import { WCampaignFinancesTab } from './WCampaignFinancesTab'
import { WOrganizationsTab } from './WOrganizationsTab'
import { fetchTargetDetails, selectTarget } from '../../slices/targetSlice'
import {
  updateActiveSectionByTarget,
  fetchActiveSectionByTarget,
  fetchTrackingsByTarget,
} from '../../slices/wizardSlice'
import { setFormStatus } from '../../slices/wizardSlice'

export const TargetWizardEdit = () => {
  let dispatch = useDispatch()
  let navigate = useNavigate()
  const { targetId } = useParams()
  const targetFetchStatus = useSelector((state) => state.target.fetchStatus)
  const target = useSelector(selectTarget)
  const wizardFormStatus = useSelector((state) => state.wizard.formStatus)
  const isDisabled = useSelector((state) => state.wizard.isDisabled)
  const activeSection = useSelector((state) => state.wizard.activeSection)

  useEffect(() => {
    dispatch(fetchTargetDetails(targetId))
    dispatch(fetchTrackingsByTarget(targetId))
    dispatch(fetchActiveSectionByTarget(targetId))
  }, [dispatch, targetId])

  useEffect(() => {
    dispatch(setFormStatus('clean'))
  }, [dispatch, targetId, activeSection])

  const handleExitClick = () => {
    if (wizardFormStatus === 'clean') {
      navigate(`/targets/${targetId}`)
      return
    }

    if (window.confirm('Discard unsaved changes?')) {
      navigate(`/targets/${targetId}`)
    }
  }

  const setActiveSection = async (section) => {
    dispatch(
      updateActiveSectionByTarget({ target_id: targetId, section: section })
    )
  }

  const handleTabClick = (event, newTab) => {
    if (wizardFormStatus === 'clean') {
      setActiveSection(newTab)
      return
    }

    if (window.confirm('Discard unsaved changes?')) {
      setActiveSection(newTab)
    }
  }

  if (
    targetFetchStatus === 'idle' ||
    targetFetchStatus === 'loading' ||
    activeSection === null
  ) {
    return <LoadingBalls />
  }

  return (
    <WizardContainer style={{ paddingTop: 60 }} noValidate autoComplete='off'>
      <StyledTabsBox>
        <StyledTabs
          value={activeSection}
          onChange={handleTabClick}
          variant='scrollable'
          scrollButtons='on'
        >
          <StyledTab disabled={isDisabled} label='Intro' value='INTRO' />
          <StyledTab disabled={isDisabled} label='Links' value='LINKS' />
          <StyledTab disabled={isDisabled} label='Names' value='NAMES' />
          <StyledTab
            disabled={isDisabled}
            label='Biographical'
            value='BIOGRAPHICAL'
          />
          <StyledTab disabled={isDisabled} label='Photo' value='PHOTO' />
          <StyledTab disabled={isDisabled} label='Family' value='FAMILY' />
          <StyledTab
            disabled={isDisabled}
            label='Addresses'
            value='ADDRESSES'
          />
          <StyledTab
            disabled={isDisabled}
            label='Education'
            value='EDUCATION'
          />
          <StyledTab
            disabled={isDisabled}
            label='Candidacies'
            value='CANDIDACY'
          />
          <StyledTab
            disabled={isDisabled}
            label='Elections'
            value='ELECTIONS'
          />
          <StyledTab
            disabled={isDisabled}
            label='Elected Office'
            value='ELECTED_OFFICES'
          />
          <StyledTab
            disabled={isDisabled}
            label='Campaign Finance'
            value='CAMPAIGN_FINANCE'
          />
          <StyledTab
            disabled={isDisabled}
            label='Organizations'
            value='ORGANIZATIONS'
          />
        </StyledTabs>
      </StyledTabsBox>

      <div role='tabpanel' hidden={activeSection !== 'INTRO'}>
        <WTitle>Continue building your target.</WTitle>
        <WDescription style={{ paddingBottom: '2rem' }}>
          You provided legal first and last. Now let's fill in the rest!
        </WDescription>
        <WActions>
          <Button variant='contained' onClick={() => setActiveSection('LINKS')}>
            CONTINUE
          </Button>
          <Button variant='contained' onClick={handleExitClick}>
            EXIT
          </Button>
        </WActions>
      </div>

      <div role='tabpanel' hidden={activeSection !== 'LINKS'}>
        {activeSection === 'LINKS' && (
          <WLinksTab
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('NAMES')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'NAMES'}>
        {activeSection === 'NAMES' && (
          <WNamesTab
            target={target}
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('BIOGRAPHICAL')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'BIOGRAPHICAL'}>
        {activeSection === 'BIOGRAPHICAL' && (
          <WBiographyTab
            target={target}
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('PHOTO')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'PHOTO'}>
        {activeSection === 'PHOTO' && (
          <WPhotoTab
            target={target}
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('FAMILY')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'FAMILY'}>
        {activeSection === 'FAMILY' && (
          <WFamilyTab
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('ADDRESSES')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'ADDRESSES'}>
        {activeSection === 'ADDRESSES' && (
          <WAddressesTab
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('EDUCATION')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'EDUCATION'}>
        {activeSection === 'EDUCATION' && (
          <WEducationTab
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('CANDIDACY')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'CANDIDACY'}>
        {activeSection === 'CANDIDACY' && (
          <WCandidaciesTab
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('ELECTIONS')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'ELECTIONS'}>
        {activeSection === 'ELECTIONS' && (
          <WElectionsTab
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('ELECTED_OFFICES')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'ELECTED_OFFICES'}>
        {activeSection === 'ELECTED_OFFICES' && (
          <WGoverningsTab
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('CAMPAIGN_FINANCE')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'CAMPAIGN_FINANCE'}>
        {activeSection === 'CAMPAIGN_FINANCE' && (
          <WCampaignFinancesTab
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('ORGANIZATIONS')}
          />
        )}
      </div>
      <div role='tabpanel' hidden={activeSection !== 'ORGANIZATIONS'}>
        {activeSection === 'ORGANIZATIONS' && (
          <WOrganizationsTab
            onExit={handleExitClick}
            onSubmitSuccess={() => setActiveSection('NAMES')}
          />
        )}
      </div>
    </WizardContainer>
  )
}

const StyledTabsBox = styled(Box)`
  border-bottom: 2px;
  border-color: 'divider';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

const StyledTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    justify-content: space-between;
  }
`

const StyledTab = styled(Tab)`
  min-width: 100px;

  ${(props) => props.disabled && `opacity: 0.2;`};
`
