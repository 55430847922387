import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Grid, Button, TextField } from '@material-ui/core'

import { api } from '../../../api/api'
import {
  WizardContainer,
  WTitle,
  WDescription,
  WContent,
  WActions,
} from '../../wizard'
import { FormErrors } from '../../util/FormErrors'

export const TargetWizardBio = () => {
  const [targetAttributes, setTargetAttributes] = useState({})
  const [target, setTarget] = useState()
  const [errors, setErrors] = useState([])

  const handleChange = (event) => {
    setTargetAttributes({
      ...targetAttributes,
      [event.target.name]: event.target.value,
    })
  }

  const handleNext = async () => {
    try {
      const response = await api.createTarget(targetAttributes)
      setTarget(response.data)
    } catch (err) {
      setErrors(err.errors)
    }
  }

  if (target) {
    return <Navigate to={`/targets/wizard/${target.target_id}/edit`} />
  }

  return (
    <WizardContainer noValidate autoComplete='off'>
      <WTitle>Start building your target.</WTitle>
      <WDescription style={{ paddingBottom: '2rem' }}>
        Please write their legal first and last name
      </WDescription>
      <WContent>
        <FormErrors errors={errors} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='First Name'
              name='target_first_name'
              variant='outlined'
              onChange={handleChange}
              defaultValue={targetAttributes.target_first_name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Last Name'
              name='target_last_name'
              variant='outlined'
              onChange={handleChange}
              defaultValue={targetAttributes.target_last_name}
            />
          </Grid>
        </Grid>
      </WContent>
      <WActions>
        <Button variant='contained' onClick={handleNext}>
          Next
        </Button>
      </WActions>
    </WizardContainer>
  )
}
