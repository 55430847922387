import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

import {
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
} from '@material-ui/core'

import { api } from '../../api/api'
import { Citations } from '../bullets/Citations'
import { setFolderRefetch } from '../slices/bulletSlice'

const NewFolder = ({ incommingFolder, incommingTargets, setIsCreate }) => {
  const folderRefetch = useSelector((state) => state.bullet.folderRefetch)
  const allTargets = useSelector((state) => state.user.targets)
  const [folder, setFolder] = useState(incommingFolder)
  const [folder_name, setFolder_name] = useState('')
  const [folder_detail, setFolder_detail] = useState('')
  const [folder_target, setFolder_target] = useState([])
  const [targetNames, setTargetNames] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    const tempFolders = []
    //get list of all targets in bullets, briefs and selected
    incommingTargets.forEach((t) => tempFolders.push(t.target_id))
    folder.bullets.forEach((bullet) => {
      if (!tempFolders.includes(bullet.bullet_target))
        tempFolders.push(bullet.bullet_target)
    })
    folder.briefs.forEach((brief) => {
      if (!tempFolders.includes(brief.brief_target))
        tempFolders.push(brief.brief_target)
    })

    //make english readable version for display
    tempFolders.forEach((target) => {
      const match = allTargets.find((item) => item.target_id === target)
      if (!isEmpty(match) && !targetNames.includes(match.target_full_name))
        setTargetNames((targetNames) => [
          ...targetNames,
          match.target_full_name,
        ])
    })
    setFolder_target(tempFolders)
  }, [incommingTargets])

  const handleChange = (fieldName, value) => {
    if (fieldName === 'folder_name') setFolder_name(value)
    if (fieldName === 'folder_detail') setFolder_detail(value)
  }

  const handleCancel = () => {
    setIsCreate(false)
  }
  const handleCreate = () => {
    submit()
  }

  const submit = async () => {
    let tempFolder = {
      folder_detail,
      folder_name,
      folder_target,
      bullets: !isEmpty(folder.bullets)
        ? [`${folder.bullets.map((bullet) => bullet.bullet_id)}`]
        : [],
      briefs: !isEmpty(folder.briefs)
        ? [`${folder.briefs.map((brief) => brief.brief_id)}`]
        : [],
    }
    console.log(tempFolder)
    try {
      await api.createFolder(tempFolder)
      dispatch(setFolderRefetch(!folderRefetch))
      setIsCreate(false)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <Container>
      <Folders>
        <div>
          <Typography variant='h5' style={{ marginTop: '1rem' }}>
            Folder Name
          </Typography>
          <TextField
            fullWidth
            required
            charLimit={150}
            label='Required'
            value={folder_name}
            inputProps={{ maxLength: 150 }}
            helperText={
              folder_name === ''
                ? 'Max length 150'
                : `${150 - folder_name.length} remaining`
            }
            onChange={(e) => handleChange('folder_name', e.target.value)}
          />
        </div>
        <div>
          <Typography variant='h5' style={{ marginTop: '1rem' }}>
            Folder Details
          </Typography>
          <TextField
            fullWidth
            required
            label='Required'
            value={folder_detail}
            onChange={(e) => handleChange('folder_detail', e.target.value)}
          />
        </div>
      </Folders>
      <Folders style={{ background: 'lightgrey', marginTop: '1rem' }}>
        <Typography variant='h5' style={{ marginTop: '1rem' }}>
          New Folder Preview:
        </Typography>
        <StyledCardHeader
          title={folder_name}
          subheader={
            <PRow>
              <PLeft>{`Target(s): ${targetNames.map(
                (target) => ' ' + target
              )}`}</PLeft>
              <PRight>{folder.counts}</PRight>
            </PRow>
          }
        />
        <StyledCardContent>
          {!isEmpty(folder.bullets) && (
            <Bullets>
              <CardHeader
                title={
                  <Row>
                    <Left>Bullet(s)</Left>
                    <Center>
                      <CiteRow>Citation</CiteRow>
                    </Center>
                  </Row>
                }
              />
              {folder.bullets.map((bullet, index) => (
                <Row key={bullet.bullet_id + index}>
                  <Left>{bullet.bullet_header}</Left>
                  <Center>
                    <CiteRow>
                      <Citations bullet={bullet} isModal={false} />
                    </CiteRow>
                  </Center>
                </Row>
              ))}
            </Bullets>
          )}
          {!isEmpty(folder.briefs) && (
            <Briefs>
              <CardHeader
                title={
                  <Row>
                    <Left>Brief(s)</Left>
                  </Row>
                }
              />
              {folder.briefs.map((brief, index) => (
                <Row key={brief.brief_id + index}>{brief.brief_header}</Row>
              ))}
            </Briefs>
          )}
        </StyledCardContent>
        <Row>
          <Button onClick={() => handleCreate()}>Create Folder</Button>
          <Button onClick={() => handleCancel()}>Cancel</Button>
        </Row>
      </Folders>
    </Container>
  )
}

export default NewFolder

const Container = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 1rem;
  width: 100%;
`
const Folders = styled(Card)`
  flex-direction: column;
`
const StyledCardHeader = styled(CardHeader)`
  ${(props) =>
    props.newFolder &&
    `&:hover {
      background: lightgrey;
    }`}
`
const Bullets = styled(Card)`
  padding: 8px;
  margin-bottom: 1rem;
`
const Briefs = styled(Card)`
  padding: 8px;
`
const StyledCardContent = styled(CardContent)`
  height: auto;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
const CiteRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Left = styled.span`
  width: 60%;
`
const Center = styled.span`
  width: 40%;
`
const PRow = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
const PLeft = styled.span`
  width: 75%;
`
const PRight = styled.span`
  width: 25%;
`
