import React, { useCallback } from 'react'
import GoogleButton from 'react-google-button'
import {
  CssBaseline,
  Link,
  Box,
  Typography,
  Container,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import tappanlogo from '../../static/img/tappanlogo.png'

const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_BASE_BACKEND_URL } = process.env

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link
        color='inherit'
        href='https://www.tappanresearch.com/'
        target='_blank'
      >
        Tappan Research
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export const Login = () => {
  const classes = useStyles()

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
    const redirectUri = 'api/v1/auth/login/google/'

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ].join(' ')

    const params = {
      response_type: 'code',
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${REACT_APP_BASE_BACKEND_URL}/${redirectUri}`,
      prompt: 'select_account',
      access_type: 'offline',
      scope,
    }
    const urlParams = new URLSearchParams(params).toString()
    window.location = `${googleAuthUrl}?${urlParams}`
  }, [])
  return (
    <>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src={tappanlogo}
            className={classes.logo}
            alt={'Tappan Research Logo'}
          />
          <GoogleButton
            onClick={openGoogleLoginPage}
            label='Sign in with Google'
            disabled={!REACT_APP_GOOGLE_CLIENT_ID}
          />
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
      <Grid
        container
        columns={{ xs: 4 }}
        style={{ position: 'absolute', bottom: 15 }}
      >
        <Grid item xs={3}>
          <LinksOut
            linkAddress={'https://explorer.tappanresearch.com/contact'}
            copy='Contact Us'
          />
        </Grid>
        <Grid item xs={3}>
          <LinksOut
            linkAddress={'https://explorer.tappanresearch.com/privacy'}
            copy='Privacy Policy'
          />
        </Grid>
        <Grid item xs={3}>
          <LinksOut
            linkAddress={'https://explorer.tappanresearch.com/terms-of-service'}
            copy='Terms of Service'
          />
        </Grid>
        <Grid item xs={3}>
          <LinksOut
            linkAddress={'https://www.tappanresearch.com/'}
            copy='Tappan Research'
          />
        </Grid>
      </Grid>
    </>
  )
}

const LinksOut = ({ copy, linkAddress }) => {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      <Link color='inherit' href={linkAddress} target='_blank'>
        {copy}
      </Link>{' '}
    </Typography>
  )
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    marginBottom: '8px',
  },
}))
