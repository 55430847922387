import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  ListItem,
  List,
  makeStyles,
  useTheme,
  IconButton,
  Drawer,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import TargetPicker from '../util/TargetPicker'
import { KeywordSearch } from './KeywordSearch'
import { setLeftOpen } from '../slices/navSlice'
import { setSelectedThemes, setUserThemes } from '../slices/themeSlice'
import { clearFilters } from '../slices/bulletSlice'

import FolderPicker from './FolderPicker'
import TypePicker from './TypePicker'
import SelectedFilter from './SelectedFilter'
import { DatePicker } from './DatePicker'
import { Tags } from '../Tags/Tags'
import { api } from '../../api/api'

export const AppDrawer = () => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const location = useLocation()
  const leftOpen = useSelector((state) => state.nav.leftOpen)
  const user = useSelector((state) => state.user.user)

  useEffect(() => {
    if (location.pathname.match(/^\/(login)|(targets).*/g))
      dispatch(setLeftOpen(false))
    else if (user) dispatch(setLeftOpen(true))
    else dispatch(setLeftOpen(false))
  }, [user, location])

  const selectedTargets = useSelector((state) => state.bullet.selected_targets)
  const selectedFolders = useSelector((state) => state.bullet.selected_folders)
  const selectedTypes = useSelector((state) => state.bullet.selected_types)
  const selectedStatuses = useSelector(
    (state) => state.bullet.selected_statuses
  )
  const selectedBulletTypes = useSelector(
    (state) => state.bullet.selected_bullet_types
  )
  const userThemes = useSelector((state) => state.theme.userThemes)
  const selectedThemes = useSelector((state) => state.theme.selectedThemes)
  const [isLoading, setIsLoading] = useState(true)

  const getData = async () => {
    try {
      const themes = await api.getThemes()
      dispatch(setUserThemes(themes.data))
    } catch (err) {
      throw err
    }
  }
  useEffect(() => {
    setIsLoading(true)
    getData()
    setIsLoading(false)
  }, [])

  const handleDrawerClose = () => {
    dispatch(setLeftOpen(false))
  }

  const handleTags = (field, tags) => {
    dispatch(setSelectedThemes(tags))
  }

  const clearAll = () => {
    dispatch(setSelectedThemes([]))
    dispatch(clearFilters())
  }

  return (
    <Drawer
      className={classes.drawer}
      variant='persistent'
      anchor='left'
      open={leftOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <ListItem>
          <Typography variant='h6'>Content Filters</Typography>
        </ListItem>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
      <Divider />
      <List disablePadding>
        <ListItem>
          <TargetPicker isPane isGlobal={false} />
        </ListItem>
        <List disablePadding>
          {selectedTargets.map((target) => (
            <ListItem key={'list' + target.id}>
              <SelectedFilter
                type='target'
                key={target.id}
                name={target.name}
                value={target}
              />
            </ListItem>
          ))}
        </List>
        <Divider />
        <ListItem>
          <FolderPicker isPane />
        </ListItem>
        <Divider />
        <span className={classes.column}>
          {selectedFolders.map((folder) => (
            <ListItem key={'list' + folder.id}>
              <SelectedFilter
                type='folder'
                key={folder.id}
                name={folder.name}
                value={folder}
              />
            </ListItem>
          ))}
        </span>
        <ListItem>
          <TypePicker pickerType='bullet_type' />
        </ListItem>
        <List disablePadding>
          {selectedBulletTypes.map((type) => (
            <ListItem key={'list' + type}>
              <SelectedFilter
                type='bullet_type'
                key={type}
                name={type}
                value={type}
              />
            </ListItem>
          ))}
        </List>
        <Divider />
        <ListItem>
          <TypePicker pickerType='data_type' />
        </ListItem>
        <List disablePadding>
          {selectedTypes.map((type) => (
            <ListItem key={'list' + type}>
              <SelectedFilter
                type='data_type'
                key={type}
                name={type}
                value={type}
              />
            </ListItem>
          ))}
        </List>

        <ListItem>
          <TypePicker pickerType='record_status' />
        </ListItem>
        <List disablePadding>
          {selectedStatuses.map((status) => (
            <ListItem key={'list' + status}>
              <SelectedFilter
                type='record_status'
                key={status}
                name={status}
                value={status}
              />
            </ListItem>
          ))}
        </List>
        <Divider />
        <span className={classes.column}>
          <KeywordSearch />
        </span>
        <span className={classes.column} style={{ margin: '0 .5rem' }}>
          {!isLoading && (
            <Tags
              handleChange={handleTags}
              existingTags={userThemes}
              currentTags={selectedThemes}
            />
          )}
        </span>
        <span className={classes.column} style={{ margin: '1rem 0.5rem' }}>
          <DatePicker />
        </span>
        <span className={classes.column} style={{ margin: '1rem 0.5rem' }}>
          <Button
            variant='text'
            onClick={() => {
              clearAll()
            }}
          >
            Clear Filters
          </Button>
        </span>
      </List>
      <ContactUs>
        <a
          href='https://explorer.tappanresearch.com/contact'
          rel='noreferrer'
          target='_blank'
        >
          Contact Us
        </a>
      </ContactUs>
    </Drawer>
  )
}

const ContactUs = styled.div`
  display: flex;
  position: absolute;
  bottom: 15px;
  justify-content: start;
  margin: 0 0.5rem;
  flex-grow: 1;
  margin-bottom: auto;
`
const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  logo: {
    'opacity': 1,
    'transition': theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      color: 'yellow',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'left',
    alignContent: 'center',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    zIndex: 90,
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    width: drawerWidth,
    backgroundColor: '#dce8f0',
    height: '100%',
    overflowY: 'visible',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}))
